<div class="host" [prizmTheme]="theme" prizmFocusTrap>
  <ng-container *polymorphOutlet="context.outerHeader as data; context: context">
    <div class="header prizm-font-title-h4" *ngIf="context.header || context.outerHeader">
      <div
        class="title"
        #host
        *polymorphOutlet="context.header as data; context: context"
        [prizmHintOnOverflow]="data"
        [prizmHintOnOverflowEl]="host"
      >
        {{ data }}
      </div>
      <button
        *ngIf="context.closeable"
        (click)="close()"
        size="m"
        appearanceType="ghost"
        appearance="secondary"
        prizmIconButton
        icon="xmark"
      ></button>
    </div>
  </ng-container>

  <div class="content prizm-font-main-body-14">
    <prizm-scrollbar class="scrollbar" [visibility]="context.scrollbarVisibility ?? 'auto'">
      <div *polymorphOutlet="$any(context.content) as text; context: $any(context)">
        {{ text }}
      </div>
    </prizm-scrollbar>
  </div>

  <div class="footer prizm-font-btn-links-14" *ngIf="footer" [style]="context.footerStyle">
    <ng-container *polymorphOutlet="$any(footer) as data; context: context">
      <div class="buttons" *ngIf="isFooterArray; else temp">
        <button
          *ngFor="let footerButton of data"
          [style]="footerButton.style"
          [appearance]="footerButton.appearance"
          [appearanceType]="footerButton.appearanceType"
          [size]="context.size"
          (click)="footerButton.action(context)"
          prizmButton
        >
          {{ footerButton.text }}
        </button>
      </div>
      <ng-template #temp>
        {{ data }}
      </ng-template>
    </ng-container>
  </div>
</div>
