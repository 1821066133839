<div class="container" #container>
  <div class="breadcrumbs" *ngFor="let item of breadcrumbsItem; let i = index">
    <!--    <prizm-icon-->
    <!--      class="breadcrumbs__chevron"-->
    <!--      *ngIf="i !== 0"-->
    <!--      [size]="16"-->
    <!--      iconClass="chevrons-right"-->
    <!--    ></prizm-icon>    -->
    <prizm-icons-full
      class="breadcrumbs__chevron"
      *ngIf="i !== 0"
      [size]="16"
      name="chevron-right"
    ></prizm-icons-full>

    <div class="breadcrumb breadcrumb__name">
      <ng-container [ngTemplateOutlet]="item.template" [ngTemplateOutletContext]="{ idx: i }"></ng-container>
    </div>

    <prizm-dropdown-host
      class="breadcrumbs__dots"
      *ngIf="i === 0 && this.isContainerOverflowed"
      [(isOpen)]="isDropdownOpened"
      [content]="dropdown"
      prizmDropdownHostWidth="auto"
    >
      <div class="dropdown-inner">
        <!--        <prizm-icon class="breadcrumbs__chevron" [size]="16" iconClass="chevrons-right"></prizm-icon>-->
        <prizm-icons-full class="breadcrumbs__chevron" [size]="16" name="chevron-right"></prizm-icons-full>
        <button class="dots-buttons" (click)="isDropdownOpened = !isDropdownOpened">...</button>
      </div>
    </prizm-dropdown-host>
  </div>

  <ng-container>
    <div class="breadcrumbs" #breadcrumb *ngFor="let breadcrumb of breadcrumbsToShow$ | async; let i = index">
      <!--      <prizm-icon-->
      <!--        class="breadcrumbs__chevron"-->
      <!--        *ngIf="i !== 0"-->
      <!--        [size]="16"-->
      <!--        iconClass="chevrons-right"-->
      <!--      ></prizm-icon>-->
      <prizm-icons-full
        class="breadcrumbs__chevron"
        *ngIf="i !== 0"
        [size]="16"
        name="chevron-right"
      ></prizm-icons-full>
      <button
        class="breadcrumb"
        [class.breadcrumb_active]="i === activeBreadcrumbIdx"
        (click)="changeBreadcrumb(i)"
      >
        <!--        <prizm-icon-->
        <!--          class="breadcrumb__icon"-->
        <!--          *ngIf="breadcrumb.icon"-->
        <!--          [size]="16"-->
        <!--          [iconClass]="breadcrumb.icon"-->
        <!--        ></prizm-icon>-->
        <prizm-icons-full
          class="breadcrumb__icon"
          *ngIf="breadcrumb.icon"
          [size]="16"
          [name]="breadcrumb.icon"
        ></prizm-icons-full>
        <span class="breadcrumb__name" *ngIf="breadcrumb.name">{{ breadcrumb.name }}</span>
      </button>

      <prizm-dropdown-host
        class="breadcrumbs__dots"
        *ngIf="i === 0 && this.isContainerOverflowed"
        [(isOpen)]="isDropdownOpened"
        [content]="dropdown"
        prizmDropdownHostWidth="auto"
      >
        <div class="dropdown-inner">
          <!--          <prizm-icon class="breadcrumbs__chevron" [size]="16" iconClass="chevrons-right"></prizm-icon>-->
          <prizm-icons-full class="breadcrumbs__chevron" [size]="16" name="chevron-right"></prizm-icons-full>
          <button class="dots-buttons" (click)="isDropdownOpened = !isDropdownOpened">...</button>
        </div>
      </prizm-dropdown-host>
    </div>
  </ng-container>
</div>

<div class="container container__fake" #breadcrumbsFake>
  <div class="breadcrumbs" #breadcrumbsFake *ngFor="let breadcrumb of breadcrumbs$ | async; let i = index">
    <!--    <prizm-icon-->
    <!--      class="breadcrumbs__chevron"-->
    <!--      *ngIf="i !== 0"-->
    <!--      [size]="16"-->
    <!--      iconClass="chevrons-right"-->
    <!--    ></prizm-icon>    -->
    <prizm-icons-full
      class="breadcrumbs__chevron"
      *ngIf="i !== 0"
      [size]="16"
      name="chevron-right"
    ></prizm-icons-full>
    <button class="breadcrumb">
      <!--      <prizm-icon-->
      <!--        class="breadcrumb__icon"-->
      <!--        *ngIf="!!breadcrumb.icon"-->
      <!--        [size]="16"-->
      <!--        [iconClass]="breadcrumb.icon"-->
      <!--      ></prizm-icon>-->
      <prizm-icons-full
        class="breadcrumb__icon"
        *ngIf="!!breadcrumb.icon"
        [size]="16"
        [name]="breadcrumb.icon"
      ></prizm-icons-full>
      <span class="breadcrumb__name">{{ breadcrumb?.name }}</span>
    </button>
  </div>
</div>

<ng-template #dropdown>
  <div class="dropdown-content" *ngIf="breadcrumbsInMenu$ | async as breadcrumbsInMenu">
    <ng-container *ngFor="let breadcrumb of breadcrumbsInMenu; let i = index">
      <button class="dropdown-breadcrumb" (click)="changeBreadcrumb(i + 1)">
        <!--        <prizm-icon-->
        <!--          class="dropdown-breadcrumb__icon"-->
        <!--          *ngIf="!!breadcrumb.icon"-->
        <!--          [size]="16"-->
        <!--          [iconClass]="breadcrumb.icon"-->
        <!--        ></prizm-icon>-->
        <prizm-icons-full
          class="dropdown-breadcrumb__icon"
          *ngIf="!!breadcrumb.icon"
          [size]="16"
          [name]="breadcrumb.icon"
        ></prizm-icons-full>
        <span class="dropdown-breadcrumb__name">{{ breadcrumb?.name }}</span>
      </button>
    </ng-container>
  </div>
</ng-template>
