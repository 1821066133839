<prizm-card [shadow]="shadow">
  <div class="header prizm-font-title-h4">
    <ng-container *polymorphOutlet="header">
      <div class="title">
        <ng-container *polymorphOutlet="title">
          {{ title }}
        </ng-container>
      </div>
      <ng-container *polymorphOutlet="icons">
        <div class="icons" [class.divider]="hasLine">
          <ng-container *ngFor="let icon of $any(icons); let idx = index">
            <ng-container *polymorphOutlet="icon; context: { idx: idx }">
              <button
                [icon]="$any(icon)"
                (click)="iconClick.emit(icon)"
                prizmIconButton
                appearanceType="ghost"
                appearance="secondary"
                size="s"
              ></button>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="body prizm-font-main-body-14">
    <ng-content></ng-content>
  </div>
</prizm-card>
