<div class="panel">
  <div class="back-btn-wrapper" *ngIf="withBackButton">
    <button
      [icon]="'arrow-left'"
      (click)="back()"
      prizmIconButton
      appearanceType="ghost"
      appearance="secondary"
      size="m"
    ></button>
  </div>
  <div class="buttons">
    <ng-content select="[buttons]"></ng-content>
  </div>
  <div class="title" *ngIf="!!(header || subheader)" [class.not-left-indent]="withBackButton">
    <span class="title__main">{{ header }}</span>
    <span class="title__sub">{{ subheader }}</span>
  </div>
  <div class="header" #headerElement [class.header_empty]="headerRef.nativeElement.children.length === 0">
    <ng-content select="[header]"></ng-content>
  </div>
  <div class="instruments">
    <ng-content select="[instruments]"></ng-content>
  </div>
</div>
