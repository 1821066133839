import { NgModule } from '@angular/core';
import { PrizmInputDateMultiComponent } from './input-date-multi.component';

/** @deprecated
 * use standalone
 * */
@NgModule({
  imports: [PrizmInputDateMultiComponent],
  exports: [PrizmInputDateMultiComponent],
})
export class PrizmInputDateMultiModule {}
