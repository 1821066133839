/* 🤖 this file was generated by svg-to-ts */

export type PrizmIconsType =
  | 'agenda-fill'
  | 'airplane-side-view'
  | 'airplane-top-view'
  | 'alarm-clock'
  | 'alarm-plus'
  | 'align-bottom'
  | 'align-center-h'
  | 'align-center-v'
  | 'align-center'
  | 'align-justify'
  | 'align-left'
  | 'align-right'
  | 'align-top'
  | 'ambulance-side-view'
  | 'android-pill'
  | 'android'
  | 'angle-left-right-arrows-down'
  | 'angle-left-right'
  | 'angle-left'
  | 'angle-right'
  | 'arrange-send-to-back'
  | 'array-fill'
  | 'arrow-bold-down'
  | 'arrow-bold-left'
  | 'arrow-bold-right'
  | 'arrow-bold-up'
  | 'arrow-daw'
  | 'arrow-down-between-lines'
  | 'arrow-down-from-double-line'
  | 'arrow-down-from-line'
  | 'arrow-down-in-circle-empty'
  | 'arrow-down-to-bracket'
  | 'arrow-down-to-double-line'
  | 'arrow-down-to-line'
  | 'arrow-down-wide-short'
  | 'arrow-down'
  | 'arrow-left-between-lines'
  | 'arrow-left-down'
  | 'arrow-left-from-bracket'
  | 'arrow-left-in-circle-empty'
  | 'arrow-left-up'
  | 'arrow-left'
  | 'arrow-right-between-lines'
  | 'arrow-right-down'
  | 'arrow-right-from-bracket'
  | 'arrow-right-in-circle-empty'
  | 'arrow-right-up'
  | 'arrow-right'
  | 'arrow-rotate-left'
  | 'arrow-rotate-right'
  | 'arrow-turn-down-left'
  | 'arrow-turn-down-right'
  | 'arrow-turn-left-down'
  | 'arrow-turn-left-up'
  | 'arrow-turn-right-down'
  | 'arrow-turn-right-up'
  | 'arrow-turn-up-left'
  | 'arrow-turn-up-right'
  | 'arrow-up-arrow-down-h'
  | 'arrow-up-arrow-down-v'
  | 'arrow-up-between-lines'
  | 'arrow-up-from-bracket'
  | 'arrow-up-from-double-line'
  | 'arrow-up-from-line'
  | 'arrow-up-in-circle-empty'
  | 'arrow-up-to-dotted-line'
  | 'arrow-up-to-double-line'
  | 'arrow-up-to-line'
  | 'arrow-up-wide-short'
  | 'arrow-up'
  | 'arrows-all-directions'
  | 'arrows-compare'
  | 'arrows-cross'
  | 'arrows-direction-to-the-lines'
  | 'arrows-inside'
  | 'arrows-left-right-brackets'
  | 'arrows-left-right-to-lines'
  | 'arrows-maximize'
  | 'arrows-minimize'
  | 'arrows-out'
  | 'arrows-repeat-h'
  | 'arrows-repeat-v'
  | 'arrows-rotate-h'
  | 'arrows-rotate-v'
  | 'arrows-shuffle'
  | 'arrows-swap-h'
  | 'arrows-swap-v'
  | 'arrows-up-down-to-lines'
  | 'at-sign'
  | 'audio-speakers'
  | 'award'
  | 'bag'
  | 'balloon'
  | 'ban'
  | 'bar-axis'
  | 'bar-basic'
  | 'bar-columns-axis-x'
  | 'bar-columns'
  | 'bar-histogram'
  | 'bar-square'
  | 'bar-stacked-axis'
  | 'bar-stacked'
  | 'barcode'
  | 'bars-sort'
  | 'bars'
  | 'battery-bolt'
  | 'battery-empty'
  | 'battery-exclametion-mark'
  | 'battery-full'
  | 'battery-half'
  | 'battery-minus'
  | 'battery-plus'
  | 'battery-quarter'
  | 'battery-three-quarters'
  | 'bdrv-administration'
  | 'bell-fill'
  | 'bell-plus'
  | 'bell-ring-fill'
  | 'bell-ring'
  | 'bell-slash'
  | 'bell'
  | 'bezier-curve'
  | 'bitcoin'
  | 'block-lines'
  | 'bluetooth'
  | 'boiler-hot-water-gas-and-oil'
  | 'boiler'
  | 'bold'
  | 'book-check'
  | 'book-closed'
  | 'book-open-plus'
  | 'book-open-re-arrow-left'
  | 'book-open-re-arrow-right'
  | 'book-open-text'
  | 'book-open-with-page'
  | 'book-open'
  | 'book-stack'
  | 'bookmark-check-fill'
  | 'bookmark-check'
  | 'bookmark-cross-fill'
  | 'bookmark-cross'
  | 'bookmark-fill'
  | 'bookmark-music-fill'
  | 'bookmark-music'
  | 'bookmark-plus-fill'
  | 'bookmark-plus'
  | 'bookmark'
  | 'border-all'
  | 'border-bottom'
  | 'border-center-h'
  | 'border-center-v'
  | 'border-inner'
  | 'border-left'
  | 'border-none'
  | 'border-outer'
  | 'border-right'
  | 'border-top-left'
  | 'border-top'
  | 'box-open-arrow-down'
  | 'box'
  | 'brackets-curly'
  | 'brackets-square'
  | 'broom'
  | 'browser'
  | 'brush'
  | 'bubble-message-add'
  | 'bubble-message-attention'
  | 'bubble-message-circle'
  | 'bubble-message-empty'
  | 'bubble-message-off'
  | 'bubble-message-question'
  | 'bubble-message-text'
  | 'bubble-message'
  | 'burn'
  | 'calculator-arrow-rotate-left'
  | 'calculator-function'
  | 'calculator'
  | 'calendar-blank'
  | 'calendar-check'
  | 'calendar-clock'
  | 'calendar-deleted'
  | 'calendar-event'
  | 'calendar-plus'
  | 'calendar-question'
  | 'calendar-range'
  | 'calendar-today'
  | 'calendar'
  | 'calibration-characteristics-zql'
  | 'camera-front'
  | 'camera-movie-cross'
  | 'camera-movie'
  | 'camera'
  | 'car-jeep-side-view'
  | 'car-light-side-view'
  | 'carbon'
  | 'carousel-fill'
  | 'cart-flatbed-container'
  | 'cart-plus'
  | 'cart'
  | 'cellphone-wireless'
  | 'cellphone'
  | 'charts-iqc-zql'
  | 'check-double'
  | 'check'
  | 'checkerboard'
  | 'chevron-down-to-double-line'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-mini-left'
  | 'chevron-mini-right'
  | 'chevron-right-above-double-line'
  | 'chevron-right'
  | 'chevron-up'
  | 'chevrons-double-down'
  | 'chevrons-double-left'
  | 'chevrons-double-mini-left'
  | 'chevrons-double-mini-right'
  | 'chevrons-double-right'
  | 'chevrons-double-up'
  | 'chevrons-inside-horizontally'
  | 'chevrons-inside-vertically'
  | 'chevrons-outside-horizontally'
  | 'chevrons-outside-vertically'
  | 'circle-check-empty'
  | 'circle-check-fill'
  | 'circle-exclamation-empty'
  | 'circle-exclamation-fill'
  | 'circle-in-hexagon'
  | 'circle-info-empty'
  | 'circle-info-fill'
  | 'circle-music'
  | 'circle-plus-fill'
  | 'circle-plus'
  | 'circle-question-empty'
  | 'circle-question-fill'
  | 'circle-square-fill'
  | 'circle-square-in-square'
  | 'circle-square'
  | 'circle-xmark-fill'
  | 'circle-xmark'
  | 'circles-line-left-up-circle-right-down-fill'
  | 'circles-line-left-up-circle-right-down'
  | 'clapperboard-cross'
  | 'clapperboard-open'
  | 'clock-add'
  | 'clock-arrow-down'
  | 'clock-arrow-right'
  | 'clock-arrow-up'
  | 'clock-deleted'
  | 'clock-exclamation'
  | 'clock-line-end'
  | 'clock-rotate-left'
  | 'clock-rotate-right'
  | 'clock'
  | 'cloud-arrow-down'
  | 'cloud-arrow-up-inside'
  | 'cloud-arrow-up'
  | 'cloud-check'
  | 'cloud'
  | 'code-download'
  | 'code-simple'
  | 'code'
  | 'collage-fill'
  | 'collection'
  | 'collector'
  | 'column-fill'
  | 'column'
  | 'compass'
  | 'component'
  | 'concrete-mixer-side-view'
  | 'container-v-1'
  | 'container-v-2'
  | 'containers'
  | 'control-charts-zql'
  | 'copy-column'
  | 'copy-row'
  | 'copy'
  | 'crane'
  | 'credit-card-plus'
  | 'credit-card-slash'
  | 'credit-card'
  | 'crop'
  | 'crosshairs-dot'
  | 'crosshairs-simple'
  | 'cube-empty'
  | 'cube-fill'
  | 'cubes'
  | 'dashboard-fill'
  | 'dashboard-variant-fill'
  | 'database-check'
  | 'database-json'
  | 'database-magnifying-glass'
  | 'database-minus'
  | 'database-plus'
  | 'database-sql'
  | 'database-xmark'
  | 'database'
  | 'day-fill'
  | 'decagram-check-empty'
  | 'decagram-check-fill'
  | 'decagram-exclamation-empty'
  | 'decagram-exclamation-fill'
  | 'default-ico'
  | 'delete-content'
  | 'desktop-tower'
  | 'discrete-set'
  | 'dollar-slash'
  | 'dollar'
  | 'doughnut'
  | 'droplet-slash'
  | 'droplet'
  | 'early-version'
  | 'earth'
  | 'editor-alfavit'
  | 'editor-down'
  | 'editor-right'
  | 'electrics'
  | 'ellipsis-2'
  | 'ellipsis-4'
  | 'ellipsis-grid'
  | 'ellipsis-h'
  | 'ellipsis-v'
  | 'energy-check'
  | 'energy-slash'
  | 'energy'
  | 'equipment-zql'
  | 'eraser'
  | 'ethernet'
  | 'exchanger'
  | 'exclamation'
  | 'eye-closed'
  | 'eye-slash'
  | 'eye'
  | 'face-frown'
  | 'face-smile'
  | 'factory-pipe'
  | 'factory-pipes'
  | 'factory'
  | 'fan'
  | 'file-arrow-down-left'
  | 'file-arrow-down'
  | 'file-arrow-up'
  | 'file-arrow'
  | 'file-arrows-up-right'
  | 'file-camera'
  | 'file-cross-out'
  | 'file-cross'
  | 'file-doc'
  | 'file-docx'
  | 'file-dot'
  | 'file-empty'
  | 'file-exclamation'
  | 'file-eye'
  | 'file-filled'
  | 'file-image'
  | 'file-magnifying-glass'
  | 'file-minus'
  | 'file-music'
  | 'file-pdf'
  | 'file-pen'
  | 'file-play'
  | 'file-plus'
  | 'file-ppt'
  | 'file-pptx'
  | 'file-txt'
  | 'file-up-right'
  | 'file-xls'
  | 'file-xlsx'
  | 'files-mode'
  | 'fill'
  | 'film-cross'
  | 'film'
  | 'filter-gear'
  | 'filter-ontology'
  | 'filter'
  | 'finance-scanner'
  | 'fire'
  | 'flag-fill-red'
  | 'flag-fill'
  | 'flag'
  | 'flask-round-potion'
  | 'flask'
  | 'floppy-cross-out'
  | 'floppy-cross'
  | 'floppy-pen'
  | 'floppy-star'
  | 'floppy'
  | 'folder-arrow-down-left'
  | 'folder-arrow-down'
  | 'folder-arrow-up-right'
  | 'folder-cross'
  | 'folder-dot'
  | 'folder-minus'
  | 'folder-open'
  | 'folder-plus'
  | 'folder-underline-minus'
  | 'folder-underline-plus'
  | 'folder-underline'
  | 'folder'
  | 'forvard-step'
  | 'forward'
  | 'fuel-oil-tank'
  | 'function'
  | 'gantt'
  | 'gas-monitoring'
  | 'gas-pump'
  | 'gas'
  | 'gauge'
  | 'gear-5-edge'
  | 'gear-8-edge'
  | 'gear-cooling'
  | 'generator'
  | 'gift'
  | 'git-lab'
  | 'globe'
  | 'grid-fill'
  | 'grid'
  | 'grip-dots-vertical'
  | 'hard-drive-outline'
  | 'hash-dash'
  | 'hash'
  | 'hashtag'
  | 'headline-fill'
  | 'headphones'
  | 'heart-empty'
  | 'heart-fill'
  | 'helicopter-side-view'
  | 'hexagon-exclamation-empty'
  | 'hexagon-exclamation-fill'
  | 'hexagon-fill'
  | 'hexagon'
  | 'hexagons-axis'
  | 'hexagons'
  | 'histogram-axis'
  | 'house-mini'
  | 'house'
  | 'image'
  | 'importers-zql'
  | 'indent-arrow-left'
  | 'indent-arrow-right'
  | 'infinity'
  | 'info'
  | 'input-device'
  | 'instagram'
  | 'italic'
  | 'key'
  | 'keyboard-close'
  | 'keyboard'
  | 'label-cross'
  | 'label-exclamation-mark'
  | 'label-fill'
  | 'label-magnifying-glass'
  | 'label-plus'
  | 'label-question'
  | 'label-text'
  | 'label'
  | 'lan-connect'
  | 'lan-disconnect'
  | 'lan-pending'
  | 'laptop-phone-slash'
  | 'laptop-phone'
  | 'laptop-slash'
  | 'laptop'
  | 'layer-group-slash'
  | 'layer-group'
  | 'layer-network'
  | 'letter-day'
  | 'letter-hour'
  | 'letter-minute'
  | 'letter-month'
  | 'letter-second'
  | 'letter-time'
  | 'letter-year'
  | 'library-plus'
  | 'lightbulb'
  | 'line-axis-arrow-down'
  | 'line-axis-x'
  | 'line-axis'
  | 'line-down'
  | 'line-height'
  | 'line-multiple-y-axis-x'
  | 'line-multiple-y-axis'
  | 'line-up'
  | 'line'
  | 'link-break'
  | 'link-minus'
  | 'link-plus'
  | 'link-simple-h'
  | 'link-simple'
  | 'list-fill'
  | 'list-ol'
  | 'list-square'
  | 'list-tree-archive'
  | 'list-tree'
  | 'list-ul'
  | 'list'
  | 'lng-storage'
  | 'loader'
  | 'location-arrow'
  | 'location-directly'
  | 'location-dot'
  | 'location-minus'
  | 'location-plus'
  | 'location-slash'
  | 'location-user'
  | 'lock'
  | 'logo-grey-z'
  | 'magic-wand'
  | 'magnifying-glass-exclamation'
  | 'magnifying-glass-minus'
  | 'magnifying-glass-ontology'
  | 'magnifying-glass-plus'
  | 'magnifying-glass-qr-code'
  | 'magnifying-glass-tags-words'
  | 'magnifying-glass-top-from-bracket'
  | 'magnifying-glass-triangle-down'
  | 'magnifying-glass-xmark'
  | 'magnifying-glass'
  | 'mail-open'
  | 'mail'
  | 'mailbox'
  | 'map-base'
  | 'map-marker-date-v-1'
  | 'map-marker-date-v-2'
  | 'map-pin'
  | 'map'
  | 'medical'
  | 'merge'
  | 'messaging-telegram-send'
  | 'messaging-telegram'
  | 'microphone-slash'
  | 'microphone'
  | 'minibus-side-view'
  | 'minus-circle-fill'
  | 'minus-circle'
  | 'minus'
  | 'module-fill'
  | 'monitor'
  | 'moon'
  | 'music-slash'
  | 'music'
  | 'network-external-link'
  | 'network-v-1'
  | 'network-v-2'
  | 'network-v-3'
  | 'network-wired'
  | 'ni-fi-logo'
  | 'normdocs-zql'
  | 'npm'
  | 'object-down-subtract'
  | 'object-exclude'
  | 'object-intersect'
  | 'object-selection'
  | 'object-union'
  | 'object-up-subtract'
  | 'object'
  | 'oil-barrel'
  | 'oil-canister'
  | 'oil-droplet-arrows-all'
  | 'oil-droplet-arrows-repeat-h'
  | 'oil-droplet-check'
  | 'oil-droplet'
  | 'oil-field'
  | 'oil-tank'
  | 'ontology'
  | 'owl'
  | 'palette'
  | 'panel-down'
  | 'panel-left'
  | 'panel-right'
  | 'panel-top'
  | 'pantone'
  | 'paperclip-vertical'
  | 'paperclip'
  | 'pause'
  | 'paver-side-view'
  | 'pen-hammer'
  | 'pencil-arrow-right'
  | 'pencil-line-bottom'
  | 'pencil-triangle-down'
  | 'pencil-triangle-left'
  | 'pencil'
  | 'pentagon-fill'
  | 'pentagon'
  | 'percent'
  | 'phone-active-call'
  | 'phone-cancelled'
  | 'phone-minus'
  | 'phone-off'
  | 'phone-paused'
  | 'phone-plus'
  | 'phone'
  | 'pi'
  | 'pie-line-nested'
  | 'pie-line'
  | 'pie-pentagon'
  | 'pie-several-parts'
  | 'pie-simple'
  | 'pie'
  | 'pipe-node-side-view'
  | 'pipe-sensor'
  | 'pipe-slash'
  | 'pipe-valve'
  | 'pipeline-v-1'
  | 'pipeline-v-2'
  | 'pipette'
  | 'plane'
  | 'play-back'
  | 'play'
  | 'plus-column-right'
  | 'plus-column-top'
  | 'plus-triangle-down'
  | 'plus'
  | 'polyline'
  | 'portfel'
  | 'power'
  | 'premises-zql'
  | 'press'
  | 'printer'
  | 'process'
  | 'pulse'
  | 'pump-triangle-down'
  | 'pump'
  | 'push-button-cellphone'
  | 'qr'
  | 'question'
  | 'quilt-fill'
  | 'radiation'
  | 'railcar-hopper-side-view'
  | 'railcar-side-view'
  | 'range'
  | 'replay'
  | 'respirator-v-1'
  | 'respirator-v-2'
  | 'respirator-v-3'
  | 'respirator-v-4'
  | 'respirator-v-5'
  | 'respirator-v-6'
  | 'return-step'
  | 'return'
  | 'rhombus-fill'
  | 'rhombus'
  | 'ring-in-square'
  | 'ruler-index-zql'
  | 'ruler'
  | 'sample-logs-zql'
  | 'sample-templates-zql'
  | 'sandglass'
  | 'scatter-axis'
  | 'scatter-basic'
  | 'scatter'
  | 'scissors'
  | 'seedling-circle'
  | 'seedling-rectangle'
  | 'seedling'
  | 'selection-checked-empty'
  | 'send-backward'
  | 'send-forward'
  | 'separator'
  | 'sequential-fill'
  | 'server-network-close'
  | 'server-network-download'
  | 'server-network-minus'
  | 'server-network-plus'
  | 'server-network-security'
  | 'server-network-upload'
  | 'server-network'
  | 'server-shield'
  | 'server-slash-up-line'
  | 'server-slash'
  | 'server-up-line'
  | 'server'
  | 'shape-link-fill'
  | 'shape-link'
  | 'shape-plus'
  | 'share-left'
  | 'share-right'
  | 'shareing'
  | 'shield-exclamation'
  | 'shield-slash'
  | 'shield'
  | 'ship-front-view'
  | 'ship-side-view'
  | 'shopping-box'
  | 'shovel'
  | 'sigma'
  | 'signal-access-point'
  | 'signal-off'
  | 'signal-radio-tower'
  | 'signal'
  | 'sliders-h'
  | 'sliders-v'
  | 'snake-cup'
  | 'snowflake-up-to-bracket'
  | 'snowflake'
  | 'sort-filter-remove'
  | 'specification'
  | 'specs-zql'
  | 'speed-reduction-cooling'
  | 'square-arrows-curve-right-down'
  | 'square-arrows-curve-up-right'
  | 'square-bracket-curly-left'
  | 'square-bracket-curly-right'
  | 'square-bracket-left'
  | 'square-bracket-right'
  | 'square-circle-plus-triangle-fill'
  | 'square-circle-triangle-cross-fill'
  | 'square-circle-triangle-cross'
  | 'square-divide'
  | 'square-excel'
  | 'square-exclamation-empty'
  | 'square-exclamation-fill'
  | 'square-fill'
  | 'square-hexagon-circle-fill'
  | 'square-hexagon-circle'
  | 'square-in-square'
  | 'square-info-empty'
  | 'square-info-fill'
  | 'square-minus'
  | 'square-multiply'
  | 'square-music-double'
  | 'square-music'
  | 'square-percent'
  | 'square-plus'
  | 'square-powerpoint'
  | 'square-question-empty'
  | 'square-question-fill'
  | 'square-triangle-circle-triangle-fill'
  | 'square-triangle-circle-triangle'
  | 'square-word'
  | 'star-empty'
  | 'star-fill'
  | 'star-half-fill'
  | 'star-line'
  | 'steam-boiler'
  | 'steam-turbine'
  | 'steam'
  | 'stop'
  | 'stopwatch'
  | 'storage'
  | 'strikethrough'
  | 'subskrit-down'
  | 'subskrit-up'
  | 'sun'
  | 'support-man'
  | 'symbol-asterisk'
  | 'table-down'
  | 'table-gear'
  | 'table-magnifying-glass'
  | 'table-right'
  | 'table-transpose-right'
  | 'table-transpose-top'
  | 'table-transpose'
  | 'table'
  | 'tablet-iqc-zql'
  | 'tablet'
  | 'tank'
  | 'tankcar-oil-side-view'
  | 'tankcar-side-view'
  | 'techs-zql'
  | 'temp-add-shape-square-plus'
  | 'temp-alerts-alarm-light'
  | 'temp-alerts-circle-mini'
  | 'temp-arrows-corner-chek-in'
  | 'temp-arrows-down-double-top-2'
  | 'temp-arrows-up-double-top-2'
  | 'temp-audio-artist'
  | 'temp-badges-tag-multiple'
  | 'temp-chevrons-dropdown-small'
  | 'temp-chevrons-menu-left-small'
  | 'temp-date-inform-delete'
  | 'temp-editor-discrete-set'
  | 'temp-editor-wrap-text'
  | 'temp-files-import-1-c'
  | 'temp-location-localization-down-list'
  | 'temp-network-library'
  | 'temp-poi-difference'
  | 'temp-product-grade-according-to-specification'
  | 'temp-selection-checkbox-blank-circle'
  | 'temp-selection-checkbox-blank'
  | 'temp-selection-checkbox-marked-circle-chanel'
  | 'temp-selection-checkbox-marked-circle'
  | 'temp-selection-checked-1'
  | 'temp-selection-checked-2'
  | 'temp-selection-checked'
  | 'temp-selection-radio-off'
  | 'temp-selection-radio-on'
  | 'temp-selection-toggle-left'
  | 'temp-selection-toggle-right'
  | 'temp-selection-unchoice'
  | 'temp-shapes-checkbox-intermediate'
  | 'temp-signal-cast'
  | 'temp-signal-rss-box'
  | 'temp-social-tab'
  | 'temp-sort-version'
  | 'temp-tools-solver'
  | 'temperature-minus'
  | 'temperature-plus'
  | 'temperature'
  | 'template'
  | 'text-color'
  | 'text'
  | 'thumb-down'
  | 'thumb-up'
  | 'thumbtack-directly'
  | 'thumbtack'
  | 'touchpad'
  | 'train-cargo'
  | 'train-passenger'
  | 'transmitter'
  | 'trash-drop'
  | 'trash-empty'
  | 'trash'
  | 'triangle-circle-square-fill'
  | 'triangle-circle-square'
  | 'triangle-cross-empty'
  | 'triangle-down'
  | 'triangle-exclamation-empty'
  | 'triangle-exclamation-fill'
  | 'triangle-fill'
  | 'triangle-info-empty'
  | 'triangle-info-fill'
  | 'triangle-left'
  | 'triangle-plus-empty'
  | 'triangle-right'
  | 'triangle-up-down'
  | 'triangle-up'
  | 'triangle'
  | 'truck-dump-side-view'
  | 'truck-empty-side-view'
  | 'truck-garbage-side-view'
  | 'truck-gasoline-side-view'
  | 'truck-side-view'
  | 'tv'
  | 'umbrella'
  | 'underlined'
  | 'unlock'
  | 'user-arrows-swap'
  | 'user-card'
  | 'user-check'
  | 'user-chevron'
  | 'user-circle'
  | 'user-cross'
  | 'user-gear'
  | 'user-group'
  | 'user-location'
  | 'user-minus'
  | 'user-pen'
  | 'user-plus'
  | 'user-shield'
  | 'user-worker'
  | 'user'
  | 'users-arrow-right'
  | 'users-arrow-up-zql'
  | 'users-gear'
  | 'users-key'
  | 'users-location'
  | 'users-minus'
  | 'users-plus'
  | 'users'
  | 'vector-circle-one-point'
  | 'vector-circle-two-point'
  | 'vector-circle'
  | 'vector-line'
  | 'vector-point'
  | 'vector-polygon'
  | 'vector-rectangle'
  | 'vector-square'
  | 'vector-triangle'
  | 'vectors-radius'
  | 'vial-flask'
  | 'vial-plus'
  | 'vial-rotate-left'
  | 'vial-xmark'
  | 'vial'
  | 'video-cross'
  | 'video-slash'
  | 'video'
  | 'voicemail'
  | 'volume-cross'
  | 'volume-low'
  | 'volume-minus'
  | 'volume-off'
  | 'volume-plus'
  | 'volume-slash'
  | 'volume'
  | 'wallet'
  | 'watch'
  | 'water-heater'
  | 'water-pump'
  | 'webcam'
  | 'wifi-off'
  | 'wifi'
  | 'window'
  | 'wrench'
  | 'xmark-column-right'
  | 'xmark-column-top'
  | 'xmark-mini'
  | 'xmark';
export const PrizmIconsType = {
  'agenda-fill': 'agenda-fill' as PrizmIconsType,
  'airplane-side-view': 'airplane-side-view' as PrizmIconsType,
  'airplane-top-view': 'airplane-top-view' as PrizmIconsType,
  'alarm-clock': 'alarm-clock' as PrizmIconsType,
  'alarm-plus': 'alarm-plus' as PrizmIconsType,
  'align-bottom': 'align-bottom' as PrizmIconsType,
  'align-center-h': 'align-center-h' as PrizmIconsType,
  'align-center-v': 'align-center-v' as PrizmIconsType,
  'align-center': 'align-center' as PrizmIconsType,
  'align-justify': 'align-justify' as PrizmIconsType,
  'align-left': 'align-left' as PrizmIconsType,
  'align-right': 'align-right' as PrizmIconsType,
  'align-top': 'align-top' as PrizmIconsType,
  'ambulance-side-view': 'ambulance-side-view' as PrizmIconsType,
  'android-pill': 'android-pill' as PrizmIconsType,
  android: 'android' as PrizmIconsType,
  'angle-left-right-arrows-down': 'angle-left-right-arrows-down' as PrizmIconsType,
  'angle-left-right': 'angle-left-right' as PrizmIconsType,
  'angle-left': 'angle-left' as PrizmIconsType,
  'angle-right': 'angle-right' as PrizmIconsType,
  'arrange-send-to-back': 'arrange-send-to-back' as PrizmIconsType,
  'array-fill': 'array-fill' as PrizmIconsType,
  'arrow-bold-down': 'arrow-bold-down' as PrizmIconsType,
  'arrow-bold-left': 'arrow-bold-left' as PrizmIconsType,
  'arrow-bold-right': 'arrow-bold-right' as PrizmIconsType,
  'arrow-bold-up': 'arrow-bold-up' as PrizmIconsType,
  'arrow-daw': 'arrow-daw' as PrizmIconsType,
  'arrow-down-between-lines': 'arrow-down-between-lines' as PrizmIconsType,
  'arrow-down-from-double-line': 'arrow-down-from-double-line' as PrizmIconsType,
  'arrow-down-from-line': 'arrow-down-from-line' as PrizmIconsType,
  'arrow-down-in-circle-empty': 'arrow-down-in-circle-empty' as PrizmIconsType,
  'arrow-down-to-bracket': 'arrow-down-to-bracket' as PrizmIconsType,
  'arrow-down-to-double-line': 'arrow-down-to-double-line' as PrizmIconsType,
  'arrow-down-to-line': 'arrow-down-to-line' as PrizmIconsType,
  'arrow-down-wide-short': 'arrow-down-wide-short' as PrizmIconsType,
  'arrow-down': 'arrow-down' as PrizmIconsType,
  'arrow-left-between-lines': 'arrow-left-between-lines' as PrizmIconsType,
  'arrow-left-down': 'arrow-left-down' as PrizmIconsType,
  'arrow-left-from-bracket': 'arrow-left-from-bracket' as PrizmIconsType,
  'arrow-left-in-circle-empty': 'arrow-left-in-circle-empty' as PrizmIconsType,
  'arrow-left-up': 'arrow-left-up' as PrizmIconsType,
  'arrow-left': 'arrow-left' as PrizmIconsType,
  'arrow-right-between-lines': 'arrow-right-between-lines' as PrizmIconsType,
  'arrow-right-down': 'arrow-right-down' as PrizmIconsType,
  'arrow-right-from-bracket': 'arrow-right-from-bracket' as PrizmIconsType,
  'arrow-right-in-circle-empty': 'arrow-right-in-circle-empty' as PrizmIconsType,
  'arrow-right-up': 'arrow-right-up' as PrizmIconsType,
  'arrow-right': 'arrow-right' as PrizmIconsType,
  'arrow-rotate-left': 'arrow-rotate-left' as PrizmIconsType,
  'arrow-rotate-right': 'arrow-rotate-right' as PrizmIconsType,
  'arrow-turn-down-left': 'arrow-turn-down-left' as PrizmIconsType,
  'arrow-turn-down-right': 'arrow-turn-down-right' as PrizmIconsType,
  'arrow-turn-left-down': 'arrow-turn-left-down' as PrizmIconsType,
  'arrow-turn-left-up': 'arrow-turn-left-up' as PrizmIconsType,
  'arrow-turn-right-down': 'arrow-turn-right-down' as PrizmIconsType,
  'arrow-turn-right-up': 'arrow-turn-right-up' as PrizmIconsType,
  'arrow-turn-up-left': 'arrow-turn-up-left' as PrizmIconsType,
  'arrow-turn-up-right': 'arrow-turn-up-right' as PrizmIconsType,
  'arrow-up-arrow-down-h': 'arrow-up-arrow-down-h' as PrizmIconsType,
  'arrow-up-arrow-down-v': 'arrow-up-arrow-down-v' as PrizmIconsType,
  'arrow-up-between-lines': 'arrow-up-between-lines' as PrizmIconsType,
  'arrow-up-from-bracket': 'arrow-up-from-bracket' as PrizmIconsType,
  'arrow-up-from-double-line': 'arrow-up-from-double-line' as PrizmIconsType,
  'arrow-up-from-line': 'arrow-up-from-line' as PrizmIconsType,
  'arrow-up-in-circle-empty': 'arrow-up-in-circle-empty' as PrizmIconsType,
  'arrow-up-to-dotted-line': 'arrow-up-to-dotted-line' as PrizmIconsType,
  'arrow-up-to-double-line': 'arrow-up-to-double-line' as PrizmIconsType,
  'arrow-up-to-line': 'arrow-up-to-line' as PrizmIconsType,
  'arrow-up-wide-short': 'arrow-up-wide-short' as PrizmIconsType,
  'arrow-up': 'arrow-up' as PrizmIconsType,
  'arrows-all-directions': 'arrows-all-directions' as PrizmIconsType,
  'arrows-compare': 'arrows-compare' as PrizmIconsType,
  'arrows-cross': 'arrows-cross' as PrizmIconsType,
  'arrows-direction-to-the-lines': 'arrows-direction-to-the-lines' as PrizmIconsType,
  'arrows-inside': 'arrows-inside' as PrizmIconsType,
  'arrows-left-right-brackets': 'arrows-left-right-brackets' as PrizmIconsType,
  'arrows-left-right-to-lines': 'arrows-left-right-to-lines' as PrizmIconsType,
  'arrows-maximize': 'arrows-maximize' as PrizmIconsType,
  'arrows-minimize': 'arrows-minimize' as PrizmIconsType,
  'arrows-out': 'arrows-out' as PrizmIconsType,
  'arrows-repeat-h': 'arrows-repeat-h' as PrizmIconsType,
  'arrows-repeat-v': 'arrows-repeat-v' as PrizmIconsType,
  'arrows-rotate-h': 'arrows-rotate-h' as PrizmIconsType,
  'arrows-rotate-v': 'arrows-rotate-v' as PrizmIconsType,
  'arrows-shuffle': 'arrows-shuffle' as PrizmIconsType,
  'arrows-swap-h': 'arrows-swap-h' as PrizmIconsType,
  'arrows-swap-v': 'arrows-swap-v' as PrizmIconsType,
  'arrows-up-down-to-lines': 'arrows-up-down-to-lines' as PrizmIconsType,
  'at-sign': 'at-sign' as PrizmIconsType,
  'audio-speakers': 'audio-speakers' as PrizmIconsType,
  award: 'award' as PrizmIconsType,
  bag: 'bag' as PrizmIconsType,
  balloon: 'balloon' as PrizmIconsType,
  ban: 'ban' as PrizmIconsType,
  'bar-axis': 'bar-axis' as PrizmIconsType,
  'bar-basic': 'bar-basic' as PrizmIconsType,
  'bar-columns-axis-x': 'bar-columns-axis-x' as PrizmIconsType,
  'bar-columns': 'bar-columns' as PrizmIconsType,
  'bar-histogram': 'bar-histogram' as PrizmIconsType,
  'bar-square': 'bar-square' as PrizmIconsType,
  'bar-stacked-axis': 'bar-stacked-axis' as PrizmIconsType,
  'bar-stacked': 'bar-stacked' as PrizmIconsType,
  barcode: 'barcode' as PrizmIconsType,
  'bars-sort': 'bars-sort' as PrizmIconsType,
  bars: 'bars' as PrizmIconsType,
  'battery-bolt': 'battery-bolt' as PrizmIconsType,
  'battery-empty': 'battery-empty' as PrizmIconsType,
  'battery-exclametion-mark': 'battery-exclametion-mark' as PrizmIconsType,
  'battery-full': 'battery-full' as PrizmIconsType,
  'battery-half': 'battery-half' as PrizmIconsType,
  'battery-minus': 'battery-minus' as PrizmIconsType,
  'battery-plus': 'battery-plus' as PrizmIconsType,
  'battery-quarter': 'battery-quarter' as PrizmIconsType,
  'battery-three-quarters': 'battery-three-quarters' as PrizmIconsType,
  'bdrv-administration': 'bdrv-administration' as PrizmIconsType,
  'bell-fill': 'bell-fill' as PrizmIconsType,
  'bell-plus': 'bell-plus' as PrizmIconsType,
  'bell-ring-fill': 'bell-ring-fill' as PrizmIconsType,
  'bell-ring': 'bell-ring' as PrizmIconsType,
  'bell-slash': 'bell-slash' as PrizmIconsType,
  bell: 'bell' as PrizmIconsType,
  'bezier-curve': 'bezier-curve' as PrizmIconsType,
  bitcoin: 'bitcoin' as PrizmIconsType,
  'block-lines': 'block-lines' as PrizmIconsType,
  bluetooth: 'bluetooth' as PrizmIconsType,
  'boiler-hot-water-gas-and-oil': 'boiler-hot-water-gas-and-oil' as PrizmIconsType,
  boiler: 'boiler' as PrizmIconsType,
  bold: 'bold' as PrizmIconsType,
  'book-check': 'book-check' as PrizmIconsType,
  'book-closed': 'book-closed' as PrizmIconsType,
  'book-open-plus': 'book-open-plus' as PrizmIconsType,
  'book-open-re-arrow-left': 'book-open-re-arrow-left' as PrizmIconsType,
  'book-open-re-arrow-right': 'book-open-re-arrow-right' as PrizmIconsType,
  'book-open-text': 'book-open-text' as PrizmIconsType,
  'book-open-with-page': 'book-open-with-page' as PrizmIconsType,
  'book-open': 'book-open' as PrizmIconsType,
  'book-stack': 'book-stack' as PrizmIconsType,
  'bookmark-check-fill': 'bookmark-check-fill' as PrizmIconsType,
  'bookmark-check': 'bookmark-check' as PrizmIconsType,
  'bookmark-cross-fill': 'bookmark-cross-fill' as PrizmIconsType,
  'bookmark-cross': 'bookmark-cross' as PrizmIconsType,
  'bookmark-fill': 'bookmark-fill' as PrizmIconsType,
  'bookmark-music-fill': 'bookmark-music-fill' as PrizmIconsType,
  'bookmark-music': 'bookmark-music' as PrizmIconsType,
  'bookmark-plus-fill': 'bookmark-plus-fill' as PrizmIconsType,
  'bookmark-plus': 'bookmark-plus' as PrizmIconsType,
  bookmark: 'bookmark' as PrizmIconsType,
  'border-all': 'border-all' as PrizmIconsType,
  'border-bottom': 'border-bottom' as PrizmIconsType,
  'border-center-h': 'border-center-h' as PrizmIconsType,
  'border-center-v': 'border-center-v' as PrizmIconsType,
  'border-inner': 'border-inner' as PrizmIconsType,
  'border-left': 'border-left' as PrizmIconsType,
  'border-none': 'border-none' as PrizmIconsType,
  'border-outer': 'border-outer' as PrizmIconsType,
  'border-right': 'border-right' as PrizmIconsType,
  'border-top-left': 'border-top-left' as PrizmIconsType,
  'border-top': 'border-top' as PrizmIconsType,
  'box-open-arrow-down': 'box-open-arrow-down' as PrizmIconsType,
  box: 'box' as PrizmIconsType,
  'brackets-curly': 'brackets-curly' as PrizmIconsType,
  'brackets-square': 'brackets-square' as PrizmIconsType,
  broom: 'broom' as PrizmIconsType,
  browser: 'browser' as PrizmIconsType,
  brush: 'brush' as PrizmIconsType,
  'bubble-message-add': 'bubble-message-add' as PrizmIconsType,
  'bubble-message-attention': 'bubble-message-attention' as PrizmIconsType,
  'bubble-message-circle': 'bubble-message-circle' as PrizmIconsType,
  'bubble-message-empty': 'bubble-message-empty' as PrizmIconsType,
  'bubble-message-off': 'bubble-message-off' as PrizmIconsType,
  'bubble-message-question': 'bubble-message-question' as PrizmIconsType,
  'bubble-message-text': 'bubble-message-text' as PrizmIconsType,
  'bubble-message': 'bubble-message' as PrizmIconsType,
  burn: 'burn' as PrizmIconsType,
  'calculator-arrow-rotate-left': 'calculator-arrow-rotate-left' as PrizmIconsType,
  'calculator-function': 'calculator-function' as PrizmIconsType,
  calculator: 'calculator' as PrizmIconsType,
  'calendar-blank': 'calendar-blank' as PrizmIconsType,
  'calendar-check': 'calendar-check' as PrizmIconsType,
  'calendar-clock': 'calendar-clock' as PrizmIconsType,
  'calendar-deleted': 'calendar-deleted' as PrizmIconsType,
  'calendar-event': 'calendar-event' as PrizmIconsType,
  'calendar-plus': 'calendar-plus' as PrizmIconsType,
  'calendar-question': 'calendar-question' as PrizmIconsType,
  'calendar-range': 'calendar-range' as PrizmIconsType,
  'calendar-today': 'calendar-today' as PrizmIconsType,
  calendar: 'calendar' as PrizmIconsType,
  'calibration-characteristics-zql': 'calibration-characteristics-zql' as PrizmIconsType,
  'camera-front': 'camera-front' as PrizmIconsType,
  'camera-movie-cross': 'camera-movie-cross' as PrizmIconsType,
  'camera-movie': 'camera-movie' as PrizmIconsType,
  camera: 'camera' as PrizmIconsType,
  'car-jeep-side-view': 'car-jeep-side-view' as PrizmIconsType,
  'car-light-side-view': 'car-light-side-view' as PrizmIconsType,
  carbon: 'carbon' as PrizmIconsType,
  'carousel-fill': 'carousel-fill' as PrizmIconsType,
  'cart-flatbed-container': 'cart-flatbed-container' as PrizmIconsType,
  'cart-plus': 'cart-plus' as PrizmIconsType,
  cart: 'cart' as PrizmIconsType,
  'cellphone-wireless': 'cellphone-wireless' as PrizmIconsType,
  cellphone: 'cellphone' as PrizmIconsType,
  'charts-iqc-zql': 'charts-iqc-zql' as PrizmIconsType,
  'check-double': 'check-double' as PrizmIconsType,
  check: 'check' as PrizmIconsType,
  checkerboard: 'checkerboard' as PrizmIconsType,
  'chevron-down-to-double-line': 'chevron-down-to-double-line' as PrizmIconsType,
  'chevron-down': 'chevron-down' as PrizmIconsType,
  'chevron-left': 'chevron-left' as PrizmIconsType,
  'chevron-mini-left': 'chevron-mini-left' as PrizmIconsType,
  'chevron-mini-right': 'chevron-mini-right' as PrizmIconsType,
  'chevron-right-above-double-line': 'chevron-right-above-double-line' as PrizmIconsType,
  'chevron-right': 'chevron-right' as PrizmIconsType,
  'chevron-up': 'chevron-up' as PrizmIconsType,
  'chevrons-double-down': 'chevrons-double-down' as PrizmIconsType,
  'chevrons-double-left': 'chevrons-double-left' as PrizmIconsType,
  'chevrons-double-mini-left': 'chevrons-double-mini-left' as PrizmIconsType,
  'chevrons-double-mini-right': 'chevrons-double-mini-right' as PrizmIconsType,
  'chevrons-double-right': 'chevrons-double-right' as PrizmIconsType,
  'chevrons-double-up': 'chevrons-double-up' as PrizmIconsType,
  'chevrons-inside-horizontally': 'chevrons-inside-horizontally' as PrizmIconsType,
  'chevrons-inside-vertically': 'chevrons-inside-vertically' as PrizmIconsType,
  'chevrons-outside-horizontally': 'chevrons-outside-horizontally' as PrizmIconsType,
  'chevrons-outside-vertically': 'chevrons-outside-vertically' as PrizmIconsType,
  'circle-check-empty': 'circle-check-empty' as PrizmIconsType,
  'circle-check-fill': 'circle-check-fill' as PrizmIconsType,
  'circle-exclamation-empty': 'circle-exclamation-empty' as PrizmIconsType,
  'circle-exclamation-fill': 'circle-exclamation-fill' as PrizmIconsType,
  'circle-in-hexagon': 'circle-in-hexagon' as PrizmIconsType,
  'circle-info-empty': 'circle-info-empty' as PrizmIconsType,
  'circle-info-fill': 'circle-info-fill' as PrizmIconsType,
  'circle-music': 'circle-music' as PrizmIconsType,
  'circle-plus-fill': 'circle-plus-fill' as PrizmIconsType,
  'circle-plus': 'circle-plus' as PrizmIconsType,
  'circle-question-empty': 'circle-question-empty' as PrizmIconsType,
  'circle-question-fill': 'circle-question-fill' as PrizmIconsType,
  'circle-square-fill': 'circle-square-fill' as PrizmIconsType,
  'circle-square-in-square': 'circle-square-in-square' as PrizmIconsType,
  'circle-square': 'circle-square' as PrizmIconsType,
  'circle-xmark-fill': 'circle-xmark-fill' as PrizmIconsType,
  'circle-xmark': 'circle-xmark' as PrizmIconsType,
  'circles-line-left-up-circle-right-down-fill':
    'circles-line-left-up-circle-right-down-fill' as PrizmIconsType,
  'circles-line-left-up-circle-right-down': 'circles-line-left-up-circle-right-down' as PrizmIconsType,
  'clapperboard-cross': 'clapperboard-cross' as PrizmIconsType,
  'clapperboard-open': 'clapperboard-open' as PrizmIconsType,
  'clock-add': 'clock-add' as PrizmIconsType,
  'clock-arrow-down': 'clock-arrow-down' as PrizmIconsType,
  'clock-arrow-right': 'clock-arrow-right' as PrizmIconsType,
  'clock-arrow-up': 'clock-arrow-up' as PrizmIconsType,
  'clock-deleted': 'clock-deleted' as PrizmIconsType,
  'clock-exclamation': 'clock-exclamation' as PrizmIconsType,
  'clock-line-end': 'clock-line-end' as PrizmIconsType,
  'clock-rotate-left': 'clock-rotate-left' as PrizmIconsType,
  'clock-rotate-right': 'clock-rotate-right' as PrizmIconsType,
  clock: 'clock' as PrizmIconsType,
  'cloud-arrow-down': 'cloud-arrow-down' as PrizmIconsType,
  'cloud-arrow-up-inside': 'cloud-arrow-up-inside' as PrizmIconsType,
  'cloud-arrow-up': 'cloud-arrow-up' as PrizmIconsType,
  'cloud-check': 'cloud-check' as PrizmIconsType,
  cloud: 'cloud' as PrizmIconsType,
  'code-download': 'code-download' as PrizmIconsType,
  'code-simple': 'code-simple' as PrizmIconsType,
  code: 'code' as PrizmIconsType,
  'collage-fill': 'collage-fill' as PrizmIconsType,
  collection: 'collection' as PrizmIconsType,
  collector: 'collector' as PrizmIconsType,
  'column-fill': 'column-fill' as PrizmIconsType,
  column: 'column' as PrizmIconsType,
  compass: 'compass' as PrizmIconsType,
  component: 'component' as PrizmIconsType,
  'concrete-mixer-side-view': 'concrete-mixer-side-view' as PrizmIconsType,
  'container-v-1': 'container-v-1' as PrizmIconsType,
  'container-v-2': 'container-v-2' as PrizmIconsType,
  containers: 'containers' as PrizmIconsType,
  'control-charts-zql': 'control-charts-zql' as PrizmIconsType,
  'copy-column': 'copy-column' as PrizmIconsType,
  'copy-row': 'copy-row' as PrizmIconsType,
  copy: 'copy' as PrizmIconsType,
  crane: 'crane' as PrizmIconsType,
  'credit-card-plus': 'credit-card-plus' as PrizmIconsType,
  'credit-card-slash': 'credit-card-slash' as PrizmIconsType,
  'credit-card': 'credit-card' as PrizmIconsType,
  crop: 'crop' as PrizmIconsType,
  'crosshairs-dot': 'crosshairs-dot' as PrizmIconsType,
  'crosshairs-simple': 'crosshairs-simple' as PrizmIconsType,
  'cube-empty': 'cube-empty' as PrizmIconsType,
  'cube-fill': 'cube-fill' as PrizmIconsType,
  cubes: 'cubes' as PrizmIconsType,
  'dashboard-fill': 'dashboard-fill' as PrizmIconsType,
  'dashboard-variant-fill': 'dashboard-variant-fill' as PrizmIconsType,
  'database-check': 'database-check' as PrizmIconsType,
  'database-json': 'database-json' as PrizmIconsType,
  'database-magnifying-glass': 'database-magnifying-glass' as PrizmIconsType,
  'database-minus': 'database-minus' as PrizmIconsType,
  'database-plus': 'database-plus' as PrizmIconsType,
  'database-sql': 'database-sql' as PrizmIconsType,
  'database-xmark': 'database-xmark' as PrizmIconsType,
  database: 'database' as PrizmIconsType,
  'day-fill': 'day-fill' as PrizmIconsType,
  'decagram-check-empty': 'decagram-check-empty' as PrizmIconsType,
  'decagram-check-fill': 'decagram-check-fill' as PrizmIconsType,
  'decagram-exclamation-empty': 'decagram-exclamation-empty' as PrizmIconsType,
  'decagram-exclamation-fill': 'decagram-exclamation-fill' as PrizmIconsType,
  'default-ico': 'default-ico' as PrizmIconsType,
  'delete-content': 'delete-content' as PrizmIconsType,
  'desktop-tower': 'desktop-tower' as PrizmIconsType,
  'discrete-set': 'discrete-set' as PrizmIconsType,
  'dollar-slash': 'dollar-slash' as PrizmIconsType,
  dollar: 'dollar' as PrizmIconsType,
  doughnut: 'doughnut' as PrizmIconsType,
  'droplet-slash': 'droplet-slash' as PrizmIconsType,
  droplet: 'droplet' as PrizmIconsType,
  'early-version': 'early-version' as PrizmIconsType,
  earth: 'earth' as PrizmIconsType,
  'editor-alfavit': 'editor-alfavit' as PrizmIconsType,
  'editor-down': 'editor-down' as PrizmIconsType,
  'editor-right': 'editor-right' as PrizmIconsType,
  electrics: 'electrics' as PrizmIconsType,
  'ellipsis-2': 'ellipsis-2' as PrizmIconsType,
  'ellipsis-4': 'ellipsis-4' as PrizmIconsType,
  'ellipsis-grid': 'ellipsis-grid' as PrizmIconsType,
  'ellipsis-h': 'ellipsis-h' as PrizmIconsType,
  'ellipsis-v': 'ellipsis-v' as PrizmIconsType,
  'energy-check': 'energy-check' as PrizmIconsType,
  'energy-slash': 'energy-slash' as PrizmIconsType,
  energy: 'energy' as PrizmIconsType,
  'equipment-zql': 'equipment-zql' as PrizmIconsType,
  eraser: 'eraser' as PrizmIconsType,
  ethernet: 'ethernet' as PrizmIconsType,
  exchanger: 'exchanger' as PrizmIconsType,
  exclamation: 'exclamation' as PrizmIconsType,
  'eye-closed': 'eye-closed' as PrizmIconsType,
  'eye-slash': 'eye-slash' as PrizmIconsType,
  eye: 'eye' as PrizmIconsType,
  'face-frown': 'face-frown' as PrizmIconsType,
  'face-smile': 'face-smile' as PrizmIconsType,
  'factory-pipe': 'factory-pipe' as PrizmIconsType,
  'factory-pipes': 'factory-pipes' as PrizmIconsType,
  factory: 'factory' as PrizmIconsType,
  fan: 'fan' as PrizmIconsType,
  'file-arrow-down-left': 'file-arrow-down-left' as PrizmIconsType,
  'file-arrow-down': 'file-arrow-down' as PrizmIconsType,
  'file-arrow-up': 'file-arrow-up' as PrizmIconsType,
  'file-arrow': 'file-arrow' as PrizmIconsType,
  'file-arrows-up-right': 'file-arrows-up-right' as PrizmIconsType,
  'file-camera': 'file-camera' as PrizmIconsType,
  'file-cross-out': 'file-cross-out' as PrizmIconsType,
  'file-cross': 'file-cross' as PrizmIconsType,
  'file-doc': 'file-doc' as PrizmIconsType,
  'file-docx': 'file-docx' as PrizmIconsType,
  'file-dot': 'file-dot' as PrizmIconsType,
  'file-empty': 'file-empty' as PrizmIconsType,
  'file-exclamation': 'file-exclamation' as PrizmIconsType,
  'file-eye': 'file-eye' as PrizmIconsType,
  'file-filled': 'file-filled' as PrizmIconsType,
  'file-image': 'file-image' as PrizmIconsType,
  'file-magnifying-glass': 'file-magnifying-glass' as PrizmIconsType,
  'file-minus': 'file-minus' as PrizmIconsType,
  'file-music': 'file-music' as PrizmIconsType,
  'file-pdf': 'file-pdf' as PrizmIconsType,
  'file-pen': 'file-pen' as PrizmIconsType,
  'file-play': 'file-play' as PrizmIconsType,
  'file-plus': 'file-plus' as PrizmIconsType,
  'file-ppt': 'file-ppt' as PrizmIconsType,
  'file-pptx': 'file-pptx' as PrizmIconsType,
  'file-txt': 'file-txt' as PrizmIconsType,
  'file-up-right': 'file-up-right' as PrizmIconsType,
  'file-xls': 'file-xls' as PrizmIconsType,
  'file-xlsx': 'file-xlsx' as PrizmIconsType,
  'files-mode': 'files-mode' as PrizmIconsType,
  fill: 'fill' as PrizmIconsType,
  'film-cross': 'film-cross' as PrizmIconsType,
  film: 'film' as PrizmIconsType,
  'filter-gear': 'filter-gear' as PrizmIconsType,
  'filter-ontology': 'filter-ontology' as PrizmIconsType,
  filter: 'filter' as PrizmIconsType,
  'finance-scanner': 'finance-scanner' as PrizmIconsType,
  fire: 'fire' as PrizmIconsType,
  'flag-fill-red': 'flag-fill-red' as PrizmIconsType,
  'flag-fill': 'flag-fill' as PrizmIconsType,
  flag: 'flag' as PrizmIconsType,
  'flask-round-potion': 'flask-round-potion' as PrizmIconsType,
  flask: 'flask' as PrizmIconsType,
  'floppy-cross-out': 'floppy-cross-out' as PrizmIconsType,
  'floppy-cross': 'floppy-cross' as PrizmIconsType,
  'floppy-pen': 'floppy-pen' as PrizmIconsType,
  'floppy-star': 'floppy-star' as PrizmIconsType,
  floppy: 'floppy' as PrizmIconsType,
  'folder-arrow-down-left': 'folder-arrow-down-left' as PrizmIconsType,
  'folder-arrow-down': 'folder-arrow-down' as PrizmIconsType,
  'folder-arrow-up-right': 'folder-arrow-up-right' as PrizmIconsType,
  'folder-cross': 'folder-cross' as PrizmIconsType,
  'folder-dot': 'folder-dot' as PrizmIconsType,
  'folder-minus': 'folder-minus' as PrizmIconsType,
  'folder-open': 'folder-open' as PrizmIconsType,
  'folder-plus': 'folder-plus' as PrizmIconsType,
  'folder-underline-minus': 'folder-underline-minus' as PrizmIconsType,
  'folder-underline-plus': 'folder-underline-plus' as PrizmIconsType,
  'folder-underline': 'folder-underline' as PrizmIconsType,
  folder: 'folder' as PrizmIconsType,
  'forvard-step': 'forvard-step' as PrizmIconsType,
  forward: 'forward' as PrizmIconsType,
  'fuel-oil-tank': 'fuel-oil-tank' as PrizmIconsType,
  function: 'function' as PrizmIconsType,
  gantt: 'gantt' as PrizmIconsType,
  'gas-monitoring': 'gas-monitoring' as PrizmIconsType,
  'gas-pump': 'gas-pump' as PrizmIconsType,
  gas: 'gas' as PrizmIconsType,
  gauge: 'gauge' as PrizmIconsType,
  'gear-5-edge': 'gear-5-edge' as PrizmIconsType,
  'gear-8-edge': 'gear-8-edge' as PrizmIconsType,
  'gear-cooling': 'gear-cooling' as PrizmIconsType,
  generator: 'generator' as PrizmIconsType,
  gift: 'gift' as PrizmIconsType,
  'git-lab': 'git-lab' as PrizmIconsType,
  globe: 'globe' as PrizmIconsType,
  'grid-fill': 'grid-fill' as PrizmIconsType,
  grid: 'grid' as PrizmIconsType,
  'grip-dots-vertical': 'grip-dots-vertical' as PrizmIconsType,
  'hard-drive-outline': 'hard-drive-outline' as PrizmIconsType,
  'hash-dash': 'hash-dash' as PrizmIconsType,
  hash: 'hash' as PrizmIconsType,
  hashtag: 'hashtag' as PrizmIconsType,
  'headline-fill': 'headline-fill' as PrizmIconsType,
  headphones: 'headphones' as PrizmIconsType,
  'heart-empty': 'heart-empty' as PrizmIconsType,
  'heart-fill': 'heart-fill' as PrizmIconsType,
  'helicopter-side-view': 'helicopter-side-view' as PrizmIconsType,
  'hexagon-exclamation-empty': 'hexagon-exclamation-empty' as PrizmIconsType,
  'hexagon-exclamation-fill': 'hexagon-exclamation-fill' as PrizmIconsType,
  'hexagon-fill': 'hexagon-fill' as PrizmIconsType,
  hexagon: 'hexagon' as PrizmIconsType,
  'hexagons-axis': 'hexagons-axis' as PrizmIconsType,
  hexagons: 'hexagons' as PrizmIconsType,
  'histogram-axis': 'histogram-axis' as PrizmIconsType,
  'house-mini': 'house-mini' as PrizmIconsType,
  house: 'house' as PrizmIconsType,
  image: 'image' as PrizmIconsType,
  'importers-zql': 'importers-zql' as PrizmIconsType,
  'indent-arrow-left': 'indent-arrow-left' as PrizmIconsType,
  'indent-arrow-right': 'indent-arrow-right' as PrizmIconsType,
  infinity: 'infinity' as PrizmIconsType,
  info: 'info' as PrizmIconsType,
  'input-device': 'input-device' as PrizmIconsType,
  instagram: 'instagram' as PrizmIconsType,
  italic: 'italic' as PrizmIconsType,
  key: 'key' as PrizmIconsType,
  'keyboard-close': 'keyboard-close' as PrizmIconsType,
  keyboard: 'keyboard' as PrizmIconsType,
  'label-cross': 'label-cross' as PrizmIconsType,
  'label-exclamation-mark': 'label-exclamation-mark' as PrizmIconsType,
  'label-fill': 'label-fill' as PrizmIconsType,
  'label-magnifying-glass': 'label-magnifying-glass' as PrizmIconsType,
  'label-plus': 'label-plus' as PrizmIconsType,
  'label-question': 'label-question' as PrizmIconsType,
  'label-text': 'label-text' as PrizmIconsType,
  label: 'label' as PrizmIconsType,
  'lan-connect': 'lan-connect' as PrizmIconsType,
  'lan-disconnect': 'lan-disconnect' as PrizmIconsType,
  'lan-pending': 'lan-pending' as PrizmIconsType,
  'laptop-phone-slash': 'laptop-phone-slash' as PrizmIconsType,
  'laptop-phone': 'laptop-phone' as PrizmIconsType,
  'laptop-slash': 'laptop-slash' as PrizmIconsType,
  laptop: 'laptop' as PrizmIconsType,
  'layer-group-slash': 'layer-group-slash' as PrizmIconsType,
  'layer-group': 'layer-group' as PrizmIconsType,
  'layer-network': 'layer-network' as PrizmIconsType,
  'letter-day': 'letter-day' as PrizmIconsType,
  'letter-hour': 'letter-hour' as PrizmIconsType,
  'letter-minute': 'letter-minute' as PrizmIconsType,
  'letter-month': 'letter-month' as PrizmIconsType,
  'letter-second': 'letter-second' as PrizmIconsType,
  'letter-time': 'letter-time' as PrizmIconsType,
  'letter-year': 'letter-year' as PrizmIconsType,
  'library-plus': 'library-plus' as PrizmIconsType,
  lightbulb: 'lightbulb' as PrizmIconsType,
  'line-axis-arrow-down': 'line-axis-arrow-down' as PrizmIconsType,
  'line-axis-x': 'line-axis-x' as PrizmIconsType,
  'line-axis': 'line-axis' as PrizmIconsType,
  'line-down': 'line-down' as PrizmIconsType,
  'line-height': 'line-height' as PrizmIconsType,
  'line-multiple-y-axis-x': 'line-multiple-y-axis-x' as PrizmIconsType,
  'line-multiple-y-axis': 'line-multiple-y-axis' as PrizmIconsType,
  'line-up': 'line-up' as PrizmIconsType,
  line: 'line' as PrizmIconsType,
  'link-break': 'link-break' as PrizmIconsType,
  'link-minus': 'link-minus' as PrizmIconsType,
  'link-plus': 'link-plus' as PrizmIconsType,
  'link-simple-h': 'link-simple-h' as PrizmIconsType,
  'link-simple': 'link-simple' as PrizmIconsType,
  'list-fill': 'list-fill' as PrizmIconsType,
  'list-ol': 'list-ol' as PrizmIconsType,
  'list-square': 'list-square' as PrizmIconsType,
  'list-tree-archive': 'list-tree-archive' as PrizmIconsType,
  'list-tree': 'list-tree' as PrizmIconsType,
  'list-ul': 'list-ul' as PrizmIconsType,
  list: 'list' as PrizmIconsType,
  'lng-storage': 'lng-storage' as PrizmIconsType,
  loader: 'loader' as PrizmIconsType,
  'location-arrow': 'location-arrow' as PrizmIconsType,
  'location-directly': 'location-directly' as PrizmIconsType,
  'location-dot': 'location-dot' as PrizmIconsType,
  'location-minus': 'location-minus' as PrizmIconsType,
  'location-plus': 'location-plus' as PrizmIconsType,
  'location-slash': 'location-slash' as PrizmIconsType,
  'location-user': 'location-user' as PrizmIconsType,
  lock: 'lock' as PrizmIconsType,
  'logo-grey-z': 'logo-grey-z' as PrizmIconsType,
  'magic-wand': 'magic-wand' as PrizmIconsType,
  'magnifying-glass-exclamation': 'magnifying-glass-exclamation' as PrizmIconsType,
  'magnifying-glass-minus': 'magnifying-glass-minus' as PrizmIconsType,
  'magnifying-glass-ontology': 'magnifying-glass-ontology' as PrizmIconsType,
  'magnifying-glass-plus': 'magnifying-glass-plus' as PrizmIconsType,
  'magnifying-glass-qr-code': 'magnifying-glass-qr-code' as PrizmIconsType,
  'magnifying-glass-tags-words': 'magnifying-glass-tags-words' as PrizmIconsType,
  'magnifying-glass-top-from-bracket': 'magnifying-glass-top-from-bracket' as PrizmIconsType,
  'magnifying-glass-triangle-down': 'magnifying-glass-triangle-down' as PrizmIconsType,
  'magnifying-glass-xmark': 'magnifying-glass-xmark' as PrizmIconsType,
  'magnifying-glass': 'magnifying-glass' as PrizmIconsType,
  'mail-open': 'mail-open' as PrizmIconsType,
  mail: 'mail' as PrizmIconsType,
  mailbox: 'mailbox' as PrizmIconsType,
  'map-base': 'map-base' as PrizmIconsType,
  'map-marker-date-v-1': 'map-marker-date-v-1' as PrizmIconsType,
  'map-marker-date-v-2': 'map-marker-date-v-2' as PrizmIconsType,
  'map-pin': 'map-pin' as PrizmIconsType,
  map: 'map' as PrizmIconsType,
  medical: 'medical' as PrizmIconsType,
  merge: 'merge' as PrizmIconsType,
  'messaging-telegram-send': 'messaging-telegram-send' as PrizmIconsType,
  'messaging-telegram': 'messaging-telegram' as PrizmIconsType,
  'microphone-slash': 'microphone-slash' as PrizmIconsType,
  microphone: 'microphone' as PrizmIconsType,
  'minibus-side-view': 'minibus-side-view' as PrizmIconsType,
  'minus-circle-fill': 'minus-circle-fill' as PrizmIconsType,
  'minus-circle': 'minus-circle' as PrizmIconsType,
  minus: 'minus' as PrizmIconsType,
  'module-fill': 'module-fill' as PrizmIconsType,
  monitor: 'monitor' as PrizmIconsType,
  moon: 'moon' as PrizmIconsType,
  'music-slash': 'music-slash' as PrizmIconsType,
  music: 'music' as PrizmIconsType,
  'network-external-link': 'network-external-link' as PrizmIconsType,
  'network-v-1': 'network-v-1' as PrizmIconsType,
  'network-v-2': 'network-v-2' as PrizmIconsType,
  'network-v-3': 'network-v-3' as PrizmIconsType,
  'network-wired': 'network-wired' as PrizmIconsType,
  'ni-fi-logo': 'ni-fi-logo' as PrizmIconsType,
  'normdocs-zql': 'normdocs-zql' as PrizmIconsType,
  npm: 'npm' as PrizmIconsType,
  'object-down-subtract': 'object-down-subtract' as PrizmIconsType,
  'object-exclude': 'object-exclude' as PrizmIconsType,
  'object-intersect': 'object-intersect' as PrizmIconsType,
  'object-selection': 'object-selection' as PrizmIconsType,
  'object-union': 'object-union' as PrizmIconsType,
  'object-up-subtract': 'object-up-subtract' as PrizmIconsType,
  object: 'object' as PrizmIconsType,
  'oil-barrel': 'oil-barrel' as PrizmIconsType,
  'oil-canister': 'oil-canister' as PrizmIconsType,
  'oil-droplet-arrows-all': 'oil-droplet-arrows-all' as PrizmIconsType,
  'oil-droplet-arrows-repeat-h': 'oil-droplet-arrows-repeat-h' as PrizmIconsType,
  'oil-droplet-check': 'oil-droplet-check' as PrizmIconsType,
  'oil-droplet': 'oil-droplet' as PrizmIconsType,
  'oil-field': 'oil-field' as PrizmIconsType,
  'oil-tank': 'oil-tank' as PrizmIconsType,
  ontology: 'ontology' as PrizmIconsType,
  owl: 'owl' as PrizmIconsType,
  palette: 'palette' as PrizmIconsType,
  'panel-down': 'panel-down' as PrizmIconsType,
  'panel-left': 'panel-left' as PrizmIconsType,
  'panel-right': 'panel-right' as PrizmIconsType,
  'panel-top': 'panel-top' as PrizmIconsType,
  pantone: 'pantone' as PrizmIconsType,
  'paperclip-vertical': 'paperclip-vertical' as PrizmIconsType,
  paperclip: 'paperclip' as PrizmIconsType,
  pause: 'pause' as PrizmIconsType,
  'paver-side-view': 'paver-side-view' as PrizmIconsType,
  'pen-hammer': 'pen-hammer' as PrizmIconsType,
  'pencil-arrow-right': 'pencil-arrow-right' as PrizmIconsType,
  'pencil-line-bottom': 'pencil-line-bottom' as PrizmIconsType,
  'pencil-triangle-down': 'pencil-triangle-down' as PrizmIconsType,
  'pencil-triangle-left': 'pencil-triangle-left' as PrizmIconsType,
  pencil: 'pencil' as PrizmIconsType,
  'pentagon-fill': 'pentagon-fill' as PrizmIconsType,
  pentagon: 'pentagon' as PrizmIconsType,
  percent: 'percent' as PrizmIconsType,
  'phone-active-call': 'phone-active-call' as PrizmIconsType,
  'phone-cancelled': 'phone-cancelled' as PrizmIconsType,
  'phone-minus': 'phone-minus' as PrizmIconsType,
  'phone-off': 'phone-off' as PrizmIconsType,
  'phone-paused': 'phone-paused' as PrizmIconsType,
  'phone-plus': 'phone-plus' as PrizmIconsType,
  phone: 'phone' as PrizmIconsType,
  pi: 'pi' as PrizmIconsType,
  'pie-line-nested': 'pie-line-nested' as PrizmIconsType,
  'pie-line': 'pie-line' as PrizmIconsType,
  'pie-pentagon': 'pie-pentagon' as PrizmIconsType,
  'pie-several-parts': 'pie-several-parts' as PrizmIconsType,
  'pie-simple': 'pie-simple' as PrizmIconsType,
  pie: 'pie' as PrizmIconsType,
  'pipe-node-side-view': 'pipe-node-side-view' as PrizmIconsType,
  'pipe-sensor': 'pipe-sensor' as PrizmIconsType,
  'pipe-slash': 'pipe-slash' as PrizmIconsType,
  'pipe-valve': 'pipe-valve' as PrizmIconsType,
  'pipeline-v-1': 'pipeline-v-1' as PrizmIconsType,
  'pipeline-v-2': 'pipeline-v-2' as PrizmIconsType,
  pipette: 'pipette' as PrizmIconsType,
  plane: 'plane' as PrizmIconsType,
  'play-back': 'play-back' as PrizmIconsType,
  play: 'play' as PrizmIconsType,
  'plus-column-right': 'plus-column-right' as PrizmIconsType,
  'plus-column-top': 'plus-column-top' as PrizmIconsType,
  'plus-triangle-down': 'plus-triangle-down' as PrizmIconsType,
  plus: 'plus' as PrizmIconsType,
  polyline: 'polyline' as PrizmIconsType,
  portfel: 'portfel' as PrizmIconsType,
  power: 'power' as PrizmIconsType,
  'premises-zql': 'premises-zql' as PrizmIconsType,
  press: 'press' as PrizmIconsType,
  printer: 'printer' as PrizmIconsType,
  process: 'process' as PrizmIconsType,
  pulse: 'pulse' as PrizmIconsType,
  'pump-triangle-down': 'pump-triangle-down' as PrizmIconsType,
  pump: 'pump' as PrizmIconsType,
  'push-button-cellphone': 'push-button-cellphone' as PrizmIconsType,
  qr: 'qr' as PrizmIconsType,
  question: 'question' as PrizmIconsType,
  'quilt-fill': 'quilt-fill' as PrizmIconsType,
  radiation: 'radiation' as PrizmIconsType,
  'railcar-hopper-side-view': 'railcar-hopper-side-view' as PrizmIconsType,
  'railcar-side-view': 'railcar-side-view' as PrizmIconsType,
  range: 'range' as PrizmIconsType,
  replay: 'replay' as PrizmIconsType,
  'respirator-v-1': 'respirator-v-1' as PrizmIconsType,
  'respirator-v-2': 'respirator-v-2' as PrizmIconsType,
  'respirator-v-3': 'respirator-v-3' as PrizmIconsType,
  'respirator-v-4': 'respirator-v-4' as PrizmIconsType,
  'respirator-v-5': 'respirator-v-5' as PrizmIconsType,
  'respirator-v-6': 'respirator-v-6' as PrizmIconsType,
  'return-step': 'return-step' as PrizmIconsType,
  return: 'return' as PrizmIconsType,
  'rhombus-fill': 'rhombus-fill' as PrizmIconsType,
  rhombus: 'rhombus' as PrizmIconsType,
  'ring-in-square': 'ring-in-square' as PrizmIconsType,
  'ruler-index-zql': 'ruler-index-zql' as PrizmIconsType,
  ruler: 'ruler' as PrizmIconsType,
  'sample-logs-zql': 'sample-logs-zql' as PrizmIconsType,
  'sample-templates-zql': 'sample-templates-zql' as PrizmIconsType,
  sandglass: 'sandglass' as PrizmIconsType,
  'scatter-axis': 'scatter-axis' as PrizmIconsType,
  'scatter-basic': 'scatter-basic' as PrizmIconsType,
  scatter: 'scatter' as PrizmIconsType,
  scissors: 'scissors' as PrizmIconsType,
  'seedling-circle': 'seedling-circle' as PrizmIconsType,
  'seedling-rectangle': 'seedling-rectangle' as PrizmIconsType,
  seedling: 'seedling' as PrizmIconsType,
  'selection-checked-empty': 'selection-checked-empty' as PrizmIconsType,
  'send-backward': 'send-backward' as PrizmIconsType,
  'send-forward': 'send-forward' as PrizmIconsType,
  separator: 'separator' as PrizmIconsType,
  'sequential-fill': 'sequential-fill' as PrizmIconsType,
  'server-network-close': 'server-network-close' as PrizmIconsType,
  'server-network-download': 'server-network-download' as PrizmIconsType,
  'server-network-minus': 'server-network-minus' as PrizmIconsType,
  'server-network-plus': 'server-network-plus' as PrizmIconsType,
  'server-network-security': 'server-network-security' as PrizmIconsType,
  'server-network-upload': 'server-network-upload' as PrizmIconsType,
  'server-network': 'server-network' as PrizmIconsType,
  'server-shield': 'server-shield' as PrizmIconsType,
  'server-slash-up-line': 'server-slash-up-line' as PrizmIconsType,
  'server-slash': 'server-slash' as PrizmIconsType,
  'server-up-line': 'server-up-line' as PrizmIconsType,
  server: 'server' as PrizmIconsType,
  'shape-link-fill': 'shape-link-fill' as PrizmIconsType,
  'shape-link': 'shape-link' as PrizmIconsType,
  'shape-plus': 'shape-plus' as PrizmIconsType,
  'share-left': 'share-left' as PrizmIconsType,
  'share-right': 'share-right' as PrizmIconsType,
  shareing: 'shareing' as PrizmIconsType,
  'shield-exclamation': 'shield-exclamation' as PrizmIconsType,
  'shield-slash': 'shield-slash' as PrizmIconsType,
  shield: 'shield' as PrizmIconsType,
  'ship-front-view': 'ship-front-view' as PrizmIconsType,
  'ship-side-view': 'ship-side-view' as PrizmIconsType,
  'shopping-box': 'shopping-box' as PrizmIconsType,
  shovel: 'shovel' as PrizmIconsType,
  sigma: 'sigma' as PrizmIconsType,
  'signal-access-point': 'signal-access-point' as PrizmIconsType,
  'signal-off': 'signal-off' as PrizmIconsType,
  'signal-radio-tower': 'signal-radio-tower' as PrizmIconsType,
  signal: 'signal' as PrizmIconsType,
  'sliders-h': 'sliders-h' as PrizmIconsType,
  'sliders-v': 'sliders-v' as PrizmIconsType,
  'snake-cup': 'snake-cup' as PrizmIconsType,
  'snowflake-up-to-bracket': 'snowflake-up-to-bracket' as PrizmIconsType,
  snowflake: 'snowflake' as PrizmIconsType,
  'sort-filter-remove': 'sort-filter-remove' as PrizmIconsType,
  specification: 'specification' as PrizmIconsType,
  'specs-zql': 'specs-zql' as PrizmIconsType,
  'speed-reduction-cooling': 'speed-reduction-cooling' as PrizmIconsType,
  'square-arrows-curve-right-down': 'square-arrows-curve-right-down' as PrizmIconsType,
  'square-arrows-curve-up-right': 'square-arrows-curve-up-right' as PrizmIconsType,
  'square-bracket-curly-left': 'square-bracket-curly-left' as PrizmIconsType,
  'square-bracket-curly-right': 'square-bracket-curly-right' as PrizmIconsType,
  'square-bracket-left': 'square-bracket-left' as PrizmIconsType,
  'square-bracket-right': 'square-bracket-right' as PrizmIconsType,
  'square-circle-plus-triangle-fill': 'square-circle-plus-triangle-fill' as PrizmIconsType,
  'square-circle-triangle-cross-fill': 'square-circle-triangle-cross-fill' as PrizmIconsType,
  'square-circle-triangle-cross': 'square-circle-triangle-cross' as PrizmIconsType,
  'square-divide': 'square-divide' as PrizmIconsType,
  'square-excel': 'square-excel' as PrizmIconsType,
  'square-exclamation-empty': 'square-exclamation-empty' as PrizmIconsType,
  'square-exclamation-fill': 'square-exclamation-fill' as PrizmIconsType,
  'square-fill': 'square-fill' as PrizmIconsType,
  'square-hexagon-circle-fill': 'square-hexagon-circle-fill' as PrizmIconsType,
  'square-hexagon-circle': 'square-hexagon-circle' as PrizmIconsType,
  'square-in-square': 'square-in-square' as PrizmIconsType,
  'square-info-empty': 'square-info-empty' as PrizmIconsType,
  'square-info-fill': 'square-info-fill' as PrizmIconsType,
  'square-minus': 'square-minus' as PrizmIconsType,
  'square-multiply': 'square-multiply' as PrizmIconsType,
  'square-music-double': 'square-music-double' as PrizmIconsType,
  'square-music': 'square-music' as PrizmIconsType,
  'square-percent': 'square-percent' as PrizmIconsType,
  'square-plus': 'square-plus' as PrizmIconsType,
  'square-powerpoint': 'square-powerpoint' as PrizmIconsType,
  'square-question-empty': 'square-question-empty' as PrizmIconsType,
  'square-question-fill': 'square-question-fill' as PrizmIconsType,
  'square-triangle-circle-triangle-fill': 'square-triangle-circle-triangle-fill' as PrizmIconsType,
  'square-triangle-circle-triangle': 'square-triangle-circle-triangle' as PrizmIconsType,
  'square-word': 'square-word' as PrizmIconsType,
  'star-empty': 'star-empty' as PrizmIconsType,
  'star-fill': 'star-fill' as PrizmIconsType,
  'star-half-fill': 'star-half-fill' as PrizmIconsType,
  'star-line': 'star-line' as PrizmIconsType,
  'steam-boiler': 'steam-boiler' as PrizmIconsType,
  'steam-turbine': 'steam-turbine' as PrizmIconsType,
  steam: 'steam' as PrizmIconsType,
  stop: 'stop' as PrizmIconsType,
  stopwatch: 'stopwatch' as PrizmIconsType,
  storage: 'storage' as PrizmIconsType,
  strikethrough: 'strikethrough' as PrizmIconsType,
  'subskrit-down': 'subskrit-down' as PrizmIconsType,
  'subskrit-up': 'subskrit-up' as PrizmIconsType,
  sun: 'sun' as PrizmIconsType,
  'support-man': 'support-man' as PrizmIconsType,
  'symbol-asterisk': 'symbol-asterisk' as PrizmIconsType,
  'table-down': 'table-down' as PrizmIconsType,
  'table-gear': 'table-gear' as PrizmIconsType,
  'table-magnifying-glass': 'table-magnifying-glass' as PrizmIconsType,
  'table-right': 'table-right' as PrizmIconsType,
  'table-transpose-right': 'table-transpose-right' as PrizmIconsType,
  'table-transpose-top': 'table-transpose-top' as PrizmIconsType,
  'table-transpose': 'table-transpose' as PrizmIconsType,
  table: 'table' as PrizmIconsType,
  'tablet-iqc-zql': 'tablet-iqc-zql' as PrizmIconsType,
  tablet: 'tablet' as PrizmIconsType,
  tank: 'tank' as PrizmIconsType,
  'tankcar-oil-side-view': 'tankcar-oil-side-view' as PrizmIconsType,
  'tankcar-side-view': 'tankcar-side-view' as PrizmIconsType,
  'techs-zql': 'techs-zql' as PrizmIconsType,
  'temp-add-shape-square-plus': 'temp-add-shape-square-plus' as PrizmIconsType,
  'temp-alerts-alarm-light': 'temp-alerts-alarm-light' as PrizmIconsType,
  'temp-alerts-circle-mini': 'temp-alerts-circle-mini' as PrizmIconsType,
  'temp-arrows-corner-chek-in': 'temp-arrows-corner-chek-in' as PrizmIconsType,
  'temp-arrows-down-double-top-2': 'temp-arrows-down-double-top-2' as PrizmIconsType,
  'temp-arrows-up-double-top-2': 'temp-arrows-up-double-top-2' as PrizmIconsType,
  'temp-audio-artist': 'temp-audio-artist' as PrizmIconsType,
  'temp-badges-tag-multiple': 'temp-badges-tag-multiple' as PrizmIconsType,
  'temp-chevrons-dropdown-small': 'temp-chevrons-dropdown-small' as PrizmIconsType,
  'temp-chevrons-menu-left-small': 'temp-chevrons-menu-left-small' as PrizmIconsType,
  'temp-date-inform-delete': 'temp-date-inform-delete' as PrizmIconsType,
  'temp-editor-discrete-set': 'temp-editor-discrete-set' as PrizmIconsType,
  'temp-editor-wrap-text': 'temp-editor-wrap-text' as PrizmIconsType,
  'temp-files-import-1-c': 'temp-files-import-1-c' as PrizmIconsType,
  'temp-location-localization-down-list': 'temp-location-localization-down-list' as PrizmIconsType,
  'temp-network-library': 'temp-network-library' as PrizmIconsType,
  'temp-poi-difference': 'temp-poi-difference' as PrizmIconsType,
  'temp-product-grade-according-to-specification':
    'temp-product-grade-according-to-specification' as PrizmIconsType,
  'temp-selection-checkbox-blank-circle': 'temp-selection-checkbox-blank-circle' as PrizmIconsType,
  'temp-selection-checkbox-blank': 'temp-selection-checkbox-blank' as PrizmIconsType,
  'temp-selection-checkbox-marked-circle-chanel':
    'temp-selection-checkbox-marked-circle-chanel' as PrizmIconsType,
  'temp-selection-checkbox-marked-circle': 'temp-selection-checkbox-marked-circle' as PrizmIconsType,
  'temp-selection-checked-1': 'temp-selection-checked-1' as PrizmIconsType,
  'temp-selection-checked-2': 'temp-selection-checked-2' as PrizmIconsType,
  'temp-selection-checked': 'temp-selection-checked' as PrizmIconsType,
  'temp-selection-radio-off': 'temp-selection-radio-off' as PrizmIconsType,
  'temp-selection-radio-on': 'temp-selection-radio-on' as PrizmIconsType,
  'temp-selection-toggle-left': 'temp-selection-toggle-left' as PrizmIconsType,
  'temp-selection-toggle-right': 'temp-selection-toggle-right' as PrizmIconsType,
  'temp-selection-unchoice': 'temp-selection-unchoice' as PrizmIconsType,
  'temp-shapes-checkbox-intermediate': 'temp-shapes-checkbox-intermediate' as PrizmIconsType,
  'temp-signal-cast': 'temp-signal-cast' as PrizmIconsType,
  'temp-signal-rss-box': 'temp-signal-rss-box' as PrizmIconsType,
  'temp-social-tab': 'temp-social-tab' as PrizmIconsType,
  'temp-sort-version': 'temp-sort-version' as PrizmIconsType,
  'temp-tools-solver': 'temp-tools-solver' as PrizmIconsType,
  'temperature-minus': 'temperature-minus' as PrizmIconsType,
  'temperature-plus': 'temperature-plus' as PrizmIconsType,
  temperature: 'temperature' as PrizmIconsType,
  template: 'template' as PrizmIconsType,
  'text-color': 'text-color' as PrizmIconsType,
  text: 'text' as PrizmIconsType,
  'thumb-down': 'thumb-down' as PrizmIconsType,
  'thumb-up': 'thumb-up' as PrizmIconsType,
  'thumbtack-directly': 'thumbtack-directly' as PrizmIconsType,
  thumbtack: 'thumbtack' as PrizmIconsType,
  touchpad: 'touchpad' as PrizmIconsType,
  'train-cargo': 'train-cargo' as PrizmIconsType,
  'train-passenger': 'train-passenger' as PrizmIconsType,
  transmitter: 'transmitter' as PrizmIconsType,
  'trash-drop': 'trash-drop' as PrizmIconsType,
  'trash-empty': 'trash-empty' as PrizmIconsType,
  trash: 'trash' as PrizmIconsType,
  'triangle-circle-square-fill': 'triangle-circle-square-fill' as PrizmIconsType,
  'triangle-circle-square': 'triangle-circle-square' as PrizmIconsType,
  'triangle-cross-empty': 'triangle-cross-empty' as PrizmIconsType,
  'triangle-down': 'triangle-down' as PrizmIconsType,
  'triangle-exclamation-empty': 'triangle-exclamation-empty' as PrizmIconsType,
  'triangle-exclamation-fill': 'triangle-exclamation-fill' as PrizmIconsType,
  'triangle-fill': 'triangle-fill' as PrizmIconsType,
  'triangle-info-empty': 'triangle-info-empty' as PrizmIconsType,
  'triangle-info-fill': 'triangle-info-fill' as PrizmIconsType,
  'triangle-left': 'triangle-left' as PrizmIconsType,
  'triangle-plus-empty': 'triangle-plus-empty' as PrizmIconsType,
  'triangle-right': 'triangle-right' as PrizmIconsType,
  'triangle-up-down': 'triangle-up-down' as PrizmIconsType,
  'triangle-up': 'triangle-up' as PrizmIconsType,
  triangle: 'triangle' as PrizmIconsType,
  'truck-dump-side-view': 'truck-dump-side-view' as PrizmIconsType,
  'truck-empty-side-view': 'truck-empty-side-view' as PrizmIconsType,
  'truck-garbage-side-view': 'truck-garbage-side-view' as PrizmIconsType,
  'truck-gasoline-side-view': 'truck-gasoline-side-view' as PrizmIconsType,
  'truck-side-view': 'truck-side-view' as PrizmIconsType,
  tv: 'tv' as PrizmIconsType,
  umbrella: 'umbrella' as PrizmIconsType,
  underlined: 'underlined' as PrizmIconsType,
  unlock: 'unlock' as PrizmIconsType,
  'user-arrows-swap': 'user-arrows-swap' as PrizmIconsType,
  'user-card': 'user-card' as PrizmIconsType,
  'user-check': 'user-check' as PrizmIconsType,
  'user-chevron': 'user-chevron' as PrizmIconsType,
  'user-circle': 'user-circle' as PrizmIconsType,
  'user-cross': 'user-cross' as PrizmIconsType,
  'user-gear': 'user-gear' as PrizmIconsType,
  'user-group': 'user-group' as PrizmIconsType,
  'user-location': 'user-location' as PrizmIconsType,
  'user-minus': 'user-minus' as PrizmIconsType,
  'user-pen': 'user-pen' as PrizmIconsType,
  'user-plus': 'user-plus' as PrizmIconsType,
  'user-shield': 'user-shield' as PrizmIconsType,
  'user-worker': 'user-worker' as PrizmIconsType,
  user: 'user' as PrizmIconsType,
  'users-arrow-right': 'users-arrow-right' as PrizmIconsType,
  'users-arrow-up-zql': 'users-arrow-up-zql' as PrizmIconsType,
  'users-gear': 'users-gear' as PrizmIconsType,
  'users-key': 'users-key' as PrizmIconsType,
  'users-location': 'users-location' as PrizmIconsType,
  'users-minus': 'users-minus' as PrizmIconsType,
  'users-plus': 'users-plus' as PrizmIconsType,
  users: 'users' as PrizmIconsType,
  'vector-circle-one-point': 'vector-circle-one-point' as PrizmIconsType,
  'vector-circle-two-point': 'vector-circle-two-point' as PrizmIconsType,
  'vector-circle': 'vector-circle' as PrizmIconsType,
  'vector-line': 'vector-line' as PrizmIconsType,
  'vector-point': 'vector-point' as PrizmIconsType,
  'vector-polygon': 'vector-polygon' as PrizmIconsType,
  'vector-rectangle': 'vector-rectangle' as PrizmIconsType,
  'vector-square': 'vector-square' as PrizmIconsType,
  'vector-triangle': 'vector-triangle' as PrizmIconsType,
  'vectors-radius': 'vectors-radius' as PrizmIconsType,
  'vial-flask': 'vial-flask' as PrizmIconsType,
  'vial-plus': 'vial-plus' as PrizmIconsType,
  'vial-rotate-left': 'vial-rotate-left' as PrizmIconsType,
  'vial-xmark': 'vial-xmark' as PrizmIconsType,
  vial: 'vial' as PrizmIconsType,
  'video-cross': 'video-cross' as PrizmIconsType,
  'video-slash': 'video-slash' as PrizmIconsType,
  video: 'video' as PrizmIconsType,
  voicemail: 'voicemail' as PrizmIconsType,
  'volume-cross': 'volume-cross' as PrizmIconsType,
  'volume-low': 'volume-low' as PrizmIconsType,
  'volume-minus': 'volume-minus' as PrizmIconsType,
  'volume-off': 'volume-off' as PrizmIconsType,
  'volume-plus': 'volume-plus' as PrizmIconsType,
  'volume-slash': 'volume-slash' as PrizmIconsType,
  volume: 'volume' as PrizmIconsType,
  wallet: 'wallet' as PrizmIconsType,
  watch: 'watch' as PrizmIconsType,
  'water-heater': 'water-heater' as PrizmIconsType,
  'water-pump': 'water-pump' as PrizmIconsType,
  webcam: 'webcam' as PrizmIconsType,
  'wifi-off': 'wifi-off' as PrizmIconsType,
  wifi: 'wifi' as PrizmIconsType,
  window: 'window' as PrizmIconsType,
  wrench: 'wrench' as PrizmIconsType,
  'xmark-column-right': 'xmark-column-right' as PrizmIconsType,
  'xmark-column-top': 'xmark-column-top' as PrizmIconsType,
  'xmark-mini': 'xmark-mini' as PrizmIconsType,
  xmark: 'xmark' as PrizmIconsType,
};
export interface PrizmIcons {
  name: PrizmIconsEnum;
  data: string;
}
export enum PrizmIconsEnum {
  AGENDA_FILL = 'agenda-fill',
  AIRPLANE_SIDE_VIEW = 'airplane-side-view',
  AIRPLANE_TOP_VIEW = 'airplane-top-view',
  ALARM_CLOCK = 'alarm-clock',
  ALARM_PLUS = 'alarm-plus',
  ALIGN_BOTTOM = 'align-bottom',
  ALIGN_CENTER_H = 'align-center-h',
  ALIGN_CENTER_V = 'align-center-v',
  ALIGN_CENTER = 'align-center',
  ALIGN_JUSTIFY = 'align-justify',
  ALIGN_LEFT = 'align-left',
  ALIGN_RIGHT = 'align-right',
  ALIGN_TOP = 'align-top',
  AMBULANCE_SIDE_VIEW = 'ambulance-side-view',
  ANDROID_PILL = 'android-pill',
  ANDROID = 'android',
  ANGLE_LEFT_RIGHT_ARROWS_DOWN = 'angle-left-right-arrows-down',
  ANGLE_LEFT_RIGHT = 'angle-left-right',
  ANGLE_LEFT = 'angle-left',
  ANGLE_RIGHT = 'angle-right',
  ARRANGE_SEND_TO_BACK = 'arrange-send-to-back',
  ARRAY_FILL = 'array-fill',
  ARROW_BOLD_DOWN = 'arrow-bold-down',
  ARROW_BOLD_LEFT = 'arrow-bold-left',
  ARROW_BOLD_RIGHT = 'arrow-bold-right',
  ARROW_BOLD_UP = 'arrow-bold-up',
  ARROW_DAW = 'arrow-daw',
  ARROW_DOWN_BETWEEN_LINES = 'arrow-down-between-lines',
  ARROW_DOWN_FROM_DOUBLE_LINE = 'arrow-down-from-double-line',
  ARROW_DOWN_FROM_LINE = 'arrow-down-from-line',
  ARROW_DOWN_IN_CIRCLE_EMPTY = 'arrow-down-in-circle-empty',
  ARROW_DOWN_TO_BRACKET = 'arrow-down-to-bracket',
  ARROW_DOWN_TO_DOUBLE_LINE = 'arrow-down-to-double-line',
  ARROW_DOWN_TO_LINE = 'arrow-down-to-line',
  ARROW_DOWN_WIDE_SHORT = 'arrow-down-wide-short',
  ARROW_DOWN = 'arrow-down',
  ARROW_LEFT_BETWEEN_LINES = 'arrow-left-between-lines',
  ARROW_LEFT_DOWN = 'arrow-left-down',
  ARROW_LEFT_FROM_BRACKET = 'arrow-left-from-bracket',
  ARROW_LEFT_IN_CIRCLE_EMPTY = 'arrow-left-in-circle-empty',
  ARROW_LEFT_UP = 'arrow-left-up',
  ARROW_LEFT = 'arrow-left',
  ARROW_RIGHT_BETWEEN_LINES = 'arrow-right-between-lines',
  ARROW_RIGHT_DOWN = 'arrow-right-down',
  ARROW_RIGHT_FROM_BRACKET = 'arrow-right-from-bracket',
  ARROW_RIGHT_IN_CIRCLE_EMPTY = 'arrow-right-in-circle-empty',
  ARROW_RIGHT_UP = 'arrow-right-up',
  ARROW_RIGHT = 'arrow-right',
  ARROW_ROTATE_LEFT = 'arrow-rotate-left',
  ARROW_ROTATE_RIGHT = 'arrow-rotate-right',
  ARROW_TURN_DOWN_LEFT = 'arrow-turn-down-left',
  ARROW_TURN_DOWN_RIGHT = 'arrow-turn-down-right',
  ARROW_TURN_LEFT_DOWN = 'arrow-turn-left-down',
  ARROW_TURN_LEFT_UP = 'arrow-turn-left-up',
  ARROW_TURN_RIGHT_DOWN = 'arrow-turn-right-down',
  ARROW_TURN_RIGHT_UP = 'arrow-turn-right-up',
  ARROW_TURN_UP_LEFT = 'arrow-turn-up-left',
  ARROW_TURN_UP_RIGHT = 'arrow-turn-up-right',
  ARROW_UP_ARROW_DOWN_H = 'arrow-up-arrow-down-h',
  ARROW_UP_ARROW_DOWN_V = 'arrow-up-arrow-down-v',
  ARROW_UP_BETWEEN_LINES = 'arrow-up-between-lines',
  ARROW_UP_FROM_BRACKET = 'arrow-up-from-bracket',
  ARROW_UP_FROM_DOUBLE_LINE = 'arrow-up-from-double-line',
  ARROW_UP_FROM_LINE = 'arrow-up-from-line',
  ARROW_UP_IN_CIRCLE_EMPTY = 'arrow-up-in-circle-empty',
  ARROW_UP_TO_DOTTED_LINE = 'arrow-up-to-dotted-line',
  ARROW_UP_TO_DOUBLE_LINE = 'arrow-up-to-double-line',
  ARROW_UP_TO_LINE = 'arrow-up-to-line',
  ARROW_UP_WIDE_SHORT = 'arrow-up-wide-short',
  ARROW_UP = 'arrow-up',
  ARROWS_ALL_DIRECTIONS = 'arrows-all-directions',
  ARROWS_COMPARE = 'arrows-compare',
  ARROWS_CROSS = 'arrows-cross',
  ARROWS_DIRECTION_TO_THE_LINES = 'arrows-direction-to-the-lines',
  ARROWS_INSIDE = 'arrows-inside',
  ARROWS_LEFT_RIGHT_BRACKETS = 'arrows-left-right-brackets',
  ARROWS_LEFT_RIGHT_TO_LINES = 'arrows-left-right-to-lines',
  ARROWS_MAXIMIZE = 'arrows-maximize',
  ARROWS_MINIMIZE = 'arrows-minimize',
  ARROWS_OUT = 'arrows-out',
  ARROWS_REPEAT_H = 'arrows-repeat-h',
  ARROWS_REPEAT_V = 'arrows-repeat-v',
  ARROWS_ROTATE_H = 'arrows-rotate-h',
  ARROWS_ROTATE_V = 'arrows-rotate-v',
  ARROWS_SHUFFLE = 'arrows-shuffle',
  ARROWS_SWAP_H = 'arrows-swap-h',
  ARROWS_SWAP_V = 'arrows-swap-v',
  ARROWS_UP_DOWN_TO_LINES = 'arrows-up-down-to-lines',
  AT_SIGN = 'at-sign',
  AUDIO_SPEAKERS = 'audio-speakers',
  AWARD = 'award',
  BAG = 'bag',
  BALLOON = 'balloon',
  BAN = 'ban',
  BAR_AXIS = 'bar-axis',
  BAR_BASIC = 'bar-basic',
  BAR_COLUMNS_AXIS_X = 'bar-columns-axis-x',
  BAR_COLUMNS = 'bar-columns',
  BAR_HISTOGRAM = 'bar-histogram',
  BAR_SQUARE = 'bar-square',
  BAR_STACKED_AXIS = 'bar-stacked-axis',
  BAR_STACKED = 'bar-stacked',
  BARCODE = 'barcode',
  BARS_SORT = 'bars-sort',
  BARS = 'bars',
  BATTERY_BOLT = 'battery-bolt',
  BATTERY_EMPTY = 'battery-empty',
  BATTERY_EXCLAMETION_MARK = 'battery-exclametion-mark',
  BATTERY_FULL = 'battery-full',
  BATTERY_HALF = 'battery-half',
  BATTERY_MINUS = 'battery-minus',
  BATTERY_PLUS = 'battery-plus',
  BATTERY_QUARTER = 'battery-quarter',
  BATTERY_THREE_QUARTERS = 'battery-three-quarters',
  BDRV_ADMINISTRATION = 'bdrv-administration',
  BELL_FILL = 'bell-fill',
  BELL_PLUS = 'bell-plus',
  BELL_RING_FILL = 'bell-ring-fill',
  BELL_RING = 'bell-ring',
  BELL_SLASH = 'bell-slash',
  BELL = 'bell',
  BEZIER_CURVE = 'bezier-curve',
  BITCOIN = 'bitcoin',
  BLOCK_LINES = 'block-lines',
  BLUETOOTH = 'bluetooth',
  BOILER_HOT_WATER_GAS_AND_OIL = 'boiler-hot-water-gas-and-oil',
  BOILER = 'boiler',
  BOLD = 'bold',
  BOOK_CHECK = 'book-check',
  BOOK_CLOSED = 'book-closed',
  BOOK_OPEN_PLUS = 'book-open-plus',
  BOOK_OPEN_RE_ARROW_LEFT = 'book-open-re-arrow-left',
  BOOK_OPEN_RE_ARROW_RIGHT = 'book-open-re-arrow-right',
  BOOK_OPEN_TEXT = 'book-open-text',
  BOOK_OPEN_WITH_PAGE = 'book-open-with-page',
  BOOK_OPEN = 'book-open',
  BOOK_STACK = 'book-stack',
  BOOKMARK_CHECK_FILL = 'bookmark-check-fill',
  BOOKMARK_CHECK = 'bookmark-check',
  BOOKMARK_CROSS_FILL = 'bookmark-cross-fill',
  BOOKMARK_CROSS = 'bookmark-cross',
  BOOKMARK_FILL = 'bookmark-fill',
  BOOKMARK_MUSIC_FILL = 'bookmark-music-fill',
  BOOKMARK_MUSIC = 'bookmark-music',
  BOOKMARK_PLUS_FILL = 'bookmark-plus-fill',
  BOOKMARK_PLUS = 'bookmark-plus',
  BOOKMARK = 'bookmark',
  BORDER_ALL = 'border-all',
  BORDER_BOTTOM = 'border-bottom',
  BORDER_CENTER_H = 'border-center-h',
  BORDER_CENTER_V = 'border-center-v',
  BORDER_INNER = 'border-inner',
  BORDER_LEFT = 'border-left',
  BORDER_NONE = 'border-none',
  BORDER_OUTER = 'border-outer',
  BORDER_RIGHT = 'border-right',
  BORDER_TOP_LEFT = 'border-top-left',
  BORDER_TOP = 'border-top',
  BOX_OPEN_ARROW_DOWN = 'box-open-arrow-down',
  BOX = 'box',
  BRACKETS_CURLY = 'brackets-curly',
  BRACKETS_SQUARE = 'brackets-square',
  BROOM = 'broom',
  BROWSER = 'browser',
  BRUSH = 'brush',
  BUBBLE_MESSAGE_ADD = 'bubble-message-add',
  BUBBLE_MESSAGE_ATTENTION = 'bubble-message-attention',
  BUBBLE_MESSAGE_CIRCLE = 'bubble-message-circle',
  BUBBLE_MESSAGE_EMPTY = 'bubble-message-empty',
  BUBBLE_MESSAGE_OFF = 'bubble-message-off',
  BUBBLE_MESSAGE_QUESTION = 'bubble-message-question',
  BUBBLE_MESSAGE_TEXT = 'bubble-message-text',
  BUBBLE_MESSAGE = 'bubble-message',
  BURN = 'burn',
  CALCULATOR_ARROW_ROTATE_LEFT = 'calculator-arrow-rotate-left',
  CALCULATOR_FUNCTION = 'calculator-function',
  CALCULATOR = 'calculator',
  CALENDAR_BLANK = 'calendar-blank',
  CALENDAR_CHECK = 'calendar-check',
  CALENDAR_CLOCK = 'calendar-clock',
  CALENDAR_DELETED = 'calendar-deleted',
  CALENDAR_EVENT = 'calendar-event',
  CALENDAR_PLUS = 'calendar-plus',
  CALENDAR_QUESTION = 'calendar-question',
  CALENDAR_RANGE = 'calendar-range',
  CALENDAR_TODAY = 'calendar-today',
  CALENDAR = 'calendar',
  CALIBRATION_CHARACTERISTICS_ZQL = 'calibration-characteristics-zql',
  CAMERA_FRONT = 'camera-front',
  CAMERA_MOVIE_CROSS = 'camera-movie-cross',
  CAMERA_MOVIE = 'camera-movie',
  CAMERA = 'camera',
  CAR_JEEP_SIDE_VIEW = 'car-jeep-side-view',
  CAR_LIGHT_SIDE_VIEW = 'car-light-side-view',
  CARBON = 'carbon',
  CAROUSEL_FILL = 'carousel-fill',
  CART_FLATBED_CONTAINER = 'cart-flatbed-container',
  CART_PLUS = 'cart-plus',
  CART = 'cart',
  CELLPHONE_WIRELESS = 'cellphone-wireless',
  CELLPHONE = 'cellphone',
  CHARTS_IQC_ZQL = 'charts-iqc-zql',
  CHECK_DOUBLE = 'check-double',
  CHECK = 'check',
  CHECKERBOARD = 'checkerboard',
  CHEVRON_DOWN_TO_DOUBLE_LINE = 'chevron-down-to-double-line',
  CHEVRON_DOWN = 'chevron-down',
  CHEVRON_LEFT = 'chevron-left',
  CHEVRON_MINI_LEFT = 'chevron-mini-left',
  CHEVRON_MINI_RIGHT = 'chevron-mini-right',
  CHEVRON_RIGHT_ABOVE_DOUBLE_LINE = 'chevron-right-above-double-line',
  CHEVRON_RIGHT = 'chevron-right',
  CHEVRON_UP = 'chevron-up',
  CHEVRONS_DOUBLE_DOWN = 'chevrons-double-down',
  CHEVRONS_DOUBLE_LEFT = 'chevrons-double-left',
  CHEVRONS_DOUBLE_MINI_LEFT = 'chevrons-double-mini-left',
  CHEVRONS_DOUBLE_MINI_RIGHT = 'chevrons-double-mini-right',
  CHEVRONS_DOUBLE_RIGHT = 'chevrons-double-right',
  CHEVRONS_DOUBLE_UP = 'chevrons-double-up',
  CHEVRONS_INSIDE_HORIZONTALLY = 'chevrons-inside-horizontally',
  CHEVRONS_INSIDE_VERTICALLY = 'chevrons-inside-vertically',
  CHEVRONS_OUTSIDE_HORIZONTALLY = 'chevrons-outside-horizontally',
  CHEVRONS_OUTSIDE_VERTICALLY = 'chevrons-outside-vertically',
  CIRCLE_CHECK_EMPTY = 'circle-check-empty',
  CIRCLE_CHECK_FILL = 'circle-check-fill',
  CIRCLE_EXCLAMATION_EMPTY = 'circle-exclamation-empty',
  CIRCLE_EXCLAMATION_FILL = 'circle-exclamation-fill',
  CIRCLE_IN_HEXAGON = 'circle-in-hexagon',
  CIRCLE_INFO_EMPTY = 'circle-info-empty',
  CIRCLE_INFO_FILL = 'circle-info-fill',
  CIRCLE_MUSIC = 'circle-music',
  CIRCLE_PLUS_FILL = 'circle-plus-fill',
  CIRCLE_PLUS = 'circle-plus',
  CIRCLE_QUESTION_EMPTY = 'circle-question-empty',
  CIRCLE_QUESTION_FILL = 'circle-question-fill',
  CIRCLE_SQUARE_FILL = 'circle-square-fill',
  CIRCLE_SQUARE_IN_SQUARE = 'circle-square-in-square',
  CIRCLE_SQUARE = 'circle-square',
  CIRCLE_XMARK_FILL = 'circle-xmark-fill',
  CIRCLE_XMARK = 'circle-xmark',
  CIRCLES_LINE_LEFT_UP_CIRCLE_RIGHT_DOWN_FILL = 'circles-line-left-up-circle-right-down-fill',
  CIRCLES_LINE_LEFT_UP_CIRCLE_RIGHT_DOWN = 'circles-line-left-up-circle-right-down',
  CLAPPERBOARD_CROSS = 'clapperboard-cross',
  CLAPPERBOARD_OPEN = 'clapperboard-open',
  CLOCK_ADD = 'clock-add',
  CLOCK_ARROW_DOWN = 'clock-arrow-down',
  CLOCK_ARROW_RIGHT = 'clock-arrow-right',
  CLOCK_ARROW_UP = 'clock-arrow-up',
  CLOCK_DELETED = 'clock-deleted',
  CLOCK_EXCLAMATION = 'clock-exclamation',
  CLOCK_LINE_END = 'clock-line-end',
  CLOCK_ROTATE_LEFT = 'clock-rotate-left',
  CLOCK_ROTATE_RIGHT = 'clock-rotate-right',
  CLOCK = 'clock',
  CLOUD_ARROW_DOWN = 'cloud-arrow-down',
  CLOUD_ARROW_UP_INSIDE = 'cloud-arrow-up-inside',
  CLOUD_ARROW_UP = 'cloud-arrow-up',
  CLOUD_CHECK = 'cloud-check',
  CLOUD = 'cloud',
  CODE_DOWNLOAD = 'code-download',
  CODE_SIMPLE = 'code-simple',
  CODE = 'code',
  COLLAGE_FILL = 'collage-fill',
  COLLECTION = 'collection',
  COLLECTOR = 'collector',
  COLUMN_FILL = 'column-fill',
  COLUMN = 'column',
  COMPASS = 'compass',
  COMPONENT = 'component',
  CONCRETE_MIXER_SIDE_VIEW = 'concrete-mixer-side-view',
  CONTAINER_V_1 = 'container-v-1',
  CONTAINER_V_2 = 'container-v-2',
  CONTAINERS = 'containers',
  CONTROL_CHARTS_ZQL = 'control-charts-zql',
  COPY_COLUMN = 'copy-column',
  COPY_ROW = 'copy-row',
  COPY = 'copy',
  CRANE = 'crane',
  CREDIT_CARD_PLUS = 'credit-card-plus',
  CREDIT_CARD_SLASH = 'credit-card-slash',
  CREDIT_CARD = 'credit-card',
  CROP = 'crop',
  CROSSHAIRS_DOT = 'crosshairs-dot',
  CROSSHAIRS_SIMPLE = 'crosshairs-simple',
  CUBE_EMPTY = 'cube-empty',
  CUBE_FILL = 'cube-fill',
  CUBES = 'cubes',
  DASHBOARD_FILL = 'dashboard-fill',
  DASHBOARD_VARIANT_FILL = 'dashboard-variant-fill',
  DATABASE_CHECK = 'database-check',
  DATABASE_JSON = 'database-json',
  DATABASE_MAGNIFYING_GLASS = 'database-magnifying-glass',
  DATABASE_MINUS = 'database-minus',
  DATABASE_PLUS = 'database-plus',
  DATABASE_SQL = 'database-sql',
  DATABASE_XMARK = 'database-xmark',
  DATABASE = 'database',
  DAY_FILL = 'day-fill',
  DECAGRAM_CHECK_EMPTY = 'decagram-check-empty',
  DECAGRAM_CHECK_FILL = 'decagram-check-fill',
  DECAGRAM_EXCLAMATION_EMPTY = 'decagram-exclamation-empty',
  DECAGRAM_EXCLAMATION_FILL = 'decagram-exclamation-fill',
  DEFAULT_ICO = 'default-ico',
  DELETE_CONTENT = 'delete-content',
  DESKTOP_TOWER = 'desktop-tower',
  DISCRETE_SET = 'discrete-set',
  DOLLAR_SLASH = 'dollar-slash',
  DOLLAR = 'dollar',
  DOUGHNUT = 'doughnut',
  DROPLET_SLASH = 'droplet-slash',
  DROPLET = 'droplet',
  EARLY_VERSION = 'early-version',
  EARTH = 'earth',
  EDITOR_ALFAVIT = 'editor-alfavit',
  EDITOR_DOWN = 'editor-down',
  EDITOR_RIGHT = 'editor-right',
  ELECTRICS = 'electrics',
  ELLIPSIS_2 = 'ellipsis-2',
  ELLIPSIS_4 = 'ellipsis-4',
  ELLIPSIS_GRID = 'ellipsis-grid',
  ELLIPSIS_H = 'ellipsis-h',
  ELLIPSIS_V = 'ellipsis-v',
  ENERGY_CHECK = 'energy-check',
  ENERGY_SLASH = 'energy-slash',
  ENERGY = 'energy',
  EQUIPMENT_ZQL = 'equipment-zql',
  ERASER = 'eraser',
  ETHERNET = 'ethernet',
  EXCHANGER = 'exchanger',
  EXCLAMATION = 'exclamation',
  EYE_CLOSED = 'eye-closed',
  EYE_SLASH = 'eye-slash',
  EYE = 'eye',
  FACE_FROWN = 'face-frown',
  FACE_SMILE = 'face-smile',
  FACTORY_PIPE = 'factory-pipe',
  FACTORY_PIPES = 'factory-pipes',
  FACTORY = 'factory',
  FAN = 'fan',
  FILE_ARROW_DOWN_LEFT = 'file-arrow-down-left',
  FILE_ARROW_DOWN = 'file-arrow-down',
  FILE_ARROW_UP = 'file-arrow-up',
  FILE_ARROW = 'file-arrow',
  FILE_ARROWS_UP_RIGHT = 'file-arrows-up-right',
  FILE_CAMERA = 'file-camera',
  FILE_CROSS_OUT = 'file-cross-out',
  FILE_CROSS = 'file-cross',
  FILE_DOC = 'file-doc',
  FILE_DOCX = 'file-docx',
  FILE_DOT = 'file-dot',
  FILE_EMPTY = 'file-empty',
  FILE_EXCLAMATION = 'file-exclamation',
  FILE_EYE = 'file-eye',
  FILE_FILLED = 'file-filled',
  FILE_IMAGE = 'file-image',
  FILE_MAGNIFYING_GLASS = 'file-magnifying-glass',
  FILE_MINUS = 'file-minus',
  FILE_MUSIC = 'file-music',
  FILE_PDF = 'file-pdf',
  FILE_PEN = 'file-pen',
  FILE_PLAY = 'file-play',
  FILE_PLUS = 'file-plus',
  FILE_PPT = 'file-ppt',
  FILE_PPTX = 'file-pptx',
  FILE_TXT = 'file-txt',
  FILE_UP_RIGHT = 'file-up-right',
  FILE_XLS = 'file-xls',
  FILE_XLSX = 'file-xlsx',
  FILES_MODE = 'files-mode',
  FILL = 'fill',
  FILM_CROSS = 'film-cross',
  FILM = 'film',
  FILTER_GEAR = 'filter-gear',
  FILTER_ONTOLOGY = 'filter-ontology',
  FILTER = 'filter',
  FINANCE_SCANNER = 'finance-scanner',
  FIRE = 'fire',
  FLAG_FILL_RED = 'flag-fill-red',
  FLAG_FILL = 'flag-fill',
  FLAG = 'flag',
  FLASK_ROUND_POTION = 'flask-round-potion',
  FLASK = 'flask',
  FLOPPY_CROSS_OUT = 'floppy-cross-out',
  FLOPPY_CROSS = 'floppy-cross',
  FLOPPY_PEN = 'floppy-pen',
  FLOPPY_STAR = 'floppy-star',
  FLOPPY = 'floppy',
  FOLDER_ARROW_DOWN_LEFT = 'folder-arrow-down-left',
  FOLDER_ARROW_DOWN = 'folder-arrow-down',
  FOLDER_ARROW_UP_RIGHT = 'folder-arrow-up-right',
  FOLDER_CROSS = 'folder-cross',
  FOLDER_DOT = 'folder-dot',
  FOLDER_MINUS = 'folder-minus',
  FOLDER_OPEN = 'folder-open',
  FOLDER_PLUS = 'folder-plus',
  FOLDER_UNDERLINE_MINUS = 'folder-underline-minus',
  FOLDER_UNDERLINE_PLUS = 'folder-underline-plus',
  FOLDER_UNDERLINE = 'folder-underline',
  FOLDER = 'folder',
  FORVARD_STEP = 'forvard-step',
  FORWARD = 'forward',
  FUEL_OIL_TANK = 'fuel-oil-tank',
  FUNCTION = 'function',
  GANTT = 'gantt',
  GAS_MONITORING = 'gas-monitoring',
  GAS_PUMP = 'gas-pump',
  GAS = 'gas',
  GAUGE = 'gauge',
  GEAR_5_EDGE = 'gear-5-edge',
  GEAR_8_EDGE = 'gear-8-edge',
  GEAR_COOLING = 'gear-cooling',
  GENERATOR = 'generator',
  GIFT = 'gift',
  GIT_LAB = 'git-lab',
  GLOBE = 'globe',
  GRID_FILL = 'grid-fill',
  GRID = 'grid',
  GRIP_DOTS_VERTICAL = 'grip-dots-vertical',
  HARD_DRIVE_OUTLINE = 'hard-drive-outline',
  HASH_DASH = 'hash-dash',
  HASH = 'hash',
  HASHTAG = 'hashtag',
  HEADLINE_FILL = 'headline-fill',
  HEADPHONES = 'headphones',
  HEART_EMPTY = 'heart-empty',
  HEART_FILL = 'heart-fill',
  HELICOPTER_SIDE_VIEW = 'helicopter-side-view',
  HEXAGON_EXCLAMATION_EMPTY = 'hexagon-exclamation-empty',
  HEXAGON_EXCLAMATION_FILL = 'hexagon-exclamation-fill',
  HEXAGON_FILL = 'hexagon-fill',
  HEXAGON = 'hexagon',
  HEXAGONS_AXIS = 'hexagons-axis',
  HEXAGONS = 'hexagons',
  HISTOGRAM_AXIS = 'histogram-axis',
  HOUSE_MINI = 'house-mini',
  HOUSE = 'house',
  IMAGE = 'image',
  IMPORTERS_ZQL = 'importers-zql',
  INDENT_ARROW_LEFT = 'indent-arrow-left',
  INDENT_ARROW_RIGHT = 'indent-arrow-right',
  INFINITY = 'infinity',
  INFO = 'info',
  INPUT_DEVICE = 'input-device',
  INSTAGRAM = 'instagram',
  ITALIC = 'italic',
  KEY = 'key',
  KEYBOARD_CLOSE = 'keyboard-close',
  KEYBOARD = 'keyboard',
  LABEL_CROSS = 'label-cross',
  LABEL_EXCLAMATION_MARK = 'label-exclamation-mark',
  LABEL_FILL = 'label-fill',
  LABEL_MAGNIFYING_GLASS = 'label-magnifying-glass',
  LABEL_PLUS = 'label-plus',
  LABEL_QUESTION = 'label-question',
  LABEL_TEXT = 'label-text',
  LABEL = 'label',
  LAN_CONNECT = 'lan-connect',
  LAN_DISCONNECT = 'lan-disconnect',
  LAN_PENDING = 'lan-pending',
  LAPTOP_PHONE_SLASH = 'laptop-phone-slash',
  LAPTOP_PHONE = 'laptop-phone',
  LAPTOP_SLASH = 'laptop-slash',
  LAPTOP = 'laptop',
  LAYER_GROUP_SLASH = 'layer-group-slash',
  LAYER_GROUP = 'layer-group',
  LAYER_NETWORK = 'layer-network',
  LETTER_DAY = 'letter-day',
  LETTER_HOUR = 'letter-hour',
  LETTER_MINUTE = 'letter-minute',
  LETTER_MONTH = 'letter-month',
  LETTER_SECOND = 'letter-second',
  LETTER_TIME = 'letter-time',
  LETTER_YEAR = 'letter-year',
  LIBRARY_PLUS = 'library-plus',
  LIGHTBULB = 'lightbulb',
  LINE_AXIS_ARROW_DOWN = 'line-axis-arrow-down',
  LINE_AXIS_X = 'line-axis-x',
  LINE_AXIS = 'line-axis',
  LINE_DOWN = 'line-down',
  LINE_HEIGHT = 'line-height',
  LINE_MULTIPLE_Y_AXIS_X = 'line-multiple-y-axis-x',
  LINE_MULTIPLE_Y_AXIS = 'line-multiple-y-axis',
  LINE_UP = 'line-up',
  LINE = 'line',
  LINK_BREAK = 'link-break',
  LINK_MINUS = 'link-minus',
  LINK_PLUS = 'link-plus',
  LINK_SIMPLE_H = 'link-simple-h',
  LINK_SIMPLE = 'link-simple',
  LIST_FILL = 'list-fill',
  LIST_OL = 'list-ol',
  LIST_SQUARE = 'list-square',
  LIST_TREE_ARCHIVE = 'list-tree-archive',
  LIST_TREE = 'list-tree',
  LIST_UL = 'list-ul',
  LIST = 'list',
  LNG_STORAGE = 'lng-storage',
  LOADER = 'loader',
  LOCATION_ARROW = 'location-arrow',
  LOCATION_DIRECTLY = 'location-directly',
  LOCATION_DOT = 'location-dot',
  LOCATION_MINUS = 'location-minus',
  LOCATION_PLUS = 'location-plus',
  LOCATION_SLASH = 'location-slash',
  LOCATION_USER = 'location-user',
  LOCK = 'lock',
  LOGO_GREY_Z = 'logo-grey-z',
  MAGIC_WAND = 'magic-wand',
  MAGNIFYING_GLASS_EXCLAMATION = 'magnifying-glass-exclamation',
  MAGNIFYING_GLASS_MINUS = 'magnifying-glass-minus',
  MAGNIFYING_GLASS_ONTOLOGY = 'magnifying-glass-ontology',
  MAGNIFYING_GLASS_PLUS = 'magnifying-glass-plus',
  MAGNIFYING_GLASS_QR_CODE = 'magnifying-glass-qr-code',
  MAGNIFYING_GLASS_TAGS_WORDS = 'magnifying-glass-tags-words',
  MAGNIFYING_GLASS_TOP_FROM_BRACKET = 'magnifying-glass-top-from-bracket',
  MAGNIFYING_GLASS_TRIANGLE_DOWN = 'magnifying-glass-triangle-down',
  MAGNIFYING_GLASS_XMARK = 'magnifying-glass-xmark',
  MAGNIFYING_GLASS = 'magnifying-glass',
  MAIL_OPEN = 'mail-open',
  MAIL = 'mail',
  MAILBOX = 'mailbox',
  MAP_BASE = 'map-base',
  MAP_MARKER_DATE_V_1 = 'map-marker-date-v-1',
  MAP_MARKER_DATE_V_2 = 'map-marker-date-v-2',
  MAP_PIN = 'map-pin',
  MAP = 'map',
  MEDICAL = 'medical',
  MERGE = 'merge',
  MESSAGING_TELEGRAM_SEND = 'messaging-telegram-send',
  MESSAGING_TELEGRAM = 'messaging-telegram',
  MICROPHONE_SLASH = 'microphone-slash',
  MICROPHONE = 'microphone',
  MINIBUS_SIDE_VIEW = 'minibus-side-view',
  MINUS_CIRCLE_FILL = 'minus-circle-fill',
  MINUS_CIRCLE = 'minus-circle',
  MINUS = 'minus',
  MODULE_FILL = 'module-fill',
  MONITOR = 'monitor',
  MOON = 'moon',
  MUSIC_SLASH = 'music-slash',
  MUSIC = 'music',
  NETWORK_EXTERNAL_LINK = 'network-external-link',
  NETWORK_V_1 = 'network-v-1',
  NETWORK_V_2 = 'network-v-2',
  NETWORK_V_3 = 'network-v-3',
  NETWORK_WIRED = 'network-wired',
  NI_FI_LOGO = 'ni-fi-logo',
  NORMDOCS_ZQL = 'normdocs-zql',
  NPM = 'npm',
  OBJECT_DOWN_SUBTRACT = 'object-down-subtract',
  OBJECT_EXCLUDE = 'object-exclude',
  OBJECT_INTERSECT = 'object-intersect',
  OBJECT_SELECTION = 'object-selection',
  OBJECT_UNION = 'object-union',
  OBJECT_UP_SUBTRACT = 'object-up-subtract',
  OBJECT = 'object',
  OIL_BARREL = 'oil-barrel',
  OIL_CANISTER = 'oil-canister',
  OIL_DROPLET_ARROWS_ALL = 'oil-droplet-arrows-all',
  OIL_DROPLET_ARROWS_REPEAT_H = 'oil-droplet-arrows-repeat-h',
  OIL_DROPLET_CHECK = 'oil-droplet-check',
  OIL_DROPLET = 'oil-droplet',
  OIL_FIELD = 'oil-field',
  OIL_TANK = 'oil-tank',
  ONTOLOGY = 'ontology',
  OWL = 'owl',
  PALETTE = 'palette',
  PANEL_DOWN = 'panel-down',
  PANEL_LEFT = 'panel-left',
  PANEL_RIGHT = 'panel-right',
  PANEL_TOP = 'panel-top',
  PANTONE = 'pantone',
  PAPERCLIP_VERTICAL = 'paperclip-vertical',
  PAPERCLIP = 'paperclip',
  PAUSE = 'pause',
  PAVER_SIDE_VIEW = 'paver-side-view',
  PEN_HAMMER = 'pen-hammer',
  PENCIL_ARROW_RIGHT = 'pencil-arrow-right',
  PENCIL_LINE_BOTTOM = 'pencil-line-bottom',
  PENCIL_TRIANGLE_DOWN = 'pencil-triangle-down',
  PENCIL_TRIANGLE_LEFT = 'pencil-triangle-left',
  PENCIL = 'pencil',
  PENTAGON_FILL = 'pentagon-fill',
  PENTAGON = 'pentagon',
  PERCENT = 'percent',
  PHONE_ACTIVE_CALL = 'phone-active-call',
  PHONE_CANCELLED = 'phone-cancelled',
  PHONE_MINUS = 'phone-minus',
  PHONE_OFF = 'phone-off',
  PHONE_PAUSED = 'phone-paused',
  PHONE_PLUS = 'phone-plus',
  PHONE = 'phone',
  PI = 'pi',
  PIE_LINE_NESTED = 'pie-line-nested',
  PIE_LINE = 'pie-line',
  PIE_PENTAGON = 'pie-pentagon',
  PIE_SEVERAL_PARTS = 'pie-several-parts',
  PIE_SIMPLE = 'pie-simple',
  PIE = 'pie',
  PIPE_NODE_SIDE_VIEW = 'pipe-node-side-view',
  PIPE_SENSOR = 'pipe-sensor',
  PIPE_SLASH = 'pipe-slash',
  PIPE_VALVE = 'pipe-valve',
  PIPELINE_V_1 = 'pipeline-v-1',
  PIPELINE_V_2 = 'pipeline-v-2',
  PIPETTE = 'pipette',
  PLANE = 'plane',
  PLAY_BACK = 'play-back',
  PLAY = 'play',
  PLUS_COLUMN_RIGHT = 'plus-column-right',
  PLUS_COLUMN_TOP = 'plus-column-top',
  PLUS_TRIANGLE_DOWN = 'plus-triangle-down',
  PLUS = 'plus',
  POLYLINE = 'polyline',
  PORTFEL = 'portfel',
  POWER = 'power',
  PREMISES_ZQL = 'premises-zql',
  PRESS = 'press',
  PRINTER = 'printer',
  PROCESS = 'process',
  PULSE = 'pulse',
  PUMP_TRIANGLE_DOWN = 'pump-triangle-down',
  PUMP = 'pump',
  PUSH_BUTTON_CELLPHONE = 'push-button-cellphone',
  QR = 'qr',
  QUESTION = 'question',
  QUILT_FILL = 'quilt-fill',
  RADIATION = 'radiation',
  RAILCAR_HOPPER_SIDE_VIEW = 'railcar-hopper-side-view',
  RAILCAR_SIDE_VIEW = 'railcar-side-view',
  RANGE = 'range',
  REPLAY = 'replay',
  RESPIRATOR_V_1 = 'respirator-v-1',
  RESPIRATOR_V_2 = 'respirator-v-2',
  RESPIRATOR_V_3 = 'respirator-v-3',
  RESPIRATOR_V_4 = 'respirator-v-4',
  RESPIRATOR_V_5 = 'respirator-v-5',
  RESPIRATOR_V_6 = 'respirator-v-6',
  RETURN_STEP = 'return-step',
  RETURN = 'return',
  RHOMBUS_FILL = 'rhombus-fill',
  RHOMBUS = 'rhombus',
  RING_IN_SQUARE = 'ring-in-square',
  RULER_INDEX_ZQL = 'ruler-index-zql',
  RULER = 'ruler',
  SAMPLE_LOGS_ZQL = 'sample-logs-zql',
  SAMPLE_TEMPLATES_ZQL = 'sample-templates-zql',
  SANDGLASS = 'sandglass',
  SCATTER_AXIS = 'scatter-axis',
  SCATTER_BASIC = 'scatter-basic',
  SCATTER = 'scatter',
  SCISSORS = 'scissors',
  SEEDLING_CIRCLE = 'seedling-circle',
  SEEDLING_RECTANGLE = 'seedling-rectangle',
  SEEDLING = 'seedling',
  SELECTION_CHECKED_EMPTY = 'selection-checked-empty',
  SEND_BACKWARD = 'send-backward',
  SEND_FORWARD = 'send-forward',
  SEPARATOR = 'separator',
  SEQUENTIAL_FILL = 'sequential-fill',
  SERVER_NETWORK_CLOSE = 'server-network-close',
  SERVER_NETWORK_DOWNLOAD = 'server-network-download',
  SERVER_NETWORK_MINUS = 'server-network-minus',
  SERVER_NETWORK_PLUS = 'server-network-plus',
  SERVER_NETWORK_SECURITY = 'server-network-security',
  SERVER_NETWORK_UPLOAD = 'server-network-upload',
  SERVER_NETWORK = 'server-network',
  SERVER_SHIELD = 'server-shield',
  SERVER_SLASH_UP_LINE = 'server-slash-up-line',
  SERVER_SLASH = 'server-slash',
  SERVER_UP_LINE = 'server-up-line',
  SERVER = 'server',
  SHAPE_LINK_FILL = 'shape-link-fill',
  SHAPE_LINK = 'shape-link',
  SHAPE_PLUS = 'shape-plus',
  SHARE_LEFT = 'share-left',
  SHARE_RIGHT = 'share-right',
  SHAREING = 'shareing',
  SHIELD_EXCLAMATION = 'shield-exclamation',
  SHIELD_SLASH = 'shield-slash',
  SHIELD = 'shield',
  SHIP_FRONT_VIEW = 'ship-front-view',
  SHIP_SIDE_VIEW = 'ship-side-view',
  SHOPPING_BOX = 'shopping-box',
  SHOVEL = 'shovel',
  SIGMA = 'sigma',
  SIGNAL_ACCESS_POINT = 'signal-access-point',
  SIGNAL_OFF = 'signal-off',
  SIGNAL_RADIO_TOWER = 'signal-radio-tower',
  SIGNAL = 'signal',
  SLIDERS_H = 'sliders-h',
  SLIDERS_V = 'sliders-v',
  SNAKE_CUP = 'snake-cup',
  SNOWFLAKE_UP_TO_BRACKET = 'snowflake-up-to-bracket',
  SNOWFLAKE = 'snowflake',
  SORT_FILTER_REMOVE = 'sort-filter-remove',
  SPECIFICATION = 'specification',
  SPECS_ZQL = 'specs-zql',
  SPEED_REDUCTION_COOLING = 'speed-reduction-cooling',
  SQUARE_ARROWS_CURVE_RIGHT_DOWN = 'square-arrows-curve-right-down',
  SQUARE_ARROWS_CURVE_UP_RIGHT = 'square-arrows-curve-up-right',
  SQUARE_BRACKET_CURLY_LEFT = 'square-bracket-curly-left',
  SQUARE_BRACKET_CURLY_RIGHT = 'square-bracket-curly-right',
  SQUARE_BRACKET_LEFT = 'square-bracket-left',
  SQUARE_BRACKET_RIGHT = 'square-bracket-right',
  SQUARE_CIRCLE_PLUS_TRIANGLE_FILL = 'square-circle-plus-triangle-fill',
  SQUARE_CIRCLE_TRIANGLE_CROSS_FILL = 'square-circle-triangle-cross-fill',
  SQUARE_CIRCLE_TRIANGLE_CROSS = 'square-circle-triangle-cross',
  SQUARE_DIVIDE = 'square-divide',
  SQUARE_EXCEL = 'square-excel',
  SQUARE_EXCLAMATION_EMPTY = 'square-exclamation-empty',
  SQUARE_EXCLAMATION_FILL = 'square-exclamation-fill',
  SQUARE_FILL = 'square-fill',
  SQUARE_HEXAGON_CIRCLE_FILL = 'square-hexagon-circle-fill',
  SQUARE_HEXAGON_CIRCLE = 'square-hexagon-circle',
  SQUARE_IN_SQUARE = 'square-in-square',
  SQUARE_INFO_EMPTY = 'square-info-empty',
  SQUARE_INFO_FILL = 'square-info-fill',
  SQUARE_MINUS = 'square-minus',
  SQUARE_MULTIPLY = 'square-multiply',
  SQUARE_MUSIC_DOUBLE = 'square-music-double',
  SQUARE_MUSIC = 'square-music',
  SQUARE_PERCENT = 'square-percent',
  SQUARE_PLUS = 'square-plus',
  SQUARE_POWERPOINT = 'square-powerpoint',
  SQUARE_QUESTION_EMPTY = 'square-question-empty',
  SQUARE_QUESTION_FILL = 'square-question-fill',
  SQUARE_TRIANGLE_CIRCLE_TRIANGLE_FILL = 'square-triangle-circle-triangle-fill',
  SQUARE_TRIANGLE_CIRCLE_TRIANGLE = 'square-triangle-circle-triangle',
  SQUARE_WORD = 'square-word',
  STAR_EMPTY = 'star-empty',
  STAR_FILL = 'star-fill',
  STAR_HALF_FILL = 'star-half-fill',
  STAR_LINE = 'star-line',
  STEAM_BOILER = 'steam-boiler',
  STEAM_TURBINE = 'steam-turbine',
  STEAM = 'steam',
  STOP = 'stop',
  STOPWATCH = 'stopwatch',
  STORAGE = 'storage',
  STRIKETHROUGH = 'strikethrough',
  SUBSKRIT_DOWN = 'subskrit-down',
  SUBSKRIT_UP = 'subskrit-up',
  SUN = 'sun',
  SUPPORT_MAN = 'support-man',
  SYMBOL_ASTERISK = 'symbol-asterisk',
  TABLE_DOWN = 'table-down',
  TABLE_GEAR = 'table-gear',
  TABLE_MAGNIFYING_GLASS = 'table-magnifying-glass',
  TABLE_RIGHT = 'table-right',
  TABLE_TRANSPOSE_RIGHT = 'table-transpose-right',
  TABLE_TRANSPOSE_TOP = 'table-transpose-top',
  TABLE_TRANSPOSE = 'table-transpose',
  TABLE = 'table',
  TABLET_IQC_ZQL = 'tablet-iqc-zql',
  TABLET = 'tablet',
  TANK = 'tank',
  TANKCAR_OIL_SIDE_VIEW = 'tankcar-oil-side-view',
  TANKCAR_SIDE_VIEW = 'tankcar-side-view',
  TECHS_ZQL = 'techs-zql',
  TEMP_ADD_SHAPE_SQUARE_PLUS = 'temp-add-shape-square-plus',
  TEMP_ALERTS_ALARM_LIGHT = 'temp-alerts-alarm-light',
  TEMP_ALERTS_CIRCLE_MINI = 'temp-alerts-circle-mini',
  TEMP_ARROWS_CORNER_CHEK_IN = 'temp-arrows-corner-chek-in',
  TEMP_ARROWS_DOWN_DOUBLE_TOP_2 = 'temp-arrows-down-double-top-2',
  TEMP_ARROWS_UP_DOUBLE_TOP_2 = 'temp-arrows-up-double-top-2',
  TEMP_AUDIO_ARTIST = 'temp-audio-artist',
  TEMP_BADGES_TAG_MULTIPLE = 'temp-badges-tag-multiple',
  TEMP_CHEVRONS_DROPDOWN_SMALL = 'temp-chevrons-dropdown-small',
  TEMP_CHEVRONS_MENU_LEFT_SMALL = 'temp-chevrons-menu-left-small',
  TEMP_DATE_INFORM_DELETE = 'temp-date-inform-delete',
  TEMP_EDITOR_DISCRETE_SET = 'temp-editor-discrete-set',
  TEMP_EDITOR_WRAP_TEXT = 'temp-editor-wrap-text',
  TEMP_FILES_IMPORT_1_C = 'temp-files-import-1-c',
  TEMP_LOCATION_LOCALIZATION_DOWN_LIST = 'temp-location-localization-down-list',
  TEMP_NETWORK_LIBRARY = 'temp-network-library',
  TEMP_POI_DIFFERENCE = 'temp-poi-difference',
  TEMP_PRODUCT_GRADE_ACCORDING_TO_SPECIFICATION = 'temp-product-grade-according-to-specification',
  TEMP_SELECTION_CHECKBOX_BLANK_CIRCLE = 'temp-selection-checkbox-blank-circle',
  TEMP_SELECTION_CHECKBOX_BLANK = 'temp-selection-checkbox-blank',
  TEMP_SELECTION_CHECKBOX_MARKED_CIRCLE_CHANEL = 'temp-selection-checkbox-marked-circle-chanel',
  TEMP_SELECTION_CHECKBOX_MARKED_CIRCLE = 'temp-selection-checkbox-marked-circle',
  TEMP_SELECTION_CHECKED_1 = 'temp-selection-checked-1',
  TEMP_SELECTION_CHECKED_2 = 'temp-selection-checked-2',
  TEMP_SELECTION_CHECKED = 'temp-selection-checked',
  TEMP_SELECTION_RADIO_OFF = 'temp-selection-radio-off',
  TEMP_SELECTION_RADIO_ON = 'temp-selection-radio-on',
  TEMP_SELECTION_TOGGLE_LEFT = 'temp-selection-toggle-left',
  TEMP_SELECTION_TOGGLE_RIGHT = 'temp-selection-toggle-right',
  TEMP_SELECTION_UNCHOICE = 'temp-selection-unchoice',
  TEMP_SHAPES_CHECKBOX_INTERMEDIATE = 'temp-shapes-checkbox-intermediate',
  TEMP_SIGNAL_CAST = 'temp-signal-cast',
  TEMP_SIGNAL_RSS_BOX = 'temp-signal-rss-box',
  TEMP_SOCIAL_TAB = 'temp-social-tab',
  TEMP_SORT_VERSION = 'temp-sort-version',
  TEMP_TOOLS_SOLVER = 'temp-tools-solver',
  TEMPERATURE_MINUS = 'temperature-minus',
  TEMPERATURE_PLUS = 'temperature-plus',
  TEMPERATURE = 'temperature',
  TEMPLATE = 'template',
  TEXT_COLOR = 'text-color',
  TEXT = 'text',
  THUMB_DOWN = 'thumb-down',
  THUMB_UP = 'thumb-up',
  THUMBTACK_DIRECTLY = 'thumbtack-directly',
  THUMBTACK = 'thumbtack',
  TOUCHPAD = 'touchpad',
  TRAIN_CARGO = 'train-cargo',
  TRAIN_PASSENGER = 'train-passenger',
  TRANSMITTER = 'transmitter',
  TRASH_DROP = 'trash-drop',
  TRASH_EMPTY = 'trash-empty',
  TRASH = 'trash',
  TRIANGLE_CIRCLE_SQUARE_FILL = 'triangle-circle-square-fill',
  TRIANGLE_CIRCLE_SQUARE = 'triangle-circle-square',
  TRIANGLE_CROSS_EMPTY = 'triangle-cross-empty',
  TRIANGLE_DOWN = 'triangle-down',
  TRIANGLE_EXCLAMATION_EMPTY = 'triangle-exclamation-empty',
  TRIANGLE_EXCLAMATION_FILL = 'triangle-exclamation-fill',
  TRIANGLE_FILL = 'triangle-fill',
  TRIANGLE_INFO_EMPTY = 'triangle-info-empty',
  TRIANGLE_INFO_FILL = 'triangle-info-fill',
  TRIANGLE_LEFT = 'triangle-left',
  TRIANGLE_PLUS_EMPTY = 'triangle-plus-empty',
  TRIANGLE_RIGHT = 'triangle-right',
  TRIANGLE_UP_DOWN = 'triangle-up-down',
  TRIANGLE_UP = 'triangle-up',
  TRIANGLE = 'triangle',
  TRUCK_DUMP_SIDE_VIEW = 'truck-dump-side-view',
  TRUCK_EMPTY_SIDE_VIEW = 'truck-empty-side-view',
  TRUCK_GARBAGE_SIDE_VIEW = 'truck-garbage-side-view',
  TRUCK_GASOLINE_SIDE_VIEW = 'truck-gasoline-side-view',
  TRUCK_SIDE_VIEW = 'truck-side-view',
  TV = 'tv',
  UMBRELLA = 'umbrella',
  UNDERLINED = 'underlined',
  UNLOCK = 'unlock',
  USER_ARROWS_SWAP = 'user-arrows-swap',
  USER_CARD = 'user-card',
  USER_CHECK = 'user-check',
  USER_CHEVRON = 'user-chevron',
  USER_CIRCLE = 'user-circle',
  USER_CROSS = 'user-cross',
  USER_GEAR = 'user-gear',
  USER_GROUP = 'user-group',
  USER_LOCATION = 'user-location',
  USER_MINUS = 'user-minus',
  USER_PEN = 'user-pen',
  USER_PLUS = 'user-plus',
  USER_SHIELD = 'user-shield',
  USER_WORKER = 'user-worker',
  USER = 'user',
  USERS_ARROW_RIGHT = 'users-arrow-right',
  USERS_ARROW_UP_ZQL = 'users-arrow-up-zql',
  USERS_GEAR = 'users-gear',
  USERS_KEY = 'users-key',
  USERS_LOCATION = 'users-location',
  USERS_MINUS = 'users-minus',
  USERS_PLUS = 'users-plus',
  USERS = 'users',
  VECTOR_CIRCLE_ONE_POINT = 'vector-circle-one-point',
  VECTOR_CIRCLE_TWO_POINT = 'vector-circle-two-point',
  VECTOR_CIRCLE = 'vector-circle',
  VECTOR_LINE = 'vector-line',
  VECTOR_POINT = 'vector-point',
  VECTOR_POLYGON = 'vector-polygon',
  VECTOR_RECTANGLE = 'vector-rectangle',
  VECTOR_SQUARE = 'vector-square',
  VECTOR_TRIANGLE = 'vector-triangle',
  VECTORS_RADIUS = 'vectors-radius',
  VIAL_FLASK = 'vial-flask',
  VIAL_PLUS = 'vial-plus',
  VIAL_ROTATE_LEFT = 'vial-rotate-left',
  VIAL_XMARK = 'vial-xmark',
  VIAL = 'vial',
  VIDEO_CROSS = 'video-cross',
  VIDEO_SLASH = 'video-slash',
  VIDEO = 'video',
  VOICEMAIL = 'voicemail',
  VOLUME_CROSS = 'volume-cross',
  VOLUME_LOW = 'volume-low',
  VOLUME_MINUS = 'volume-minus',
  VOLUME_OFF = 'volume-off',
  VOLUME_PLUS = 'volume-plus',
  VOLUME_SLASH = 'volume-slash',
  VOLUME = 'volume',
  WALLET = 'wallet',
  WATCH = 'watch',
  WATER_HEATER = 'water-heater',
  WATER_PUMP = 'water-pump',
  WEBCAM = 'webcam',
  WIFI_OFF = 'wifi-off',
  WIFI = 'wifi',
  WINDOW = 'window',
  WRENCH = 'wrench',
  XMARK_COLUMN_RIGHT = 'xmark-column-right',
  XMARK_COLUMN_TOP = 'xmark-column-top',
  XMARK_MINI = 'xmark-mini',
  XMARK = 'xmark',
}
