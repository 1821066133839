import { NgModule } from '@angular/core';
import { PrizmTreeButtonComponent } from './tree-button.component';

/**
 * @deprecated
 * use standalone
 * */
@NgModule({
  imports: [PrizmTreeButtonComponent],
  exports: [PrizmTreeButtonComponent],
})
export class PrizmTreeButtonModule {}
