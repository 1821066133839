<a
  *polymorpheusOutlet="sourceCode as link; context: pathOptions"
  tuiIconButton
  type="button"
  icon="tuiIconCodeLarge"
  appearance="icon"
  target="_blank"
  size="s"
  [title]="text"
  [href]="link"
></a>
