<prizm-data-list
  class="block"
  [minHeight]="minHeight"
  [maxHeight]="maxHeight"
  [defaultStyle]="defaultStyle"
  [iconOff]="iconOff"
  [scroll]="scroll"
  [style.--prizm-data-list-border]="0"
>
  <ng-container #viewContainerRef></ng-container>
  <ng-content></ng-content>
</prizm-data-list>
