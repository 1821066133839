<div class="prizm-navigation-menu-group" [class.prizm-navigation-menu-group_expanded]="groupIsExpanded">
  <prizm-accordion *ngIf="!hideGroupAppearance; else noGroupAppearanceTemplate">
    <prizm-accordion-item
      [icon]="$any(icon)"
      [title]="$any(title)"
      [isExpanded]="groupIsExpanded"
      (isExpandedChange)="handleGroupExpandedChanged($event)"
    >
      <ng-template prizmAccordionContent>
        <ng-container [ngTemplateOutlet]="itemsTemplate"></ng-container>
      </ng-template>
    </prizm-accordion-item>
  </prizm-accordion>

  <ng-template #noGroupAppearanceTemplate>
    <ng-container [ngTemplateOutlet]="itemsTemplate"></ng-container>
  </ng-template>
</div>

<ng-template #itemsTemplate>
  <prizm-navigation-menu-toolbar
    [searchConfig]="$any(searchConfig$ | async)"
    [toolbarConfig]="$any(toolbarConfig$ | async)"
    [toolbarExtraTemplate]="toolbarExtraTemplate"
    [hideGroupAppearance]="$any(hideGroupAppearance)"
  ></prizm-navigation-menu-toolbar>
  <ng-container *ngIf="groupItems$ | async as groupItems">
    <prizm-navigation-menu-items
      [items]="groupItems"
      [itemExtraTemplate]="$any(itemExtraTemplate)"
      [childrenHandler]="childrenHandler"
      [expandedItemsMap]="$any(expandedItemsMap$ | async)"
      [activeItem]="$any(activeItem$ | async)"
      [mode]="$any(mode$ | async)"
      (activeItemChanged)="handleActiveItemChange($event)"
      (itemExpandedChanged)="handleItemExpandedChanged($event)"
      (goToParentItem)="handleGoToParentItem($event)"
      (goToRootItem)="handleGoToRootItem($event)"
    ></prizm-navigation-menu-items>

    <ng-container *ngIf="!groupItems.length">
      <div
        class="prizm-navigation-menu-group__no-items-content"
        *ngIf="emptyMessageConfig$ | async as emptyMessageConfig"
      >
        <div class="empty-message">
          <span class="empty-message__main">{{ emptyMessageConfig.title }}</span>
          <span class="empty-message__sub">{{ emptyMessageConfig.subtitle }}</span>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
