export const unsupportedKeyCharacters = [
  'Shift',
  'Escape',
  'Backspace',
  'Delete',
  'ArrowUp',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'Enter',
  'CapsLock',
  'Alt',
  'Control',
  'Meta',
];
