import { NgModule } from '@angular/core';
import { PrizmOverlayComponent } from './overlay.component';

// TODO move to seperated lib
/**
 * @deprecated
 * use standalone component instead
 * */
@NgModule({
  imports: [PrizmOverlayComponent],
  declarations: [],
})
export class PrizmOverlayModule {}
