<div
  class="checkbox checkbox--size-{{ size }}"
  [ngClass]="{ 'checkbox--checked': checked, 'checkbox--indeterminate': indeterminate }"
>
  <div
    class="checkbox__icon prizm-icon checkbox__icon--size-{{ size }}"
    [ngClass]="{
      'checkbox__icon--no-icon': indeterminate === false && checked === false,
      'checkbox__icon--checked': indeterminate === false && checked === true,
      'checkbox__icon--indeterminate': indeterminate,
    }"
  >
    <ng-container *ngIf="indeterminate === true; else showMarkTemplate">
      <prizm-icons [size]="16" name="minus"></prizm-icons>
    </ng-container>

    <ng-template #showMarkTemplate>
      <prizm-icons
        [class.hidden]="!(indeterminate === false && checked === true)"
        [size]="16"
        name="check"
      ></prizm-icons>
    </ng-template>
  </div>
</div>

<div class="label label--size-{{ size }}"><ng-content></ng-content></div>
