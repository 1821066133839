<div
  class="nav"
  #container
  [class.nav_active]="isActive$ | async"
  [prizmHint]="menuItem?.title"
  [prizmHintCanShow]="prizmIsTextOverflow(title)"
  (click)="navClick()"
>
  <div class="nav__icon" [style.marginLeft.px]="deep | prizmCalculateNavMargin">
    <prizm-icons-full
      *ngIf="menuItem?.icon"
      [name]="menuItem!.icon | prizmToType : 'string'"
    ></prizm-icons-full>
  </div>
  <span class="nav__title" #title>
    {{ menuItem?.title }}
  </span>
  <div class="nav__status">
    <div
      class="indicator indicator_{{ menuItem?.indicatorStatus }}"
      *ngIf="menuItem?.indicatorValue && menuItem?.indicatorStatus"
      [prizmHint]="$any(menuItem?.indicatorValue)"
    >
      {{ menuItem?.indicatorValue }}
    </div>
  </div>
</div>
