<prizm-dropdown-host
  class="z-hosted"
  [canOpen]="interactive"
  [content]="dropdown"
  [closeByEsc]="true"
  [isOpen]="open && interactive"
  [prizmDropdownHost]="layoutComponent?.el?.nativeElement"
  (isOpenChange)="onOpenChange($event)"
  prizmDropdownHostWidth="auto"
>
  <input
    class="input-search"
    #focusableElementRef
    [readOnly]="true"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [ngModelOptions]="{ standalone: true }"
    [ngModel]="
      value
        ? computeValue(
            value.from | prizmMapper : formatter | async,
            value.to | prizmMapper : formatter | async
          )
        : ''
    "
    (click)="open = !open"
    (ngModelChange)="onValueChange($event || '')"
    prizmInput
  />

  <ng-template #dropdown>
    <prizm-calendar-month
      [disabledItemHandler]="disabledItemHandler"
      [min]="min"
      [max]="max"
      [value]="value"
      (prizmAfterViewInit)="markAsTouched()"
      (monthClick)="onMonthClick($event)"
      prizmPreventDefault="mousedown"
    ></prizm-calendar-month>
  </ng-template>
</prizm-dropdown-host>

<ng-container *prizmInputLayoutRight>
  <button
    [interactive]="true"
    [disabled]="disabled"
    (click)="onOpenChange(!open)"
    prizmInputIconButton="calendar-range"
  ></button>
</ng-container>
