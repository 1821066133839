<div
  class="z-wrapper"
  *ngIf="spinTexts$ | async as texts"
  #wrapper
  prizmPreventDefault="mousedown"
  [prizmFocusable]="computedFocusable"
  (prizmFocusedChange)="onFocused($event)"
  (prizmFocusVisibleChange)="onFocusVisible($event)"
  (keydown.arrowLeft)="onLeftClick()"
  (keydown.arrowRight)="onRightClick()"
>
  <button
    class="z-arrow"
    prizmIconButton
    type="button"
    size="l"
    [appearanceType]="mode"
    automation-id="prizm-primitive-spin-button__left"
    icon="angle-left"
    appearance="secondary"
    [class.z-arrow_hidden]="leftComputedDisabled"
    [prizmHint]="texts[0]"
    [prizmHintDirection]="'b'"
    [focusable]="false"
    (click)="onLeftClick()"
  ></button>
  <span class="z-content">
    <ng-content></ng-content>
  </span>
  <button
    class="z-arrow"
    prizmIconButton
    type="button"
    size="l"
    appearance="secondary"
    automation-id="prizm-primitive-spin-button__right"
    icon="angle-right"
    [appearanceType]="mode"
    [class.z-arrow_hidden]="rightComputedDisabled"
    [prizmHint]="texts[1]"
    [prizmHintDirection]="'b'"
    [focusable]="false"
    (click)="onRightClick()"
  ></button>
  <!--  [appearanceType]='mode'-->
</div>
