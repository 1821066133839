<prizm-dropdown-host
  #dropdownHostRef
  [content]="dropdown"
  [isOpen]="$any(opened$ | async)"
  [prizmDropdownHost]="layoutComponent?.el?.nativeElement"
  [prizmDropdownHostWidth]="'100%'"
  [prizmDropdownMinHeight]="20"
  [autoReposition]="false"
  (isOpenChange)="opened$$.next($event)"
>
  <input
    class="input-search"
    #focusableElementRef
    [placeholder]="placeholder"
    [disabled]="disabled"
    [tabIndex]="!disabled ? 0 : -1"
    [readonly]="true"
    [ngModelOptions]="{ standalone: true }"
    [ngModel]="treeSelectStringifyDirective.stringifiedSelected$ | async"
    (click)="opened$$.next(true)"
    style="cursor: inherit"
    prizmInput
  />
</prizm-dropdown-host>

<ng-container *prizmInputLayoutRight>
  <ng-container
    *polymorphOutlet="
      icon || defaultIcon as iconName;
      context: $any({ opened: opened$ | async, disabled: disabled })
    "
  >
    <prizm-icons-full
      [class.opened]="opened$ | async"
      [class.active]="opened$ | async"
      [class.icon-dropdown]="iconName === defaultIcon"
      [name]="$any(iconName)"
      [class.disabled]="disabled"
      [prizmFocusable]="true"
      (click)="!disabled && opened$$.next(!opened$$.value)"
    >
    </prizm-icons-full>
  </ng-container>
</ng-container>

<ng-template #dropdown>
  <prizm-input-tree-select-data-list-wrapper
    [projectedDataListDirective]="dataListDirective"
    [parentDropdownHost]="dropdownHostRef"
    (prizmOnDestroy)="onTouch()"
  >
  </prizm-input-tree-select-data-list-wrapper>
</ng-template>
