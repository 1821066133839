<prizm-tree
  #treeCtrl="prizmTreeController"
  *ngFor="let item of items"
  [prizmTreeController]="false"
  [map]="expandedItemsMap"
  [content]="content"
  [value]="item"
  [childrenHandler]="menuItemsChildrenHandler"
  (expandedChanged)="handleExpandedChanged($event)"
>
  <ng-template #content let-item>
    <prizm-navigation-menu-item
      [item]="item"
      [mode]="mode"
      [itemExtraTemplate]="itemExtraTemplate"
      [isActiveNode]="getItemIsActiveNode(item)"
      [isActive]="getItemIsActive(item)"
      [isExpandable]="menuItemsChildrenHandler(item).length > 0"
      [isExpanded]="getItemIsExpanded(item)"
      (toggleExpanded)="treeCtrl.toggleByItemValue($event)"
      (interaction)="handleInteraction($event, treeCtrl)"
      (goToParentItem)="goToParentItem.emit($event)"
      (goToRootItem)="goToRootItem.emit($event)"
    ></prizm-navigation-menu-item>
  </ng-template>
</prizm-tree>
