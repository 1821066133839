import { NgModule } from '@angular/core';
import { PrizmRadioButtonComponent } from './prizm-radio-button.component';

/**
 * @deprecated
 * use standalone
 * */
@NgModule({
  imports: [PrizmRadioButtonComponent],
  exports: [PrizmRadioButtonComponent],
})
export class PrizmRadioButtonModule {}
