<div
  class="prizm-toast-info-container {{ toastRef.appearance }}"
  [class.is-platform]="toastRef.isPlatform"
  prizmTheme
>
  <div class="icon-block">
    <prizm-indicator [status]="$any(toastRef.appearance)" type="icon"></prizm-indicator>
  </div>
  <div class="content-box">
    <span class="title prizm-font-dynamic-title-h4" *ngIf="toastRef.title">
      <ng-container *polymorphOutlet="toastRef.title as content; context: context">{{
        content
      }}</ng-container>
    </span>
    <div class="content prizm-font-main-body-14">
      <ng-container *polymorphOutlet="temp as content; context: context">{{ content }}</ng-container>
    </div>
  </div>

  <div class="control">
    <button
      class="close-button"
      [size]="16"
      [interactive]="true"
      (click)="toastRef.close()"
      prizmInputIconButton="xmark"
    ></button>
  </div>
</div>
