<ng-content></ng-content>
<tr *ngIf="heading">
  <th class="heading" [colSpan]="columnsCount">
    <div class="heading__container">
      <prizm-icons
        class="heading__icon"
        [class.heading__icon_expanded]="open"
        [size]="16"
        (click)="onClick()"
        name="angle-right"
      ></prizm-icons>
      <span class="heading__content">
        <ng-container *polymorphOutlet="heading as text">{{ text }}</ng-container>
      </span>
    </div>
  </th>
</tr>

<ng-container *ngIf="open && row">
  <ng-container *ngIf="renderData$ | async as data; else loadingWrapperTemplate">
    <ng-container *ngIf="!!data?.length; else emptyWrapperTemplate">
      <ng-template
        *ngFor="
          let item of data;
          index as index;
          odd as odd;
          even as even;
          first as first;
          last as last;
          count as count;
          trackBy: row.prizmRowTrackBy
        "
        [template]="templateRowElement"
        [context]="
          $any({
            item: item,
            index: index,
            rowIdx: index,
            odd: odd,
            even: even,
            getRowId: row.prizmRowGetRowId,
            first: first,
            last: last,
            count: count,
            deepLevel: 0
          })
        "
        prizmTableRowInit
      ></ng-template>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loadingWrapperTemplate>
  <ng-container *ngTemplateOutlet="loadingTemplate?.template || loadingWrapperTemplate"></ng-container>

  <ng-template #loadingWrapperTemplate>
    <tr>
      <td [colSpan]="columnsCount">Loading...</td>
    </tr>
  </ng-template>
</ng-template>

<ng-template #emptyWrapperTemplate>
  <ng-container *ngTemplateOutlet="emptyTemplate?.template || emptyInnerTemplate"></ng-container>

  <ng-template #emptyInnerTemplate>
    <tr class="empty-row">
      <td [colSpan]="columnsCount">—</td>
    </tr>
  </ng-template>
</ng-template>

<ng-template
  #templateRowElement
  let-item="item"
  let-index="index"
  let-odd="odd"
  let-even="even"
  let-first="first"
  let-last="last"
  let-count="count"
  let-deepLevel="deepLevel"
  let-parentItem="parentItem"
  let-parentIdx="parentIdx"
  let-rowIdx="rowIdx"
>
  <ng-container
    [ngTemplateOutlet]="$any(row?.template)"
    [ngTemplateOutletContext]="{
      $implicit: item,
      rowIdx: rowIdx,
      parentIdx: parentIdx,
      parentItem: parentItem,
      index: index,
      odd: odd,
      item: item,
      even: even,
      deepLevel: deepLevel,
      first: first,
      last: last,
      count: count
    }"
  ></ng-container>

  <ng-container *ngIf="tableTreeService.canShowChild(index) | async">
    <ng-template #treeLoadingTemplateRef>
      <ng-container
        [ngTemplateOutlet]="treeLoadingTemplate?.template || defaultTreeLoading"
        [ngTemplateOutletContext]="{
          item: item,
          parentIdx: index,
          parentItem: item,
          deepLevel: deepLevel,
          first: first,
          last: last,
          count: count
        }"
      >
      </ng-container>
      <ng-template #defaultTreeLoading>
        <tr>
          <td>Loading...</td>
        </tr>
      </ng-template>
    </ng-template>

    <ng-container
      *ngIf="
        row?.prizmRowGetChildren && item
          | prizmCallFunc : $any(row?.prizmRowGetChildren)
          | prizmCallFunc : sortChildren
          | async as children;
        else treeLoadingTemplateRef
      "
    >
      <ng-template
        *ngFor="
          let childItem of $any(children);
          index as childIndex;
          odd as childOdd;
          even as childEven;
          first as childFirst;
          last as childLast;
          count as childCount;
          trackBy: $any(row?.prizmRowTrackBy)
        "
        [template]="templateRowElement"
        [context]="
          $any({
            item: childItem,
            rowIdx: childIndex,
            parentIdx: index,
            getRowId: $any(row?.prizmRowGetRowId),
            parentItem: item,
            deepLevel: (deepLevel ?? 0) + 1,
            first: childFirst,
            last: childLast,
            count: childCount + count
          })
        "
        prizmTableRowInit
      >
      </ng-template>
    </ng-container>
  </ng-container>
</ng-template>

<ng-content select="[footer]"></ng-content>
