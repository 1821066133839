<button
  class="t-menu"
  tuiIconButton
  icon="tuiIconMenuLarge"
  appearance="icon"
  [title]="menu"
  (click)="onClick()"
  (tuiActiveZoneChange)="onActiveZone($event)"
>
  <prizm-doc-navigation class="t-navigation" *tuiSidebar="!!(open$ | async)"></prizm-doc-navigation>
</button>
<div class="t-logo">
  <img class="t-img" *polymorpheusOutlet="logo as src" alt="Logo" [src]="src" />
</div>
<ng-content></ng-content>
