export const PRIZM_LIGHT_THEME_SPECIFIC_CSS_VARS = {
  '--prizm-text-icon-primary': 'var(--prizm-palette-1200)',
  '--prizm-text-icon-secondary': 'var(--prizm-palette-gray-800)',
  '--prizm-text-icon-tertiary': 'var(--prizm-palette-gray-600)',
  '--prizm-text-icon-disable': 'var(--prizm-palette-gray-400)',
  '--prizm-text-icon-link': 'var(--prizm-palette-blue-600)',
  '--prizm-text-icon-link-hover': 'var(--prizm-palette-blue-h650)',
  '--prizm-text-icon-link-visited': 'var(--prizm-palette-blue-400)',
  '--prizm-text-icon-reverse': 'var(--prizm-palette-0)',
  '--prizm-text-icon-exception': 'var(--prizm-palette-0)',
  '--prizm-background-fill-primary': 'var(--prizm-palette-0)',
  '--prizm-background-fill-secondary': 'var(--prizm-palette-gray-100)',
  '--prizm-background-fill-panel': 'var(--prizm-palette-0)',
  '--prizm-background-fill-overlay': 'var(--prizm-palette-0)',
  '--prizm-background-fill-blanket': '#0f11157f',
  '--prizm-background-fill-reverse': 'var(--prizm-palette-gray-1100)',
  '--prizm-background-stroke': 'var(--prizm-palette-gray-200)',
  '--prizm-background-stroke-focus': 'var(--prizm-palette-blue-400)',
  '--prizm-table-fill-header-default': 'var(--prizm-palette-gray-100)',
  '--prizm-table-fill-header-hover': 'var(--prizm-palette-gray-h150)',
  '--prizm-table-fill-row-zebra_default': 'var(--prizm-palette-gray-100)',
  '--prizm-table-fill-row-hover': 'var(--prizm-palette-gray-h150)',
  '--prizm-table-fill-row-active': 'var(--prizm-palette-blue-200)',
  '--prizm-table-fill-cell-disable': 'var(--prizm-palette-gray-200)',
  '--prizm-table-stroke-cell-default': 'var(--prizm-palette-gray-200)',
  '--prizm-table-stroke-cell-hover': 'var(--prizm-palette-gray-h450)',
  '--prizm-table-stroke-cell-active': 'var(--prizm-palette-blue-600)',
  '--prizm-status-info-primary-default': 'var(--prizm-palette-blue-600)',
  '--prizm-status-info-primary-hover': 'var(--prizm-palette-blue-h650)',
  '--prizm-status-info-secondary-default': 'var(--prizm-palette-blue-200)',
  '--prizm-status-info-secondary-hover': 'var(--prizm-palette-blue-h250)',
  '--prizm-status-none-primary-default': 'var(--prizm-palette-gray-600)',
  '--prizm-status-none-primary-hover': 'var(--prizm-palette-gray-h650)',
  '--prizm-status-none-secondary-default': 'var(--prizm-palette-gray-200)',
  '--prizm-status-none-secondary-hover': 'var(--prizm-palette-gray-h250)',
  '--prizm-status-success-primary-default': 'var(--prizm-palette-green-600)',
  '--prizm-status-success-primary-hover': 'var(--prizm-palette-green-h650)',
  '--prizm-status-success-secondary-default': 'var(--prizm-palette-green-200)',
  '--prizm-status-success-secondary-hover': 'var(--prizm-palette-green-h250)',
  '--prizm-status-attention-primary-default': 'var(--prizm-palette-yellow-300)',
  '--prizm-status-attention-primary-hover': 'var(--prizm-palette-yellow-h350)',
  '--prizm-status-attention-secondary-default': 'var(--prizm-palette-yellow-100)',
  '--prizm-status-attention-secondary-hover': 'var(--prizm-palette-yellow-h150)',
  '--prizm-status-warning-primary-default': 'var(--prizm-palette-orange-400)',
  '--prizm-status-warning-primary-hover': 'var(--prizm-palette-orange-h450)',
  '--prizm-status-warning-secondary-default': 'var(--prizm-palette-orange-100)',
  '--prizm-status-warning-secondary-hover': 'var(--prizm-palette-orange-h150)',
  '--prizm-status-alarm-primary-default': 'var(--prizm-palette-red-600)',
  '--prizm-status-alarm-primary-hover': 'var(--prizm-palette-red-h650)',
  '--prizm-status-alarm-secondary-default': 'var(--prizm-palette-red-200)',
  '--prizm-status-alarm-secondary-hover': 'var(--prizm-palette-red-h250)',
  '--prizm-index-plan': '#0033ff',
  '--prizm-index-fact': '#20222b',
  '--prizm-index-prognosis': 'var(--prizm-palette-gray-600)',
  '--prizm-button-primary-solid-default': 'var(--prizm-palette-blue-600)',
  '--prizm-button-primary-solid-hover': 'var(--prizm-palette-blue-h650)',
  '--prizm-button-primary-solid-active': 'var(--prizm-palette-blue-700)',
  '--prizm-button-primary-ghost-active': 'var(--prizm-palette-blue-200)',
  '--prizm-button-secondary-solid-default': 'var(--prizm-palette-gray-600)',
  '--prizm-button-secondary-solid-hover': 'var(--prizm-palette-gray-h650)',
  '--prizm-button-secondary-solid-active': 'var(--prizm-palette-gray-700)',
  '--prizm-button-secondary-ghost-active': 'var(--prizm-palette-gray-200)',
  '--prizm-button-success-solid-default': 'var(--prizm-palette-green-600)',
  '--prizm-button-success-solid-hover': 'var(--prizm-palette-green-h650)',
  '--prizm-button-success-solid-active': 'var(--prizm-palette-green-700)',
  '--prizm-button-success-ghost-active': 'var(--prizm-palette-green-200)',
  '--prizm-button-warning-solid-default': 'var(--prizm-palette-orange-400)',
  '--prizm-button-warning-solid-hover': 'var(--prizm-palette-orange-h450)',
  '--prizm-button-warning-solid-active': 'var(--prizm-palette-orange-500)',
  '--prizm-button-warning-ghost-active': 'var(--prizm-palette-orange-200)',
  '--prizm-button-alarm-solid-default': 'var(--prizm-palette-red-600)',
  '--prizm-button-alarm-solid-hover': 'var(--prizm-palette-red-h650)',
  '--prizm-button-alarm-solid-active': 'var(--prizm-palette-red-700)',
  '--prizm-button-alarm-ghost-active': 'var(--prizm-palette-red-200)',
  '--prizm-button-ghost-hover': 'var(--prizm-palette-gray-h150)',
  '--prizm-button-disable': 'var(--prizm-palette-gray-100)',
  '--prizm-form-fill-default': 'var(--prizm-palette-gray-100)',
  '--prizm-form-fill-disable': 'var(--prizm-palette-gray-100)',
  '--prizm-form-stroke-default': 'var(--prizm-palette-gray-300)',
  '--prizm-form-stroke-hover': 'var(--prizm-palette-gray-h450)',
  '--prizm-form-stroke-disable': 'var(--prizm-palette-gray-100)',
  '--prizm-form-active': 'var(--prizm-palette-blue-600)',
  '--prizm-form-active-hover': 'var(--prizm-palette-blue-h650)',
  '--prizm-form-active-disable': 'var(--prizm-palette-blue-400)',
  '--prizm-chart-axis-default': 'var(--prizm-palette-gray-300)',
  '--prizm-chart-axis-line-hover': 'var(--prizm-palette-1200)',
  '--prizm-chart-axis-line-active': 'var(--prizm-palette-blue-600)',
  '--prizm-chart-axis-area-hover': '#8a91a233',
  '--prizm-chart-axis-area-active': '#436cee33',
  '--prizm-token': '#ffffff',
  '--prizm-chart-fill-blanket': '#8a91a233',
  '--prizm-shadow-color': 'var(--prizm-palette-shadow-light)',
  '--prizm-shadow-big-top': '0px -8px 32px 0px var(--prizm-shadow-color)',
  '--prizm-shadow-big-right': '8px 0px 32px 0px var(--prizm-shadow-color)',
  '--prizm-shadow-big-left': '-8px 0px 32px 0px var(--prizm-shadow-color)',
  '--prizm-shadow-big-bottom': '0px 8px 32px 0px var(--prizm-shadow-color)',
  '--prizm-shadow-mini-top': '0px -2px 4px 0px var(--prizm-shadow-color)',
  '--prizm-shadow-mini-right': '2px 0px 4px 0px var(--prizm-shadow-color)',
  '--prizm-shadow-mini-left': '-2px 0px 4px 0px var(--prizm-shadow-color)',
  '--prizm-shadow-mini-bottom': '0px 2px 4px 0px var(--prizm-shadow-color)',
};
export const PRIZM_LIGHT_THEME_PALETTE_CSS_VARS = {
  '--prizm-palette-0': 'var(--prizm-palette-0-light)',
  '--prizm-palette-1200': 'var(--prizm-palette-1200-light)',
  '--prizm-palette-gray-h50': 'var(--prizm-palette-gray-h50-light)',
  '--prizm-palette-gray-100': 'var(--prizm-palette-gray-100-light)',
  '--prizm-palette-gray-h150': 'var(--prizm-palette-gray-h150-light)',
  '--prizm-palette-gray-200': 'var(--prizm-palette-gray-200-light)',
  '--prizm-palette-gray-h250': 'var(--prizm-palette-gray-h250-light)',
  '--prizm-palette-gray-300': 'var(--prizm-palette-gray-300-light)',
  '--prizm-palette-gray-h350': 'var(--prizm-palette-gray-h350-light)',
  '--prizm-palette-gray-400': 'var(--prizm-palette-gray-400-light)',
  '--prizm-palette-gray-h450': 'var(--prizm-palette-gray-h450-light)',
  '--prizm-palette-gray-500': 'var(--prizm-palette-gray-500-light)',
  '--prizm-palette-gray-h550': 'var(--prizm-palette-gray-h550-light)',
  '--prizm-palette-gray-600': 'var(--prizm-palette-gray-600-light)',
  '--prizm-palette-gray-h650': 'var(--prizm-palette-gray-h650-light)',
  '--prizm-palette-gray-700': 'var(--prizm-palette-gray-700-light)',
  '--prizm-palette-gray-h750': 'var(--prizm-palette-gray-h750-light)',
  '--prizm-palette-gray-800': 'var(--prizm-palette-gray-800-light)',
  '--prizm-palette-gray-h850': 'var(--prizm-palette-gray-h850-light)',
  '--prizm-palette-gray-900': 'var(--prizm-palette-gray-900-light)',
  '--prizm-palette-gray-h950': 'var(--prizm-palette-gray-h950-light)',
  '--prizm-palette-gray-1000': 'var(--prizm-palette-gray-1000-light)',
  '--prizm-palette-gray-h1050': 'var(--prizm-palette-gray-h1050-light)',
  '--prizm-palette-gray-1100': 'var(--prizm-palette-gray-1100-light)',
  '--prizm-palette-gray-h1150': 'var(--prizm-palette-gray-h1150-light)',
  '--prizm-palette-blue-h50': 'var(--prizm-palette-blue-h50-light)',
  '--prizm-palette-blue-100': 'var(--prizm-palette-blue-100-light)',
  '--prizm-palette-blue-h150': 'var(--prizm-palette-blue-h150-light)',
  '--prizm-palette-blue-200': 'var(--prizm-palette-blue-200-light)',
  '--prizm-palette-blue-h250': 'var(--prizm-palette-blue-h250-light)',
  '--prizm-palette-blue-300': 'var(--prizm-palette-blue-300-light)',
  '--prizm-palette-blue-h350': 'var(--prizm-palette-blue-h350-light)',
  '--prizm-palette-blue-400': 'var(--prizm-palette-blue-400-light)',
  '--prizm-palette-blue-h450': 'var(--prizm-palette-blue-h450-light)',
  '--prizm-palette-blue-500': 'var(--prizm-palette-blue-500-light)',
  '--prizm-palette-blue-h550': 'var(--prizm-palette-blue-h550-light)',
  '--prizm-palette-blue-600': 'var(--prizm-palette-blue-600-light)',
  '--prizm-palette-blue-h650': 'var(--prizm-palette-blue-h650-light)',
  '--prizm-palette-blue-700': 'var(--prizm-palette-blue-700-light)',
  '--prizm-palette-blue-h750': 'var(--prizm-palette-blue-h750-light)',
  '--prizm-palette-blue-800': 'var(--prizm-palette-blue-800-light)',
  '--prizm-palette-blue-h850': 'var(--prizm-palette-blue-h850-light)',
  '--prizm-palette-blue-900': 'var(--prizm-palette-blue-900-light)',
  '--prizm-palette-blue-h950': 'var(--prizm-palette-blue-h950-light)',
  '--prizm-palette-blue-1000': 'var(--prizm-palette-blue-1000-light)',
  '--prizm-palette-blue-h1050': 'var(--prizm-palette-blue-h1050-light)',
  '--prizm-palette-blue-1100': 'var(--prizm-palette-blue-1100-light)',
  '--prizm-palette-blue-h1150': 'var(--prizm-palette-blue-h1150-light)',
  '--prizm-palette-green-h50': 'var(--prizm-palette-green-h50-light)',
  '--prizm-palette-green-100': 'var(--prizm-palette-green-100-light)',
  '--prizm-palette-green-h150': 'var(--prizm-palette-green-h150-light)',
  '--prizm-palette-green-200': 'var(--prizm-palette-green-200-light)',
  '--prizm-palette-green-h250': 'var(--prizm-palette-green-h250-light)',
  '--prizm-palette-green-300': 'var(--prizm-palette-green-300-light)',
  '--prizm-palette-green-h350': 'var(--prizm-palette-green-h350-light)',
  '--prizm-palette-green-400': 'var(--prizm-palette-green-400-light)',
  '--prizm-palette-green-h450': 'var(--prizm-palette-green-h450-light)',
  '--prizm-palette-green-500': 'var(--prizm-palette-green-500-light)',
  '--prizm-palette-green-h550': 'var(--prizm-palette-green-h550-light)',
  '--prizm-palette-green-600': 'var(--prizm-palette-green-600-light)',
  '--prizm-palette-green-h650': 'var(--prizm-palette-green-h650-light)',
  '--prizm-palette-green-700': 'var(--prizm-palette-green-700-light)',
  '--prizm-palette-green-h750': 'var(--prizm-palette-green-h750-light)',
  '--prizm-palette-green-800': 'var(--prizm-palette-green-800-light)',
  '--prizm-palette-green-h850': 'var(--prizm-palette-green-h850-light)',
  '--prizm-palette-green-900': 'var(--prizm-palette-green-900-light)',
  '--prizm-palette-green-h950': 'var(--prizm-palette-green-h950-light)',
  '--prizm-palette-green-1000': 'var(--prizm-palette-green-1000-light)',
  '--prizm-palette-green-h1050': 'var(--prizm-palette-green-h1050-light)',
  '--prizm-palette-green-1100': 'var(--prizm-palette-green-1100-light)',
  '--prizm-palette-green-h1150': 'var(--prizm-palette-green-h1150-light)',
  '--prizm-palette-yellow-h50': 'var(--prizm-palette-yellow-h50-light)',
  '--prizm-palette-yellow-100': 'var(--prizm-palette-yellow-100-light)',
  '--prizm-palette-yellow-h150': 'var(--prizm-palette-yellow-h150-light)',
  '--prizm-palette-yellow-200': 'var(--prizm-palette-yellow-200-light)',
  '--prizm-palette-yellow-h250': 'var(--prizm-palette-yellow-h250-light)',
  '--prizm-palette-yellow-300': 'var(--prizm-palette-yellow-300-light)',
  '--prizm-palette-yellow-h350': 'var(--prizm-palette-yellow-h350-light)',
  '--prizm-palette-yellow-400': 'var(--prizm-palette-yellow-400-light)',
  '--prizm-palette-yellow-h450': 'var(--prizm-palette-yellow-h450-light)',
  '--prizm-palette-yellow-500': 'var(--prizm-palette-yellow-500-light)',
  '--prizm-palette-yellow-h550': 'var(--prizm-palette-yellow-h550-light)',
  '--prizm-palette-yellow-600': 'var(--prizm-palette-yellow-600-light)',
  '--prizm-palette-yellow-h650': 'var(--prizm-palette-yellow-h650-light)',
  '--prizm-palette-yellow-700': 'var(--prizm-palette-yellow-700-light)',
  '--prizm-palette-yellow-h750': 'var(--prizm-palette-yellow-h750-light)',
  '--prizm-palette-yellow-800': 'var(--prizm-palette-yellow-800-light)',
  '--prizm-palette-yellow-h850': 'var(--prizm-palette-yellow-h850-light)',
  '--prizm-palette-yellow-900': 'var(--prizm-palette-yellow-900-light)',
  '--prizm-palette-yellow-h950': 'var(--prizm-palette-yellow-h950-light)',
  '--prizm-palette-yellow-1000': 'var(--prizm-palette-yellow-1000-light)',
  '--prizm-palette-yellow-h1050': 'var(--prizm-palette-yellow-h1050-light)',
  '--prizm-palette-yellow-1100': 'var(--prizm-palette-yellow-1100-light)',
  '--prizm-palette-yellow-h1150': 'var(--prizm-palette-yellow-h1150-light)',
  '--prizm-palette-orange-h50': 'var(--prizm-palette-orange-h50-light)',
  '--prizm-palette-orange-100': 'var(--prizm-palette-orange-100-light)',
  '--prizm-palette-orange-h150': 'var(--prizm-palette-orange-h150-light)',
  '--prizm-palette-orange-200': 'var(--prizm-palette-orange-200-light)',
  '--prizm-palette-orange-h250': 'var(--prizm-palette-orange-h250-light)',
  '--prizm-palette-orange-300': 'var(--prizm-palette-orange-300-light)',
  '--prizm-palette-orange-h350': 'var(--prizm-palette-orange-h350-light)',
  '--prizm-palette-orange-400': 'var(--prizm-palette-orange-400-light)',
  '--prizm-palette-orange-h450': 'var(--prizm-palette-orange-h450-light)',
  '--prizm-palette-orange-500': 'var(--prizm-palette-orange-500-light)',
  '--prizm-palette-orange-h550': 'var(--prizm-palette-orange-h550-light)',
  '--prizm-palette-orange-600': 'var(--prizm-palette-orange-600-light)',
  '--prizm-palette-orange-h650': 'var(--prizm-palette-orange-h650-light)',
  '--prizm-palette-orange-700': 'var(--prizm-palette-orange-700-light)',
  '--prizm-palette-orange-h750': 'var(--prizm-palette-orange-h750-light)',
  '--prizm-palette-orange-800': 'var(--prizm-palette-orange-800-light)',
  '--prizm-palette-orange-h850': 'var(--prizm-palette-orange-h850-light)',
  '--prizm-palette-orange-900': 'var(--prizm-palette-orange-900-light)',
  '--prizm-palette-orange-h950': 'var(--prizm-palette-orange-h950-light)',
  '--prizm-palette-orange-1000': 'var(--prizm-palette-orange-1000-light)',
  '--prizm-palette-orange-h1050': 'var(--prizm-palette-orange-h1050-light)',
  '--prizm-palette-orange-1100': 'var(--prizm-palette-orange-1100-light)',
  '--prizm-palette-orange-h1150': 'var(--prizm-palette-orange-h1150-light)',
  '--prizm-palette-red-h50': 'var(--prizm-palette-red-h50-light)',
  '--prizm-palette-red-100': 'var(--prizm-palette-red-100-light)',
  '--prizm-palette-red-h150': 'var(--prizm-palette-red-h150-light)',
  '--prizm-palette-red-200': 'var(--prizm-palette-red-200-light)',
  '--prizm-palette-red-h250': 'var(--prizm-palette-red-h250-light)',
  '--prizm-palette-red-300': 'var(--prizm-palette-red-300-light)',
  '--prizm-palette-red-h350': 'var(--prizm-palette-red-h350-light)',
  '--prizm-palette-red-400': 'var(--prizm-palette-red-400-light)',
  '--prizm-palette-red-h450': 'var(--prizm-palette-red-h450-light)',
  '--prizm-palette-red-500': 'var(--prizm-palette-red-500-light)',
  '--prizm-palette-red-h550': 'var(--prizm-palette-red-h550-light)',
  '--prizm-palette-red-600': 'var(--prizm-palette-red-600-light)',
  '--prizm-palette-red-h650': 'var(--prizm-palette-red-h650-light)',
  '--prizm-palette-red-700': 'var(--prizm-palette-red-700-light)',
  '--prizm-palette-red-h750': 'var(--prizm-palette-red-h750-light)',
  '--prizm-palette-red-800': 'var(--prizm-palette-red-800-light)',
  '--prizm-palette-red-h850': 'var(--prizm-palette-red-h850-light)',
  '--prizm-palette-red-900': 'var(--prizm-palette-red-900-light)',
  '--prizm-palette-red-h950': 'var(--prizm-palette-red-h950-light)',
  '--prizm-palette-red-1000': 'var(--prizm-palette-red-1000-light)',
  '--prizm-palette-red-h1050': 'var(--prizm-palette-red-h1050-light)',
  '--prizm-palette-red-1050': 'var(--prizm-palette-red-1050-light)',
  '--prizm-palette-red-h1150': 'var(--prizm-palette-red-h1150-light)',
};
export const PRIZM_DARK_THEME_SPECIFIC_CSS_VARS = {
  '--prizm-shadow-color': 'var(--prizm-shadow-color-dark)',
  '--prizm-palette-0': 'var(--prizm-palette-0-dark)',
  '--prizm-palette-1200': 'var(--prizm-palette-1200-dark)',
  '--prizm-palette-gray-h50': 'var(--prizm-palette-gray-h50-dark)',
  '--prizm-palette-gray-100': 'var(--prizm-palette-gray-100-dark)',
  '--prizm-palette-gray-h150': 'var(--prizm-palette-gray-h150-dark)',
  '--prizm-palette-gray-200': 'var(--prizm-palette-gray-200-dark)',
  '--prizm-palette-gray-h250': 'var(--prizm-palette-gray-h250-dark)',
  '--prizm-palette-gray-300': 'var(--prizm-palette-gray-300-dark)',
  '--prizm-palette-gray-h350': 'var(--prizm-palette-gray-h350-dark)',
  '--prizm-palette-gray-400': 'var(--prizm-palette-gray-400-dark)',
  '--prizm-palette-gray-h450': 'var(--prizm-palette-gray-h450-dark)',
  '--prizm-palette-gray-500': 'var(--prizm-palette-gray-500-dark)',
  '--prizm-palette-gray-h550': 'var(--prizm-palette-gray-h550-dark)',
  '--prizm-palette-gray-600': 'var(--prizm-palette-gray-600-dark)',
  '--prizm-palette-gray-h650': 'var(--prizm-palette-gray-h650-dark)',
  '--prizm-palette-gray-700': 'var(--prizm-palette-gray-700-dark)',
  '--prizm-palette-gray-h750': 'var(--prizm-palette-gray-h750-dark)',
  '--prizm-palette-gray-800': 'var(--prizm-palette-gray-800-dark)',
  '--prizm-palette-gray-h850': 'var(--prizm-palette-gray-h850-dark)',
  '--prizm-palette-gray-900': 'var(--prizm-palette-gray-900-dark)',
  '--prizm-palette-gray-h950': 'var(--prizm-palette-gray-h950-dark)',
  '--prizm-palette-gray-1000': 'var(--prizm-palette-gray-1000-dark)',
  '--prizm-palette-gray-h1050': 'var(--prizm-palette-gray-h1050-dark)',
  '--prizm-palette-gray-1100': 'var(--prizm-palette-gray-1100-dark)',
  '--prizm-palette-gray-h1150': 'var(--prizm-palette-gray-h1150-dark)',
  '--prizm-palette-blue-h50': 'var(--prizm-palette-blue-h50-dark)',
  '--prizm-palette-blue-100': 'var(--prizm-palette-blue-100-dark)',
  '--prizm-palette-blue-h150': 'var(--prizm-palette-blue-h150-dark)',
  '--prizm-palette-blue-200': 'var(--prizm-palette-blue-200-dark)',
  '--prizm-palette-blue-h250': 'var(--prizm-palette-blue-h250-dark)',
  '--prizm-palette-blue-300': 'var(--prizm-palette-blue-300-dark)',
  '--prizm-palette-blue-h350': 'var(--prizm-palette-blue-h350-dark)',
  '--prizm-palette-blue-400': 'var(--prizm-palette-blue-400-dark)',
  '--prizm-palette-blue-h450': 'var(--prizm-palette-blue-h450-dark)',
  '--prizm-palette-blue-500': 'var(--prizm-palette-blue-500-dark)',
  '--prizm-palette-blue-h550': 'var(--prizm-palette-blue-h550-dark)',
  '--prizm-palette-blue-600': 'var(--prizm-palette-blue-600-dark)',
  '--prizm-palette-blue-h650': 'var(--prizm-palette-blue-h650-dark)',
  '--prizm-palette-blue-700': 'var(--prizm-palette-blue-700-dark)',
  '--prizm-palette-blue-h750': 'var(--prizm-palette-blue-h750-dark)',
  '--prizm-palette-blue-800': 'var(--prizm-palette-blue-800-dark)',
  '--prizm-palette-blue-h850': 'var(--prizm-palette-blue-h850-dark)',
  '--prizm-palette-blue-900': 'var(--prizm-palette-blue-900-dark)',
  '--prizm-palette-blue-h950': 'var(--prizm-palette-blue-h950-dark)',
  '--prizm-palette-blue-1000': 'var(--prizm-palette-blue-1000-dark)',
  '--prizm-palette-blue-h1050': 'var(--prizm-palette-blue-h1050-dark)',
  '--prizm-palette-blue-1100': 'var(--prizm-palette-blue-1100-dark)',
  '--prizm-palette-blue-h1150': 'var(--prizm-palette-blue-h1150-dark)',
  '--prizm-palette-green-h50': 'var(--prizm-palette-green-h50-dark)',
  '--prizm-palette-green-100': 'var(--prizm-palette-green-100-dark)',
  '--prizm-palette-green-h150': 'var(--prizm-palette-green-h150-dark)',
  '--prizm-palette-green-200': 'var(--prizm-palette-green-200-dark)',
  '--prizm-palette-green-h250': 'var(--prizm-palette-green-h250-dark)',
  '--prizm-palette-green-300': 'var(--prizm-palette-green-300-dark)',
  '--prizm-palette-green-h350': 'var(--prizm-palette-green-h350-dark)',
  '--prizm-palette-green-400': 'var(--prizm-palette-green-400-dark)',
  '--prizm-palette-green-h450': 'var(--prizm-palette-green-h450-dark)',
  '--prizm-palette-green-500': 'var(--prizm-palette-green-500-dark)',
  '--prizm-palette-green-h550': 'var(--prizm-palette-green-h550-dark)',
  '--prizm-palette-green-600': 'var(--prizm-palette-green-600-dark)',
  '--prizm-palette-green-h650': 'var(--prizm-palette-green-h650-dark)',
  '--prizm-palette-green-700': 'var(--prizm-palette-green-700-dark)',
  '--prizm-palette-green-h750': 'var(--prizm-palette-green-h750-dark)',
  '--prizm-palette-green-800': 'var(--prizm-palette-green-800-dark)',
  '--prizm-palette-green-h850': 'var(--prizm-palette-green-h850-dark)',
  '--prizm-palette-green-900': 'var(--prizm-palette-green-900-dark)',
  '--prizm-palette-green-h950': 'var(--prizm-palette-green-h950-dark)',
  '--prizm-palette-green-1000': 'var(--prizm-palette-green-1000-dark)',
  '--prizm-palette-green-h1050': 'var(--prizm-palette-green-h1050-dark)',
  '--prizm-palette-green-1100': 'var(--prizm-palette-green-1100-dark)',
  '--prizm-palette-green-h1150': 'var(--prizm-palette-green-h1150-dark)',
  '--prizm-palette-yellow-h50': 'var(--prizm-palette-yellow-h50-dark)',
  '--prizm-palette-yellow-100': 'var(--prizm-palette-yellow-100-dark)',
  '--prizm-palette-yellow-h150': 'var(--prizm-palette-yellow-h150-dark)',
  '--prizm-palette-yellow-200': 'var(--prizm-palette-yellow-200-dark)',
  '--prizm-palette-yellow-h250': 'var(--prizm-palette-yellow-h250-dark)',
  '--prizm-palette-yellow-300': 'var(--prizm-palette-yellow-300-dark)',
  '--prizm-palette-yellow-h350': 'var(--prizm-palette-yellow-h350-dark)',
  '--prizm-palette-yellow-400': 'var(--prizm-palette-yellow-400-dark)',
  '--prizm-palette-yellow-h450': 'var(--prizm-palette-yellow-h450-dark)',
  '--prizm-palette-yellow-500': 'var(--prizm-palette-yellow-500-dark)',
  '--prizm-palette-yellow-h550': 'var(--prizm-palette-yellow-h550-dark)',
  '--prizm-palette-yellow-600': 'var(--prizm-palette-yellow-600-dark)',
  '--prizm-palette-yellow-h650': 'var(--prizm-palette-yellow-h650-dark)',
  '--prizm-palette-yellow-700': 'var(--prizm-palette-yellow-700-dark)',
  '--prizm-palette-yellow-h750': 'var(--prizm-palette-yellow-h750-dark)',
  '--prizm-palette-yellow-800': 'var(--prizm-palette-yellow-800-dark)',
  '--prizm-palette-yellow-h850': 'var(--prizm-palette-yellow-h850-dark)',
  '--prizm-palette-yellow-900': 'var(--prizm-palette-yellow-900-dark)',
  '--prizm-palette-yellow-h950': 'var(--prizm-palette-yellow-h950-dark)',
  '--prizm-palette-yellow-1000': 'var(--prizm-palette-yellow-1000-dark)',
  '--prizm-palette-yellow-h1050': 'var(--prizm-palette-yellow-h1050-dark)',
  '--prizm-palette-yellow-1100': 'var(--prizm-palette-yellow-1100-dark)',
  '--prizm-palette-yellow-h1150': 'var(--prizm-palette-yellow-h1150-dark)',
  '--prizm-palette-orange-h50': 'var(--prizm-palette-orange-h50-dark)',
  '--prizm-palette-orange-100': 'var(--prizm-palette-orange-100-dark)',
  '--prizm-palette-orange-h150': 'var(--prizm-palette-orange-h150-dark)',
  '--prizm-palette-orange-200': 'var(--prizm-palette-orange-200-dark)',
  '--prizm-palette-orange-h250': 'var(--prizm-palette-orange-h250-dark)',
  '--prizm-palette-orange-300': 'var(--prizm-palette-orange-300-dark)',
  '--prizm-palette-orange-h350': 'var(--prizm-palette-orange-h350-dark)',
  '--prizm-palette-orange-400': 'var(--prizm-palette-orange-400-dark)',
  '--prizm-palette-orange-h450': 'var(--prizm-palette-orange-h450-dark)',
  '--prizm-palette-orange-500': 'var(--prizm-palette-orange-500-dark)',
  '--prizm-palette-orange-h550': 'var(--prizm-palette-orange-h550-dark)',
  '--prizm-palette-orange-600': 'var(--prizm-palette-orange-600-dark)',
  '--prizm-palette-orange-h650': 'var(--prizm-palette-orange-h650-dark)',
  '--prizm-palette-orange-700': 'var(--prizm-palette-orange-700-dark)',
  '--prizm-palette-orange-h750': 'var(--prizm-palette-orange-h750-dark)',
  '--prizm-palette-orange-800': 'var(--prizm-palette-orange-800-dark)',
  '--prizm-palette-orange-h850': 'var(--prizm-palette-orange-h850-dark)',
  '--prizm-palette-orange-900': 'var(--prizm-palette-orange-900-dark)',
  '--prizm-palette-orange-h950': 'var(--prizm-palette-orange-h950-dark)',
  '--prizm-palette-orange-1000': 'var(--prizm-palette-orange-1000-dark)',
  '--prizm-palette-orange-h1050': 'var(--prizm-palette-orange-h1050-dark)',
  '--prizm-palette-orange-1100': 'var(--prizm-palette-orange-1100-dark)',
  '--prizm-palette-orange-h1150': 'var(--prizm-palette-orange-h1150-dark)',
  '--prizm-palette-red-h50': 'var(--prizm-palette-red-h50-dark)',
  '--prizm-palette-red-100': 'var(--prizm-palette-red-100-dark)',
  '--prizm-palette-red-h150': 'var(--prizm-palette-red-h150-dark)',
  '--prizm-palette-red-200': 'var(--prizm-palette-red-200-dark)',
  '--prizm-palette-red-h250': 'var(--prizm-palette-red-h250-dark)',
  '--prizm-palette-red-300': 'var(--prizm-palette-red-300-dark)',
  '--prizm-palette-red-h350': 'var(--prizm-palette-red-h350-dark)',
  '--prizm-palette-red-400': 'var(--prizm-palette-red-400-dark)',
  '--prizm-palette-red-h450': 'var(--prizm-palette-red-h450-dark)',
  '--prizm-palette-red-500': 'var(--prizm-palette-red-500-dark)',
  '--prizm-palette-red-h550': 'var(--prizm-palette-red-h550-dark)',
  '--prizm-palette-red-600': 'var(--prizm-palette-red-600-dark)',
  '--prizm-palette-red-h650': 'var(--prizm-palette-red-h650-dark)',
  '--prizm-palette-red-700': 'var(--prizm-palette-red-700-dark)',
  '--prizm-palette-red-h750': 'var(--prizm-palette-red-h750-dark)',
  '--prizm-palette-red-800': 'var(--prizm-palette-red-800-dark)',
  '--prizm-palette-red-h850': 'var(--prizm-palette-red-h850-dark)',
  '--prizm-palette-red-900': 'var(--prizm-palette-red-900-dark)',
  '--prizm-palette-red-h950': 'var(--prizm-palette-red-h950-dark)',
  '--prizm-palette-red-1000': 'var(--prizm-palette-red-1000-dark)',
  '--prizm-palette-red-h1050': 'var(--prizm-palette-red-h1050-dark)',
  '--prizm-palette-red-1050': 'var(--prizm-palette-red-1050-dark)',
  '--prizm-palette-red-h1150': 'var(--prizm-palette-red-h1150-dark)',
};
export const PRIZM_DARK_THEME_PALETTE_CSS_VARS = {
  '--prizm-text-icon-primary': 'var(--prizm-palette-1200)',
  '--prizm-text-icon-secondary': 'var(--prizm-palette-gray-800)',
  '--prizm-text-icon-tertiary': 'var(--prizm-palette-gray-600)',
  '--prizm-text-icon-disable': 'var(--prizm-palette-gray-500)',
  '--prizm-text-icon-link': 'var(--prizm-palette-blue-500)',
  '--prizm-text-icon-link-hover': 'var(--prizm-palette-blue-h750)',
  '--prizm-text-icon-link-visited': 'var(--prizm-palette-blue-700)',
  '--prizm-text-icon-reverse': 'var(--prizm-palette-0)',
  '--prizm-text-icon-exception': 'var(--prizm-palette-1200)',
  '--prizm-background-fill-primary': 'var(--prizm-palette-gray-100)',
  '--prizm-background-fill-secondary': 'var(--prizm-palette-0)',
  '--prizm-background-fill-panel': 'var(--prizm-palette-gray-300)',
  '--prizm-background-fill-overlay': 'var(--prizm-palette-gray-200)',
  '--prizm-background-fill-blanket': '#0f11157f',
  '--prizm-background-fill-reverse': 'var(--prizm-palette-gray-1100)',
  '--prizm-background-stroke': 'var(--prizm-palette-gray-400)',
  '--prizm-background-stroke-focus': 'var(--prizm-palette-blue-800)',
  '--prizm-table-fill-header-default': 'var(--prizm-palette-gray-200)',
  '--prizm-table-fill-header-hover': 'var(--prizm-palette-gray-h250)',
  '--prizm-table-fill-row-zebra_default': 'var(--prizm-palette-gray-200)',
  '--prizm-table-fill-row-hover': 'var(--prizm-palette-gray-h250)',
  '--prizm-table-fill-row-active': 'var(--prizm-palette-blue-400)',
  '--prizm-table-fill-cell-disable': 'var(--prizm-palette-gray-300)',
  '--prizm-table-stroke-cell-default': 'var(--prizm-palette-gray-400)',
  '--prizm-table-stroke-cell-hover': 'var(--prizm-palette-gray-h650)',
  '--prizm-table-stroke-cell-active': 'var(--prizm-palette-blue-600)',
  '--prizm-status-info-primary-default': 'var(--prizm-palette-blue-600)',
  '--prizm-status-info-primary-hover': 'var(--prizm-palette-blue-h650)',
  '--prizm-status-info-secondary-default': 'var(--prizm-palette-blue-300)',
  '--prizm-status-info-secondary-hover': 'var(--prizm-palette-blue-h350)',
  '--prizm-status-none-primary-default': 'var(--prizm-palette-gray-600)',
  '--prizm-status-none-primary-hover': 'var(--prizm-palette-gray-h650)',
  '--prizm-status-none-secondary-default': 'var(--prizm-palette-gray-300)',
  '--prizm-status-none-secondary-hover': 'var(--prizm-palette-gray-h350)',
  '--prizm-status-success-primary-default': 'var(--prizm-palette-green-600)',
  '--prizm-status-success-primary-hover': 'var(--prizm-palette-green-h650)',
  '--prizm-status-success-secondary-default': 'var(--prizm-palette-green-300)',
  '--prizm-status-success-secondary-hover': 'var(--prizm-palette-green-h350)',
  '--prizm-status-attention-primary-default': 'var(--prizm-palette-yellow-900)',
  '--prizm-status-attention-primary-hover': 'var(--prizm-palette-yellow-h950)',
  '--prizm-status-attention-secondary-default': 'var(--prizm-palette-yellow-300)',
  '--prizm-status-attention-secondary-hover': 'var(--prizm-palette-yellow-h350)',
  '--prizm-status-warning-primary-default': 'var(--prizm-palette-orange-800)',
  '--prizm-status-warning-primary-hover': 'var(--prizm-palette-orange-h850)',
  '--prizm-status-warning-secondary-default': 'var(--prizm-palette-orange-300)',
  '--prizm-status-warning-secondary-hover': 'var(--prizm-palette-orange-h350)',
  '--prizm-status-alarm-primary-default': 'var(--prizm-palette-red-600)',
  '--prizm-status-alarm-primary-hover': 'var(--prizm-palette-red-h650)',
  '--prizm-status-alarm-secondary-default': 'var(--prizm-palette-red-300)',
  '--prizm-status-alarm-secondary-hover': 'var(--prizm-palette-red-h350)',
  '--prizm-index-plan': '#4075ff',
  '--prizm-index-fact': '#ffffff',
  '--prizm-index-prognosis': 'var(--prizm-palette-gray-600)',
  '--prizm-button-primary-solid-default': 'var(--prizm-palette-blue-600)',
  '--prizm-button-primary-solid-hover': 'var(--prizm-palette-blue-h650)',
  '--prizm-button-primary-solid-active': 'var(--prizm-palette-blue-700)',
  '--prizm-button-primary-ghost-active': 'var(--prizm-palette-blue-400)',
  '--prizm-button-secondary-solid-default': 'var(--prizm-palette-gray-600)',
  '--prizm-button-secondary-solid-hover': 'var(--prizm-palette-gray-h650)',
  '--prizm-button-secondary-solid-active': 'var(--prizm-palette-gray-700)',
  '--prizm-button-secondary-ghost-active': 'var(--prizm-palette-gray-400)',
  '--prizm-button-success-solid-default': 'var(--prizm-palette-green-600)',
  '--prizm-button-success-solid-hover': 'var(--prizm-palette-green-h650)',
  '--prizm-button-success-solid-active': 'var(--prizm-palette-green-700)',
  '--prizm-button-success-ghost-active': 'var(--prizm-palette-green-400)',
  '--prizm-button-warning-solid-default': 'var(--prizm-palette-orange-800)',
  '--prizm-button-warning-solid-hover': 'var(--prizm-palette-orange-h850)',
  '--prizm-button-warning-solid-active': 'var(--prizm-palette-orange-900)',
  '--prizm-button-warning-ghost-active': 'var(--prizm-palette-orange-400)',
  '--prizm-button-alarm-solid-default': 'var(--prizm-palette-red-600)',
  '--prizm-button-alarm-solid-hover': 'var(--prizm-palette-red-h650)',
  '--prizm-button-alarm-solid-active': 'var(--prizm-palette-red-700)',
  '--prizm-button-alarm-ghost-active': 'var(--prizm-palette-red-400)',
  '--prizm-button-ghost-hover': 'var(--prizm-palette-gray-h350)',
  '--prizm-button-disable': 'var(--prizm-palette-gray-300)',
  '--prizm-form-fill-default': 'var(--prizm-palette-0)',
  '--prizm-form-fill-disable': 'var(--prizm-palette-0)',
  '--prizm-form-stroke-default': 'var(--prizm-palette-gray-500)',
  '--prizm-form-stroke-hover': 'var(--prizm-palette-gray-h650)',
  '--prizm-form-stroke-disable': 'var(--prizm-palette-1200)',
  '--prizm-form-active': 'var(--prizm-palette-blue-600)',
  '--prizm-form-active-hover': 'var(--prizm-palette-blue-h650)',
  '--prizm-form-active-disable': 'var(--prizm-palette-blue-400)',
  '--prizm-chart-axis-default': 'var(--prizm-palette-gray-300)',
  '--prizm-chart-axis-line-hover': 'var(--prizm-palette-1200)',
  '--prizm-chart-axis-line-active': 'var(--prizm-palette-blue-600)',
  '--prizm-chart-axis-area-hover': '#8a91a233',
  '--prizm-chart-axis-area-active': '#436cee33',
  '--prizm-chart-fill-blanket': '#8a91a233',
  '--prizm-shadow-color': 'var(--prizm-palette-shadow-dark)',
  '--prizm-shadow-big-top': '0px -8px 32px 0px var(--prizm-shadow-color)',
  '--prizm-shadow-big-right': '8px 0px 32px 0px var(--prizm-shadow-color)',
  '--prizm-shadow-big-left': '-8px 0px 32px 0px var(--prizm-shadow-color)',
  '--prizm-shadow-big-bottom': '0px 8px 32px 0px var(--prizm-shadow-color)',
  '--prizm-shadow-mini-top': '0px -2px 4px 0px var(--prizm-shadow-color)',
  '--prizm-shadow-mini-right': '2px 0px 4px 0px var(--prizm-shadow-color)',
  '--prizm-shadow-mini-left': '-2px 0px 4px 0px var(--prizm-shadow-color)',
  '--prizm-shadow-mini-bottom': '0px 2px 4px 0px var(--prizm-shadow-color)',
};

export const PRIZM_PALETTE_THEME_CSS_VARS = {
  '--prizm-palette-shadow-dark': 'rgba(0, 0, 0, 0.5)',
  '--prizm-palette-shadow-light': 'rgba(191, 198, 215, 0.5)',
  '--prizm-palette-0-light': '#ffffff',
  '--prizm-palette-1200-light': '#0f1115',
  '--prizm-palette-gray-h50-light': '#f8f8f9',
  '--prizm-palette-gray-100-light': '#f3f4f5',
  '--prizm-palette-gray-h150-light': '#ebedef',
  '--prizm-palette-gray-200-light': '#e0e3e7',
  '--prizm-palette-gray-h250-light': '#d4d8dd',
  '--prizm-palette-gray-300-light': '#c3c7cf',
  '--prizm-palette-gray-h350-light': '#b7bcc6',
  '--prizm-palette-gray-400-light': '#a7acb8',
  '--prizm-palette-gray-h450-light': '#9ba1af',
  '--prizm-palette-gray-500-light': '#8a91a2',
  '--prizm-palette-gray-h550-light': '#7e8799',
  '--prizm-palette-gray-600-light': '#6e778c',
  '--prizm-palette-gray-h650-light': '#646d84',
  '--prizm-palette-gray-700-light': '#545f77',
  '--prizm-palette-gray-h750-light': '#4c556b',
  '--prizm-palette-gray-800-light': '#3f4759',
  '--prizm-palette-gray-h850-light': '#373e4e',
  '--prizm-palette-gray-900-light': '#2c313d',
  '--prizm-palette-gray-h950-light': '#292e3a',
  '--prizm-palette-gray-1000-light': '#252a35',
  '--prizm-palette-gray-h1050-light': '#222731',
  '--prizm-palette-gray-1100-light': '#1e222a',
  '--prizm-palette-gray-h1150-light': '#181b22',
  '--prizm-palette-blue-h50-light': '#f6f8ff',
  '--prizm-palette-blue-100-light': '#f0f4ff',
  '--prizm-palette-blue-h150-light': '#e7edff',
  '--prizm-palette-blue-200-light': '#d9e2ff',
  '--prizm-palette-blue-h250-light': '#cad6fe',
  '--prizm-palette-blue-300-light': '#b4c4fd',
  '--prizm-palette-blue-h350-light': '#a4b8fd',
  '--prizm-palette-blue-400-light': '#8da7fd',
  '--prizm-palette-blue-h450-light': '#7d9bfd',
  '--prizm-palette-blue-500-light': '#6689fd',
  '--prizm-palette-blue-h550-light': '#587df7',
  '--prizm-palette-blue-600-light': '#436cee',
  '--prizm-palette-blue-h650-light': '#3d63da',
  '--prizm-palette-blue-700-light': '#3555bd',
  '--prizm-palette-blue-h750-light': '#304daa',
  '--prizm-palette-blue-800-light': '#28408d',
  '--prizm-palette-blue-h850-light': '#23387c',
  '--prizm-palette-blue-900-light': '#1b2b62',
  '--prizm-palette-blue-h950-light': '#1a295d',
  '--prizm-palette-blue-1000-light': '#182556',
  '--prizm-palette-blue-h1050-light': '#16234f',
  '--prizm-palette-blue-1100-light': '#132045',
  '--prizm-palette-blue-h1150-light': '#111a32',
  '--prizm-palette-green-h50-light': '#f5faf2',
  '--prizm-palette-green-100-light': '#eff6ea',
  '--prizm-palette-green-h150-light': '#e6f0dd',
  '--prizm-palette-green-200-light': '#d8e7ca',
  '--prizm-palette-green-h250-light': '#c9deb6',
  '--prizm-palette-green-300-light': '#b2d198',
  '--prizm-palette-green-h350-light': '#a3c784',
  '--prizm-palette-green-400-light': '#8cb966',
  '--prizm-palette-green-h450-light': '#7caf52',
  '--prizm-palette-green-500-light': '#65a134',
  '--prizm-palette-green-h550-light': '#5a9727',
  '--prizm-palette-green-600-light': '#498714',
  '--prizm-palette-green-h650-light': '#3e7c13',
  '--prizm-palette-green-700-light': '#3b6a15',
  '--prizm-palette-green-h750-light': '#366015',
  '--prizm-palette-green-800-light': '#2f5014',
  '--prizm-palette-green-h850-light': '#2a4513',
  '--prizm-palette-green-900-light': '#223512',
  '--prizm-palette-green-h950-light': '#203212',
  '--prizm-palette-green-1000-light': '#1e2e11',
  '--prizm-palette-green-h1050-light': '#1c2b0f',
  '--prizm-palette-green-1100-light': '#1a260d',
  '--prizm-palette-green-h1150-light': '#161e10',
  '--prizm-palette-yellow-h50-light': '#fef8e3',
  '--prizm-palette-yellow-100-light': '#fdf3d1',
  '--prizm-palette-yellow-h150-light': '#fbecb3',
  '--prizm-palette-yellow-200-light': '#f9e187',
  '--prizm-palette-yellow-h250-light': '#f4d560',
  '--prizm-palette-yellow-300-light': '#edc225',
  '--prizm-palette-yellow-h350-light': '#e1b725',
  '--prizm-palette-yellow-400-light': '#cea724',
  '--prizm-palette-yellow-h450-light': '#c29d24',
  '--prizm-palette-yellow-500-light': '#af8d23',
  '--prizm-palette-yellow-h550-light': '#a28322',
  '--prizm-palette-yellow-600-light': '#8f7420',
  '--prizm-palette-yellow-h650-light': '#836a1e',
  '--prizm-palette-yellow-700-light': '#725b1c',
  '--prizm-palette-yellow-h750-light': '#67521a',
  '--prizm-palette-yellow-800-light': '#564518',
  '--prizm-palette-yellow-h850-light': '#4b3d17',
  '--prizm-palette-yellow-900-light': '#3b3015',
  '--prizm-palette-yellow-h950-light': '#382d14',
  '--prizm-palette-yellow-1000-light': '#342912',
  '--prizm-palette-yellow-h1050-light': '#302511',
  '--prizm-palette-yellow-1100-light': '#29200f',
  '--prizm-palette-yellow-h1150-light': '#1f1a11',
  '--prizm-palette-orange-h50-light': '#fef7ee',
  '--prizm-palette-orange-100-light': '#fdf1e2',
  '--prizm-palette-orange-h150-light': '#fbe9d1',
  '--prizm-palette-orange-200-light': '#f8deb8',
  '--prizm-palette-orange-h250-light': '#f6d09a',
  '--prizm-palette-orange-300-light': '#f2bc6d',
  '--prizm-palette-orange-h350-light': '#f0ae51',
  '--prizm-palette-orange-400-light': '#ec9827',
  '--prizm-palette-orange-h450-light': '#de8e27',
  '--prizm-palette-orange-500-light': '#c98027',
  '--prizm-palette-orange-h550-light': '#bb7725',
  '--prizm-palette-orange-600-light': '#a66923',
  '--prizm-palette-orange-h650-light': '#986122',
  '--prizm-palette-orange-700-light': '#835420',
  '--prizm-palette-orange-h750-light': '#764b1e',
  '--prizm-palette-orange-800-light': '#623e1b',
  '--prizm-palette-orange-h850-light': '#553619',
  '--prizm-palette-orange-900-light': '#422b16',
  '--prizm-palette-orange-h950-light': '#3e2915',
  '--prizm-palette-orange-1000-light': '#392513',
  '--prizm-palette-orange-h1050-light': '#352312',
  '--prizm-palette-orange-1100-light': '#2e1f11',
  '--prizm-palette-orange-h1150-light': '#221913',
  '--prizm-palette-red-h50-light': '#fdf7f5',
  '--prizm-palette-red-100-light': '#fcf1ee',
  '--prizm-palette-red-h150-light': '#fae9e4',
  '--prizm-palette-red-200-light': '#f7dcd4',
  '--prizm-palette-red-h250-light': '#f4cec3',
  '--prizm-palette-red-300-light': '#f0baa9',
  '--prizm-palette-red-h350-light': '#eeab99',
  '--prizm-palette-red-400-light': '#eb9481',
  '--prizm-palette-red-h450-light': '#e9836f',
  '--prizm-palette-red-500-light': '#e66a54',
  '--prizm-palette-red-h550-light': '#df5a46',
  '--prizm-palette-red-600-light': '#d44332',
  '--prizm-palette-red-h650-light': '#c13f2f',
  '--prizm-palette-red-700-light': '#a5382a',
  '--prizm-palette-red-h750-light': '#943427',
  '--prizm-palette-red-800-light': '#7a2d22',
  '--prizm-palette-red-h850-light': '#6a291e',
  '--prizm-palette-red-900-light': '#512218',
  '--prizm-palette-red-h950-light': '#4d2017',
  '--prizm-palette-red-1000-light': '#461e15',
  '--prizm-palette-red-h1050-light': '#401c14',
  '--prizm-palette-red-1050-light': '#371912',
  '--prizm-palette-red-h1150-light': '#271613',
  '--prizm-palette-0-dark': '#0f1115',
  '--prizm-palette-1200-dark': '#ffffff',
  '--prizm-palette-gray-h50-dark': '#181b22',
  '--prizm-palette-gray-100-dark': '#1e222a',
  '--prizm-palette-gray-h150-dark': '#222731',
  '--prizm-palette-gray-200-dark': '#252a35',
  '--prizm-palette-gray-h250-dark': '#292e3a',
  '--prizm-palette-gray-300-dark': '#2c313d',
  '--prizm-palette-gray-h350-dark': '#373e4e',
  '--prizm-palette-gray-400-dark': '#3f4759',
  '--prizm-palette-gray-h450-dark': '#4c556b',
  '--prizm-palette-gray-500-dark': '#545f77',
  '--prizm-palette-gray-h550-dark': '#646d84',
  '--prizm-palette-gray-600-dark': '#6e778c',
  '--prizm-palette-gray-h650-dark': '#7e8799',
  '--prizm-palette-gray-700-dark': '#8a91a2',
  '--prizm-palette-gray-h750-dark': '#9ba1af',
  '--prizm-palette-gray-800-dark': '#a7acb8',
  '--prizm-palette-gray-h850-dark': '#b7bcc6',
  '--prizm-palette-gray-900-dark': '#c3c7cf',
  '--prizm-palette-gray-h950-dark': '#d4d8dd',
  '--prizm-palette-gray-1000-dark': '#e0e3e7',
  '--prizm-palette-gray-h1050-dark': '#ebedef',
  '--prizm-palette-gray-1100-dark': '#f3f4f5',
  '--prizm-palette-gray-h1150-dark': '#f8f8f9',
  '--prizm-palette-blue-h50-dark': '#111a32',
  '--prizm-palette-blue-100-dark': '#132045',
  '--prizm-palette-blue-h150-dark': '#16234f',
  '--prizm-palette-blue-200-dark': '#182556',
  '--prizm-palette-blue-h250-dark': '#1a295d',
  '--prizm-palette-blue-300-dark': '#1b2b62',
  '--prizm-palette-blue-h350-dark': '#23387c',
  '--prizm-palette-blue-400-dark': '#28408d',
  '--prizm-palette-blue-h450-dark': '#304daa',
  '--prizm-palette-blue-500-dark': '#3555bd',
  '--prizm-palette-blue-h550-dark': '#3d63da',
  '--prizm-palette-blue-600-dark': '#436cee',
  '--prizm-palette-blue-h650-dark': '#587df7',
  '--prizm-palette-blue-700-dark': '#6689fd',
  '--prizm-palette-blue-h750-dark': '#7d9bfd',
  '--prizm-palette-blue-800-dark': '#8da7fd',
  '--prizm-palette-blue-h850-dark': '#a4b8fd',
  '--prizm-palette-blue-900-dark': '#b4c4fd',
  '--prizm-palette-blue-h950-dark': '#cad6fe',
  '--prizm-palette-blue-1000-dark': '#d9e2ff',
  '--prizm-palette-blue-h1050-dark': '#e7edff',
  '--prizm-palette-blue-1100-dark': '#f0f4ff',
  '--prizm-palette-blue-h1150-dark': '#f4f7fe',
  '--prizm-palette-green-h50-dark': '#161e10',
  '--prizm-palette-green-100-dark': '#1a260d',
  '--prizm-palette-green-h150-dark': '#1c2b0f',
  '--prizm-palette-green-200-dark': '#1e2e11',
  '--prizm-palette-green-h250-dark': '#203212',
  '--prizm-palette-green-300-dark': '#223512',
  '--prizm-palette-green-h350-dark': '#2a4513',
  '--prizm-palette-green-400-dark': '#2f5014',
  '--prizm-palette-green-h450-dark': '#366015',
  '--prizm-palette-green-500-dark': '#3b6a15',
  '--prizm-palette-green-h550-dark': '#3e7c13',
  '--prizm-palette-green-600-dark': '#498714',
  '--prizm-palette-green-h650-dark': '#5a9727',
  '--prizm-palette-green-700-dark': '#65a134',
  '--prizm-palette-green-h750-dark': '#7caf52',
  '--prizm-palette-green-800-dark': '#8cb966',
  '--prizm-palette-green-h850-dark': '#a3c784',
  '--prizm-palette-green-900-dark': '#b2d198',
  '--prizm-palette-green-h950-dark': '#c9deb6',
  '--prizm-palette-green-1000-dark': '#d8e7ca',
  '--prizm-palette-green-h1050-dark': '#e6f0dd',
  '--prizm-palette-green-1100-dark': '#eff6ea',
  '--prizm-palette-green-h1150-dark': '#f5faf2',
  '--prizm-palette-yellow-h50-dark': '#1f1a11',
  '--prizm-palette-yellow-100-dark': '#29200f',
  '--prizm-palette-yellow-h150-dark': '#302511',
  '--prizm-palette-yellow-200-dark': '#342912',
  '--prizm-palette-yellow-h250-dark': '#382d14',
  '--prizm-palette-yellow-300-dark': '#3b3015',
  '--prizm-palette-yellow-h350-dark': '#4b3d17',
  '--prizm-palette-yellow-400-dark': '#564518',
  '--prizm-palette-yellow-h450-dark': '#67521a',
  '--prizm-palette-yellow-500-dark': '#725b1c',
  '--prizm-palette-yellow-h550-dark': '#836a1e',
  '--prizm-palette-yellow-600-dark': '#8f7420',
  '--prizm-palette-yellow-h650-dark': '#a28322',
  '--prizm-palette-yellow-700-dark': '#af8d23',
  '--prizm-palette-yellow-h750-dark': '#c29d24',
  '--prizm-palette-yellow-800-dark': '#cea724',
  '--prizm-palette-yellow-h850-dark': '#e1b725',
  '--prizm-palette-yellow-900-dark': '#edc225',
  '--prizm-palette-yellow-h950-dark': '#f4d560',
  '--prizm-palette-yellow-1000-dark': '#f9e187',
  '--prizm-palette-yellow-h1050-dark': '#fbecb3',
  '--prizm-palette-yellow-1100-dark': '#fdf3d1',
  '--prizm-palette-yellow-h1150-dark': '#fef8e3',
  '--prizm-palette-orange-h50-dark': '#221913',
  '--prizm-palette-orange-100-dark': '#2e1f11',
  '--prizm-palette-orange-h150-dark': '#352312',
  '--prizm-palette-orange-200-dark': '#392513',
  '--prizm-palette-orange-h250-dark': '#3e2915',
  '--prizm-palette-orange-300-dark': '#422b16',
  '--prizm-palette-orange-h350-dark': '#553619',
  '--prizm-palette-orange-400-dark': '#623e1b',
  '--prizm-palette-orange-h450-dark': '#764b1e',
  '--prizm-palette-orange-500-dark': '#835420',
  '--prizm-palette-orange-h550-dark': '#986122',
  '--prizm-palette-orange-600-dark': '#a66923',
  '--prizm-palette-orange-h650-dark': '#bb7725',
  '--prizm-palette-orange-700-dark': '#c98027',
  '--prizm-palette-orange-h750-dark': '#de8e27',
  '--prizm-palette-orange-800-dark': '#ec9827',
  '--prizm-palette-orange-h850-dark': '#f0ae51',
  '--prizm-palette-orange-900-dark': '#f2bc6d',
  '--prizm-palette-orange-h950-dark': '#f6d09a',
  '--prizm-palette-orange-1000-dark': '#f8deb8',
  '--prizm-palette-orange-h1050-dark': '#fbe9d1',
  '--prizm-palette-orange-1100-dark': '#fdf1e2',
  '--prizm-palette-orange-h1150-dark': '#fef7ee',
  '--prizm-palette-red-h50-dark': '#271613',
  '--prizm-palette-red-100-dark': '#371912',
  '--prizm-palette-red-h150-dark': '#401c14',
  '--prizm-palette-red-200-dark': '#461e15',
  '--prizm-palette-red-h250-dark': '#4d2017',
  '--prizm-palette-red-300-dark': '#512218',
  '--prizm-palette-red-h350-dark': '#6a291e',
  '--prizm-palette-red-400-dark': '#7a2d22',
  '--prizm-palette-red-h450-dark': '#943427',
  '--prizm-palette-red-500-dark': '#a5382a',
  '--prizm-palette-red-h550-dark': '#c13f2f',
  '--prizm-palette-red-600-dark': '#d44332',
  '--prizm-palette-red-h650-dark': '#df5a46',
  '--prizm-palette-red-700-dark': '#e66a54',
  '--prizm-palette-red-h750-dark': '#e9836f',
  '--prizm-palette-red-800-dark': '#eb9481',
  '--prizm-palette-red-h850-dark': '#eeab99',
  '--prizm-palette-red-900-dark': '#f0baa9',
  '--prizm-palette-red-h950-dark': '#f4cec3',
  '--prizm-palette-red-1000-dark': '#f7dcd4',
  '--prizm-palette-red-h1050-dark': '#fae9e4',
  '--prizm-palette-red-1050-dark': '#fcf1ee',
  '--prizm-palette-red-h1150-dark': '#fdf7f5',
};

export const PRIZM_LIGHT_THEME_CSS_VARS = {
  ...PRIZM_LIGHT_THEME_SPECIFIC_CSS_VARS,
  ...PRIZM_LIGHT_THEME_PALETTE_CSS_VARS,
};

export const PRIZM_DARK_THEME_CSS_VARS = {
  ...PRIZM_DARK_THEME_SPECIFIC_CSS_VARS,
  ...PRIZM_DARK_THEME_PALETTE_CSS_VARS,
};
