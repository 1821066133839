<div class="container container--{{ orientation }}" #container>
  <prizm-splitter-gutter
    *ngFor="let gutter of guttersData"
    [areaBefore]="$any(gutter.areaBefore)"
    [areaAfter]="$any(gutter.areaAfter)"
    [order]="$any(gutter.order)"
    [orientation]="orientation"
    [template]="$any(customGutter?.template)"
  >
  </prizm-splitter-gutter>
  <ng-content select="prizm-splitter-area"></ng-content>
</div>
