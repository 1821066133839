<prizm-tree-item
  #view
  *prizmLet="children$ | async as children"
  [prizmTreeNode]="value"
  [usePaddingIndent]="usePaddingIndent"
>
  <ng-container *ngIf="$any(value) !== children">
    <div *polymorphOutlet="content as val; context: { $implicit: value, node: view }">{{ val }}</div>
  </ng-container>

  <prizm-tree *ngFor="let child of children" [value]="child" [content]="content"></prizm-tree>
</prizm-tree-item>
