<ng-container *ngIf="!empty; else emptyTpl">
  <ng-content></ng-content>
</ng-container>

<ng-template #emptyTpl>&nbsp;</ng-template>

<ng-container *prizmInputLayoutLeft>
  <prizm-input-carousel-auxiliary-left [carousel]="this"> </prizm-input-carousel-auxiliary-left>
</ng-container>

<ng-container *prizmInputLayoutRight>
  <prizm-input-carousel-auxiliary-right [carousel]="this"> </prizm-input-carousel-auxiliary-right>
</ng-container>
