<prizm-doc-page class="info-page" header="Как перейти">
  <h3>Подключите библиотеки</h3>
  <a href="{{ storybookBaseUrl }}/how-to-work/for-developers">Как работать с Prizm (быстрый старт)</a>

  <h3>Посмотрите, каких компонентов вам не хватает</h3>
  <p>
    В дизайн-системе есть самые основные компоненты для разработки интерфейсов. Скорее всего, вам понадобится
    несколько уникальных компонентов или доработок. Должно быть примерно так:
  </p>
  <ol class="disc">
    <li>80% — компоненты из дизайн-системы,</li>
    <li>20% — новые компоненты и доработки.</li>
  </ol>
  <p>
    Посмотрите заранее, что уже есть, а что вам может понадобиться. Где смотреть: Статья «<a
      href="https://jira.zyfra.com/wiki/pages/viewpage.action?pageId=151104107"
      >Репозитории</a
    >»
  </p>
  <p>
    Если что-то не нашлось, проверьте, может быть, задача на доработки, которые нужны вам, уже запланирована
    или в работе.
  </p>
  <ol class="disc">
    <li>
      <a href="{{ storybookBaseUrl }}/about-prizm/roadmap">Дорожная карта и задачи</a>
      <ol class="disc">
        <li>
          Со временем дорожная карта будет в GitHub:
          <a href="https://github.com/orgs/zyfra/projects/1">ссылка</a>
        </li>
        <li>
          <a
            href="https://jira.zyfra.com/secure/RapidBoard.jspa?rapidView=1321&projectKey=IDPPRIZM&view=detail"
            >Доска и активный спринт</a
          >
        </li>
        <li>
          <a
            href="https://jira.zyfra.com/secure/RapidBoard.jspa?rapidView=1321&projectKey=IDPPRIZM&view=planning.nodetail&issueLimit=100"
            >Бэклог спринтов и продукта</a
          >
        </li>
        <li>
          Песочница бэклога
          <a href="https://jira.zyfra.com/wiki/display/DesignSystem/Backlog.+Stage+III">Backlog. Stage III</a>
        </li>
      </ol>
    </li>
  </ol>
  <p>
    Если нужных компонентов нигде нет, что ж. Некоторые компоненты мы берём в работу, но что-то вам придётся
    добавить самостоятельно.
  </p>

  <h3>Отправьте запрос на доработку дизайн-системы</h3>
  <p>Когда стоит это делать</p>
  <ol class="disc">
    <li>Вы нашли ошибку в работе наших компонентов.</li>
    <li>Ваша доработка или улучшение компонента будет полезна многим.</li>
    <li>
      Новый компонент часто используется в проектировании интерфейсов (во всех дизайн-системах есть, а у нас
      нет).
    </li>
  </ol>
  <p>Как отправить запрос на доработку</p>
  <p>
    Статья «<a href="https://jira.zyfra.com/wiki/pages/viewpage.action?pageId=179801382"
      >Связь с командой Prizm</a
    >» (Confluence) / <a href="{{ storybookBaseUrl }}/about-prizm/contacts">Связаться с командой</a> ответит
    на все вопросы.
  </p>

  <h3>Для контрибьюторов</h3>
  <p>
    Статья «<a href="https://jira.zyfra.com/wiki/pages/viewpage.action?pageId=179801382"
      >Связь с командой Prizm</a
    >» (Confluence) / <a href="{{ storybookBaseUrl }}/how-to-work/contributing"> Сontributing</a> (сайт)
    ответит на все вопросы.
  </p>
</prizm-doc-page>
