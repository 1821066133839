<ng-container *prizmLet="toolbarService.searchVisible$ | async as searchVisible">
  <div class="prizm-navigation-menu-toolbar" *ngIf="toolbarIsVisible">
    <div class="instruments">
      <ng-container *ngTemplateOutlet="toolbarExtraTemplate"></ng-container>

      <ng-container *ngIf="toolbarService.viewMode$ | async as viewMode">
        <button
          *ngIf="toolbarConfig.hierarchyMode ?? true"
          [pseudoPressed]="viewMode === 'hierarchy'"
          [size]="'m'"
          [icon]="'files-mode'"
          [appearance]="'secondary'"
          [appearanceType]="'ghost'"
          (click)="toolbarService.changeViewMode('hierarchy')"
          prizmIconButton
        ></button>

        <button
          *ngIf="toolbarConfig.rubricatorMode"
          [pseudoPressed]="viewMode === 'rubricator'"
          [size]="'m'"
          [icon]="'editor-alfavit'"
          [appearance]="'secondary'"
          [appearanceType]="'ghost'"
          (click)="toolbarService.changeViewMode('rubricator')"
          prizmIconButton
        ></button>

        <button
          *ngIf="toolbarConfig.folderMode"
          [pseudoPressed]="viewMode === 'folder'"
          [size]="'m'"
          [icon]="'folder'"
          [appearance]="'secondary'"
          [appearanceType]="'ghost'"
          (click)="toolbarService.changeViewMode('folder')"
          prizmIconButton
        ></button>

        <button
          *ngIf="toolbarConfig.closeAll"
          [size]="'m'"
          [icon]="'chevron-down-to-double-line'"
          [appearance]="'secondary'"
          [appearanceType]="'ghost'"
          (click)="toolbarService.closeAll()"
          prizmIconButton
        ></button>

        <button
          *ngIf="toolbarConfig.search"
          [pseudoPressed]="searchVisible"
          [size]="'m'"
          [icon]="'magnifying-glass'"
          [appearance]="'secondary'"
          [appearanceType]="'ghost'"
          (click)="toolbarService.toggleSearch()"
          prizmIconButton
        ></button>
      </ng-container>
    </div>
  </div>
  <prizm-navigation-menu-search
    *ngIf="searchVisible"
    [searchDebounce]="$any(searchConfig.searchDebounce)"
    (searchChange)="toolbarService.changeSearchValue($event)"
  ></prizm-navigation-menu-search>
</ng-container>
