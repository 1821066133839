<prizm-doc-page class="info-page" header="Как добавить компонент">
  <p>
    Дизайн-система Prizm постоянно развивается, появляются новые компоненты. Вы можете внести свой вклад и
    познакомить других участников со своими наработками, предложить добавить новый компонент. Отправьте нам
    предложение, мы его рассмотрим и примем решение.
  </p>

  <h3>Зачем это нужно</h3>
  <p>
    Только вместе можно сделать хорошо. Нет смысла ждать когда всё будет готово. К этому времени всё устареет.
  </p>

  <h3>Как отправить предложение</h3>
  <p>Это зависит от состояния, в котором находится новый компонент.</p>
  <ol class="disc">
    <li><strong>Есть только идея</strong> — поставьте задачу.</li>
    <li><strong>Готов дизайн</strong> — отправьте ссылку на макет, в задаче или на почту.</li>
    <li><strong>Готов код компонента</strong> — отправьте PR.</li>
  </ol>
  <p>
    Напишите нам. В статье «<a href="https://jira.zyfra.com/wiki/pages/viewpage.action?pageId=179801382"
      >Способы связи с командой Prizm</a
    >» (Confluence) / <a href="{{ storybookBaseUrl }}/about-prizm/contacts">Связаться с командой</a> есть
    варианты как это сделать.
  </p>

  <h3>Кто разрабатывает новые компоненты</h3>
  <ol class="decimal">
    <li>Команда Prizm может взять разработку на себя.</li>
    <li>Ваша команда может самостоятельно разработать компонент и передаёт его в Prizm.</li>
  </ol>
  <p>
    Кто будет заниматься разработкой, договариваемся в процессе согласования — когда уже решено, что компонент
    войдёт в дизайн-систему Prizm. Всё зависит от загрузки, приоритетов, востребованности.
  </p>

  <h3>Как проходит согласование</h3>
  <ol class="decimal">
    <li>
      Вы отправляете информацию, напишите нам, в статье «<a
        href="https://jira.zyfra.com/wiki/pages/viewpage.action?pageId=179801382"
        >Способы связи с командой Prizm</a
      >» (Confluence) / <a href="{{ storybookBaseUrl }}/about-prizm/contacts">Связаться с командой</a> есть
      варианты как это сделать.
    </li>
    <li>Мы обсуждаем, даём обратную связь (дизайн, код и так далее).</li>
    <li>Успех.</li>
  </ol>

  <h3>Примерный план</h3>

  <h4>ЭТАП 1</h4>
  <p class="italic">Принимаем решение</p>
  <ol class="decimal">
    <li>Оцениваем популярность компонента: смотрим, есть ли еще запросы на его разработку.</li>
    <li>
      Составляем описание API и описываем, как компонент будет использоваться — на примерах, чтобы лучше
      понимать, как всё работает и зачем это нужно.
    </li>
    <li>
      Смотрим, что уже готово: если вы прислали PR, проверяем его, смотрим, есть ли в нём примеры
      использования и тесты.
    </li>
  </ol>
  <p>
    После этого этапа становится понятно, что это за компонент и нужно ли его добавлять в дизайн-систему.
    Договариваемся, кто его разрабатывает — вы или команда Prizm.
  </p>

  <h4>ЭТАП 2</h4>
  <p class="italic">Разрабатываем и обсуждаем новый компонент</p>
  <ol class="decimal">
    <li>Разрабатываем компонент в дизайне и в коде.</li>
    <li>Приглашаем сообщество к обсуждению.</li>
  </ol>

  <h4>ЭТАП 3</h4>
  <p class="italic">Проверяем готовый компонент и проводим ревью</p>
  <ol class="decimal">
    <li>Проводим ревью кода и устраняем все замечания.</li>
    <li>Команда дизайн-системы подтверждает, что компонент можно включить в состав основной библиотеки.</li>
  </ol>

  <h4>ЭТАП 4</h4>
  <p class="italic">Публикуем релиз</p>
  <ol class="decimal">
    <li>Собираем релизный пакет для выкладки.</li>
    <li>Публикуем информацию о релизе.</li>
  </ol>
  <p><strong>Готово!</strong> Компонент включён в библиотеку, можно использовать.</p>

  <h3>Зачем нужно согласование</h3>
  <p>
    Мы хотим сделать понятную процедуру с чёткими этапами, чтобы все участники разработки компонентов
    понимали, что происходит и что будет дальше. А заодно:
  </p>
  <ol class="disc">
    <li>Получаем качественный код и документацию.</li>
    <li>Поддерживаем семантическое версионирование всех изменений.</li>
    <li>Контролируем время на разработку новых предложений.</li>
    <li>Следим за тем, кто и чем пользуется.</li>
    <li>Храним все предложения о разработке новых компонентов в одном месте.</li>
    <li>Снижаем количество уровней принятия решений.</li>
  </ol>
  <p class="italic">Со временем у нас появится инструкция с необходимыми шагами и комментариями.</p>
</prizm-doc-page>
