<prizm-dropdown-host
  class="dropdown-host"
  [(isOpen)]="openDropdown"
  [content]="dropdown"
  prizmTheme
  prizmDropdownHostWidth="100%"
>
  <button
    class="dropdown"
    *ngIf="data.length > 0"
    [class.dropdown_active]="openDropdown"
    [prizmHint]="data[currentScreenIdx]?.title"
    [prizmHintCanShow]="prizmIsTextOverflow(title)"
    (click)="openDropdown = !openDropdown"
  >
    <div class="screen">
      <!--      <prizm-icon-->
      <!--        class="screen__icon"-->
      <!--        [iconClass]="data[currentScreenIdx]?.icon || 'files-folder'"-->
      <!--      ></prizm-icon>-->
      <prizm-icons-full
        class="screen__icon"
        [name]="data[currentScreenIdx]?.icon || iconFilesFolder.name"
      ></prizm-icons-full>
      <span class="screen__title" #title>
        {{ data[currentScreenIdx]?.title }}
      </span>
    </div>
    <prizm-icons-full class="dropdown__icon" [name]="iconAngleRight.name"></prizm-icons-full>
  </button>
</prizm-dropdown-host>

<ng-template #dropdown>
  <prizm-data-list class="screen-list">
    <div
      class="screen-list__item"
      *ngFor="let item of data; let i = index"
      [class.selected]="i === currentScreenIdx"
      [prizmHint]="item?.title"
      [prizmHintCanShow]="prizmIsTextOverflow(title)"
      (click)="changeScreen(i)"
    >
      <div class="screen">
        <prizm-icons-full class="screen__icon" [name]="item?.icon || iconFilesFolder.name"></prizm-icons-full>
        <span class="screen__title" #title>
          {{ item?.title }}
        </span>
      </div>
    </div>
  </prizm-data-list>
</ng-template>
