<div class="t-bg-toggle" [tuiMode]="null">
  <ng-template #tooltip>
    {{ texts[0] }}
    <a tuiLink tuiMode="onDark" routerLink="/directives/mode">
      <code>tuiMode</code>
    </a>
  </ng-template>
  <ng-container *ngIf="isMobile; else desktop">
    <tui-select
      class="t-mode"
      tuiTextfieldSize="s"
      [tuiTextfieldCleaner]="true"
      [tuiHintContent]="tooltip"
      [formControl]="modeControl"
    >
      tuiMode
      <tui-data-list-wrapper *tuiDataList [items]="items"></tui-data-list-wrapper>
    </tui-select>
  </ng-container>
  <ng-template #desktop>
    tuiMode:
    <tui-tooltip describeId="form" [content]="tooltip"></tui-tooltip>
    <div class="t-group" tuiGroup [collapsed]="true">
      <tui-radio-block
        class="tui-group__auto-width-item"
        size="s"
        nativeId="form"
        [item]="null"
        [hideRadio]="true"
        [formControl]="modeControl"
      >
        null
      </tui-radio-block>
      <tui-radio-block
        class="tui-group__auto-width-item"
        item="onDark"
        size="s"
        nativeId="form"
        [hideRadio]="true"
        [formControl]="modeControl"
      >
        onDark
      </tui-radio-block>
      <tui-radio-block
        class="tui-group__auto-width-item"
        item="onLight"
        size="s"
        nativeId="form"
        [hideRadio]="true"
        [formControl]="modeControl"
      >
        onLight
      </tui-radio-block>
    </div>
  </ng-template>
  <tui-checkbox-labeled class="t-checkbox" size="m" [(ngModel)]="opaque">
    {{ texts[1] }}
  </tui-checkbox-labeled>
</div>
<div
  class="t-wrapper"
  #wrapper
  [class.t-wrapper_dark]="modeControl.value === 'onDark'"
  [class.t-wrapper_gray]="modeControl.value === 'onLight'"
  [class.t-wrapper_transparent]="!opaque"
>
  <div class="t-content" id="demoContent" #content>
    <form class="t-form" *ngIf="testForm" [formGroup]="testForm">
      <div class="t-input-wrapper">
        <ng-container [ngTemplateOutlet]="template"></ng-container>
      </div>
      <button class="t-button" tuiButton type="button" size="s" [iconRight]="icon" (click)="toggleDetails()">
        {{ texts[2] }}
      </button>
      <ng-template #icon>
        <tui-svg class="t-icon" src="tuiIconChevronDown" [class.t-icon_rotated]="expanded"></tui-svg>
      </ng-template>
      <tui-expand class="t-expand" [expanded]="expanded">
        <ng-template>
          <pre class="t-value">Form data: {{ testForm.value | json }}</pre>
          <div class="t-form-controls" tuiGroup>
            <tui-select
              class="t-select"
              tuiTextfieldSize="s"
              [ngModel]="updateOn"
              [ngModelOptions]="{standalone: true}"
              (ngModelChange)="updateOnChange($event)"
            >
              updateOn
              <tui-data-list-wrapper *tuiDataList [items]="updateOnVariants"></tui-data-list-wrapper>
            </tui-select>
            <button class="tui-group__auto-width-item" tuiButton type="reset" size="s">Reset</button>
            <button class="tui-group__auto-width-item" tuiButton type="submit" size="s">Submit</button>
          </div>
        </ng-template>
      </tui-expand>
    </form>
    <ng-content></ng-content>
  </div>
  <button
    class="t-resizer"
    tabindex="-1"
    (tuiDragStart)="onDragStart($event)"
    (tuiDragContinues)="onDragContinues($event)"
    (tuiDragEnd)="onDragEnd()"
  >
    <span class="t-resizer-text" #resizerText></span>
  </button>
</div>
