import { NgModule } from '@angular/core';
import { PrizmPrimitiveYearPickerComponent } from './primitive-year-picker.component';

/**
 * @deprecated
 * use standalone
 * */
@NgModule({
  imports: [PrizmPrimitiveYearPickerComponent],
  exports: [PrizmPrimitiveYearPickerComponent],
})
export class PrizmPrimitiveYearPickerModule {}
