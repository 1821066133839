/**
 * @deprecated
 */
export const enum PrizmSupportColor {
  Mustard = `support-01`,
  Texas = `support-02`,
  Tan = `support-03`,
  Salmon = `support-04`,
  Sienna = `support-05`,
  Bittersweet = `support-06`,
  Pinkie = `support-07`,
  Charm = `support-08`,
  Amethist = `support-09`,
  Helio = `support-10`,
  Lilac = `support-11`,
  Malibu = `support-12`,
  Havelock = `support-13`,
  Picton = `support-14`,
  Mint = `support-15`,
  Fountain = `support-16`,
  Puertorico = `support-17`,
  Bay = `support-18`,
  Forest = `support-19`,
  York = `support-20`,
  Feijoa = `support-21`,
}
