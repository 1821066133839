import { NgModule } from '@angular/core';
import { PrizmInputIconButtonComponent } from './input-icon-button.component';

/**
 * @deprecated
 * use standalone
 * */
@NgModule({
  imports: [PrizmInputIconButtonComponent],
  exports: [PrizmInputIconButtonComponent],
})
export class PrizmInputIconButtonModule {}
