<div
  class="item"
  [class.selected]="
    value | prizmCallFunc : treeSelectSelectedDirective.isSelected.bind(treeSelectSelectedDirective) | async
  "
>
  <button
    *ngIf="children.length"
    [icon]="iconTemplate"
    [class.opened]="opened"
    (click)="onToggle($event)"
    prizmIconButton
    appearanceType="ghost"
    appearance="primary"
    size="s"
  ></button>

  <ng-template #iconTemplate>
    <prizm-icons-full [name]="'chevron-mini-right'" [size]="24"></prizm-icons-full>
  </ng-template>

  <div class="value">
    <ng-content></ng-content>
  </div>
</div>

<div class="children">
  <ng-container #viewContainerRef></ng-container>
</div>
