<prizm-doc-page class="info-page" header="Связь с командой Prizm">
  <h1>Способы связи</h1>

  <h2>Введение</h2>

  <p>Для связи с командой Prizm можно воспользоваться следующими способами:</p>
  <ol class="decimal">
    <li>
      С помощью <a href="https://github.com/zyfra/Prizm/issues">Issue</a> - подробнее способ описан на
      странице
      <a href="{{ storybookBaseUrl }}/how-to-work/contributing">Сontributing</a>
    </li>
  </ol>
  <p>
    Скорость реакции и сроки реакции указаны на странице
    <a href="{{ storybookBaseUrl }}/about-prizm/service-level-agreement">"SLA – соглашение об уровне услуг"</a
    >.
  </p>
  <p>
    Регистрация запросов производится круглосуточно. Рабочее время 9.00 до 18.00 (время московское), перерыв с
    12.00 до 13.00, в рабочие дни. Запросы, поступившие в нерабочее время, обрабатываются в следующий рабочий
    день.
  </p>
  <div class="warning-introduction">
    <p><strong>Важно!</strong></p>
    <p>На каждую задачу – свое письмо.</p>
  </div>

  <h2>Как это работает</h2>

  <p>При получении issue на github срабатывает процесс:</p>
  <ol class="disc">
    <li>Создаётся задача в Jira</li>
    <li>В зависимости от первого слова в теме письма тикет получает свой тип (bug, feature и т.д.)</li>
    <li>Задача в Jira назначается на исполнителя</li>
    <li>Новая задача линкуется к эпику в Jira</li>
    <li>
      Минимум один раз в три рабочих дня мы проверяем и разбираем новые задачи, которые поступают нам через
      почту и issue из репозитория
    </li>
    <li>
      После разбора задач, каждой задачи присваивает статус, которая команда считает верным, задача
      планируется в работу.
    </li>
  </ol>

  <h2>Шаблон письма</h2>

  <p>Правильное заполнение темы и содержимого повышает скорость обработки.</p>
  <ol class="disc">
    <li>Тему нужно начинать с одного из типа тикетов.</li>
    <li>Писать тип этикета в теме нужно на англиском языке.</li>
    <li>Остальную часть темы можно на русском.</li>
    <li>В теме важно отразить суть.</li>
    <li>Используйте здравый смысл и критическое мышление.</li>
    <li>Если есть сложности с определением типа тикета, то выбирайте «question».</li>
    <li>Обязательно укажите как с вами связаться. Оптимальнее всего указать электронную почту и telegram.</li>
  </ol>
  <table class="table" prizmTable size="l">
    <thead>
      <tr prizmThGroup>
        <th prizmTh>Тип тикета</th>
        <th prizmTh>Расшифровка</th>
      </tr>
    </thead>

    <tbody [data]="labelTypes" prizmTbody>
      <ng-container *prizmRow="let item of labelTypes; let i = index">
        <tr prizmTr>
          <td prizmTd>{{ item.name }}</td>
          <td prizmTd>{{ item.description }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>

  <h2>Пример письма</h2>

  <p>
    <strong>Пример темы:</strong> question. У нас есть самописная диаграмма Санкея. Хотим её передать вам на
    поддержку.
  </p>
  <p><strong>Содержимое письма</strong></p>
  <ol>
    <li>
      <strong>Статус</strong> (по вашему мнению)
      <ol>
        <li>🔴 Высокий</li>
        <li>🟠 Средний</li>
        <li>🟡 Низкий</li>
      </ol>
    </li>
    <li>
      <strong>Описание проблемы</strong>
      <ol>
        <li>
          Мы использовали свою диаграмму Санкея. Разработали её. Сейчас нет возможности её поддерживать. Она
          соответствует вашим требованиям к компоненту. Узнали, что такие же требования к самой диаграмме есть
          у других команд.
        </li>
      </ol>
    </li>
    <li>
      <strong>Предложение решения</strong> (если есть)
      <ol>
        <li>Мы хотим передать её вам на поддержку. Сможете её принять?</li>
      </ol>
    </li>
    <li>
      <strong>Контакты</strong>
      <ol>
        <li>name&#64;name.com</li>
        <li>&#64;Telegram</li>
      </ol>
    </li>
    <li>
      <strong>Дополнительная информация и ссылки</strong>
      <ol class="examleLinks">
        <li>Можем обсудить это. Наша почта и телега в контактах. Во вложении ФТ.</li>
        <li><a href="">Ссылка 1</a> - это репозиторий</li>
        <li><a href="">Ссылка 2</a> - это видео работы</li>
        <li>
          <a href="">Ссылка 3</a> – это видео с котиками, чтобы не было стресса от просмотра нашего кода.
        </li>
      </ol>
    </li>
  </ol>
</prizm-doc-page>
