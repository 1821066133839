<prizm-dropdown-host
  class="z-hosted"
  [(isOpen)]="open"
  [content]="dropdown"
  [closeByEsc]="true"
  prizmDropdownHostWidth="100%"
>
  <ng-container *ngFor="let item of items; let idx = index">
    <ng-container *ngIf="idx === currentIdx">
      <ng-container *ngTemplateOutlet="item.template as data; context: context"></ng-container>
    </ng-container>
  </ng-container>
</prizm-dropdown-host>

<ng-template #dropdown>
  <prizm-data-list class="block">
    <ng-container *ngIf="items?.length">
      <prizm-listing-item
        *ngFor="let item of items; let idx = index"
        [selected]="idx === currentIdx"
        (click)="select(idx)"
      >
        {{ item.name }}
      </prizm-listing-item>
    </ng-container>
  </prizm-data-list>
</ng-template>

<ng-template #buttonLeft>
  <button
    [interactive]="true"
    (prizmAfterViewInit)="setListener($event.nativeElement)"
    prizmInputIconButton="temp-chevrons-dropdown-small"
  ></button>
</ng-template>
