<div class="sorter" *ngIf="isSortable && table; else content" type="button">
  <ng-container [ngTemplateOutlet]="content"></ng-container>
  {{ table.change$ | async }}
  <span class="sort__block" [class.sort__block_active]="isCurrent">
    <span class="sorter__number" [class.hidden]="!(count > 1 && num)">{{ num }}</span>
    <button
      class="sorter__icon"
      [icon]="$any(icon$ | async)"
      [appearance]="isCurrent ? 'primary' : 'secondary'"
      (click)="updateSorter($event)"
      prizmIconButton
      appearanceType="ghost"
      size="s"
    ></button>
  </span>
</div>
<ng-template #content>
  <div class="cell"><ng-content></ng-content></div>
</ng-template>
<div class="resize-bar" *ngIf="resizable" (prizmResized)="onResized($event)"></div>
