<button class="chips-list__item" [disabled]="disabled" [class.chips-list__item_selected]="selected">
  <div
    class="chips-name"
    #div
    [prizmHintHost]="el.nativeElement"
    [prizmHintDirection]="hintDirection"
    [prizmHintOnOverflow]="hintText"
  >
    <ng-content></ng-content>
  </div>
  <button class="chips-cancel" *ngIf="deletable" [disabled]="disabled" (click)="deleted.emit($event)">
    <prizm-icons [size]="16" name="xmark-mini"></prizm-icons>
  </button>
</button>
