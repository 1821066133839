/**
 * @deprecated
 */
export const enum PrizmBaseColor {
  Primary = `primary`,
  Secondary = `secondary`,
  Success = `success`,
  Danger = `danger`,
  Warning = `warning`,
}
