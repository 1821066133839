<div class="block" [prizmMutationObserverHost]="el.nativeElement" (prizmMutationObserver)="updateWidth()">
  <ng-content></ng-content>
  <ng-template #temp>
    <div
      class="prizm-dropdown-host-modal"
      [ngStyle]="dropdownStyles"
      [ngClass]="dropdownClasses"
      [id]="prizmDropdownHostId"
      [attr.position]="position$ | async"
      [childrenZones]="[zoneEventService]"
      (zoneOutsideEvent)="outsideClick()"
      (prizmAfterViewInit)="addListenerItems($event.nativeElement); reCalculatePositions()"
      (prizmOnDestroy)="removeListenerItems($event.nativeElement)"
      prizmTheme
      prizmZoneEvent
      zoneEventName="click"
    >
      <ng-container
        *polymorphOutlet="content; context: { custom: prizmDropdownCustomContext }"
      ></ng-container>
    </div>
  </ng-template>
</div>
