<div class="header">
  <ng-content select="[header]"></ng-content>
</div>
<ng-container *polymorphOutlet="content as data">
  <div class="body">
    <prizm-scrollbar
      *ngIf="scroll !== 'none'; else simpleTemplate"
      [visibility]="$any(scroll)"
      [style.min-height]="minDropdownHeight"
      [style.max-height]="maxDropdownHeight"
    >
      <ng-container *ngTemplateOutlet="simpleTemplate"></ng-container>
    </prizm-scrollbar>

    <ng-template #simpleTemplate>
      <ng-content></ng-content>
    </ng-template>
  </div>
</ng-container>

<div class="footer">
  <ng-content select="[footer]"></ng-content>
</div>
