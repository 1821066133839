<div class="item" [class.selected]="value | prizmCallFunc : isSelected$ | async">
  <div class="icon-with-checkbox" [attr.data--checkbox-position]="checkboxPosition">
    <prizm-checkbox
      *ngIf="isSelected(); else intermediateOtUnselectedTemplate"
      [class.readonly]="true"
      [checked]="true"
    ></prizm-checkbox>
    <ng-template #intermediateOtUnselectedTemplate>
      <prizm-checkbox
        *ngIf="hasSelectedChildren(); else unselectedTemplate"
        [checked]="false"
        [indeterminate]="true"
      ></prizm-checkbox>
    </ng-template>
    <ng-template #unselectedTemplate>
      <prizm-checkbox [checked]="false"></prizm-checkbox>
    </ng-template>

    <button
      class="icon-toggle"
      *ngIf="children.length"
      [icon]="iconTemplate"
      [class.opened]="opened"
      (click)="onToggle($event)"
      prizmIconButton
      appearanceType="ghost"
      appearance="primary"
      size="s"
    ></button>
  </div>

  <ng-template #iconTemplate>
    <prizm-icons-full [name]="'chevron-mini-right'" [size]="24"></prizm-icons-full>
  </ng-template>

  <div class="value" [class.has-children]="children.length > 0">
    <ng-content></ng-content>
  </div>
</div>

<div class="children">
  <ng-container #viewContainerRef></ng-container>
</div>
