// TODO this temp set will be removed after 6.x version
export const PRIZM_ICONS_TEMP_SET = {
  'add-shape-square-plus': 'temp-add-shape-square-plus',
  'alerts-alarm-light': 'temp-alerts-alarm-light',
  'alerts-circle-mini': 'temp-alerts-circle-mini',
  'alerts-circle-mini-1': 'temp-alerts-circle-mini',
  'arrows-corner-chek-in': 'temp-arrows-corner-chek-in',
  'arrows-down-double-top-2': 'temp-arrows-down-double-top-2',
  'arrows-up-double-top-2': 'temp-arrows-up-double-top-2',
  'audio-artist': 'temp-audio-artist',
  'badges-tag-multiple': 'temp-badges-tag-multiple',
  'chevrons-dropdown-small': 'temp-chevrons-dropdown-small',
  'chevrons-menu-left-small': 'temp-chevrons-menu-left-small',
  'date-inform-delete': 'temp-date-inform-delete',
  'editor-discrete-set': 'temp-editor-discrete-set',
  'editor-discrete-set-1': 'temp-editor-discrete-set',
  'editor-wrap-text': 'temp-editor-wrap-text',
  // WRONG OLDER NAME with cyrillic symbol
  'files-import1с': 'temp-files-import-1c',
  // RIGHT NAME FIX
  'files-import1c': 'temp-files-import-1c',
  'location-localization-down-list': 'temp-location-localization-down-list',
  'network-library': 'temp-network-library',
  'poi-difference': 'temp-poi-difference',
  'product-grade-according-to-specification': 'temp-product-grade-according-to-specification',
  'selection-checkbox-blank-circle': 'temp-selection-checkbox-blank-circle',
  'selection-checkbox-blank': 'temp-selection-checkbox-blank',
  'selection-checkbox-marked-circle-chanel': 'temp-selection-checkbox-marked-circle-chanel',
  'selection-checked-1': 'temp-selection-checked-1',
  'selection-checked-2': 'temp-selection-checked-2',
  'selection-checked': 'temp-selection-checked',
  'selection-radio-off': 'temp-selection-radio-off',
  'selection-radio-on': 'temp-selection-radio-on',
  'selection-toggle-left': 'temp-selection-toggle-left',
  'selection-toggle-right': 'temp-selection-toggle-right',
  'selection-unchoice': 'temp-selection-unchoice',
  'shapes-checkbox-intermediate': 'temp-shapes-checkbox-intermediate',
  'signal-cast': 'temp-signal-cast',
  'signal-rss-box': 'temp-signal-rss-box',
  'social-tab': 'temp-social-tab',
  'sort-version': 'temp-sort-version',
  'tools-solver': 'temp-tools-solver',
};

export const PRIZM_OLD_ICONS_REPLACE_SET = {
  ...PRIZM_ICONS_TEMP_SET,
  'account-down-list-open': 'user-chevron',
  'account-down-list': 'user-chevron',
  'account-search': 'magnifying-glass',
  'account-setting-1': 'user-gear',
  'account-shield-1': 'user-shield',
  'add-cart-plus': 'cart-plus',
  'add-map-marker-plus': 'location-plus',
  'add-phone': 'phone-plus',
  'alerts-circle': 'circle-exclamation-empty',
  'alerts-false_alerts1': 'box-open-arrow-down',
  'alerts-false_alerts2': 'triangle-cross-empty',
  'alerts-help-circle-outline': 'circle-question-empty',
  'alerts-info-circle-fill': 'circle-info-fill',
  'alerts-inform': 'circle-info-empty',
  'arrows-autorenew-1': 'arrows-rotate-v',
  'arrows-autorenew': 'arrows-rotate-v',
  'arrows-back': 'arrow-left',
  'arrows-cached-1': 'arrows-rotate-h',
  'arrows-down-2': 'arrow-down-to-line',
  'arrows-next': 'arrow-right',
  'audio-bookmark-music': 'bookmark-music',
  'bookmarks-book-open-re-1': 'book-open-re-arrow-left',
  'cancel-block': 'ban',
  'cancel-delete-content': 'delete-content',
  'charts-arc-1': 'pie-simple',
  'charts-elevation-decline': 'line-multiple-y-axis-x',
  'charts-google-analytics': 'bar-columns-axis-x',
  'charts-pie-1': 'pie-pentagon',
  'chevron-middle': 'chevron-mini-right',
  'chevrons-menu-left': 'triangle-left',
  'chevrons-menu-right': 'triangle-right',
  'devices-laptop-off-1': 'laptop-slash',
  'editor-brace-right': 'square-bracket-right',
  'editor-curly-brace-right': 'square-bracket-curly-right',
  'editor-down-1': 'editor-down',
  'editor-horizontal-align-center-': 'align-center-v',
  'editor-percent-1': 'square-percent',
  'editor-right-1': 'editor-right',
  'editor-vertical-align-center1': 'align-center-h',
  'editor-add-comment': 'bubble-message-add',
  'files-correct': 'file-arrow',
  'files-export-1': 'square-arrows-curve-up-right',
  'files-find-1': 'file-magnifying-glass',
  'files-folder-1': 'folder',
  'files-folder-add': 'folder-plus',
  'files-folder-open-1': 'folder-open',
  'files-import-1': 'square-arrows-curve-right-down',
  'files-plus': 'file-plus',
  // NAME with mistake (cyrillic symbols)
  'files-просмотр': 'file-eye',
  // Right name for NAME with mistake
  'files-view-document': 'file-eye',

  'location-navigation-1': 'location-arrow',
  'location-pin-1': 'thumbtack',
  'network-server-1': 'server-network',
  'cargo-box': 'container-v2',
  'poi-achive-Mir': 'database-check',
  'poi-cancel-sample-reject': 'vial-rotate-left',
  'poi-factory pipe': 'factory-pipe',
  'poi-flask-2': 'vial-flask',
  'poi-medicine': 'snake-cup',
  'poi-reject-the-sample': 'vial-xmark',
  'poi-respirator-2-1': 'respirator-v2',
  'shapes-shape-plus-1': 'square-circle-plus-triangle-fill',
  'sort-search-mistake': 'magnifying-glass-exclamation',
  'tools-keyboard-close-1': 'keyboard-close',
  'transport-truck-1': 'railcar-side-view',
  'transport-truck-2': 'railcar-hopper-side-view',
  'transport-truck-3': 'truck-garbage-side-view',
  'transport-truck-4': 'tankcar-oil-side-view',
  'transport-truck-5': 'concrete-mixer-side-view',
  'vectors-combine-1': 'object-union',
  'vectors-default-24': 'default-ico',
  'bookmarks-book': 'book-closed',
  'bookmarks-book-open-variant': 'book-open',
  'bookmarks-book-open-page-variant': 'book-open-with-page',
  'bookmarks-book-open-re': 'book-open-re-arrow-left',
  'bookmarks-book-close-re': 'book-open-re-arrow-right',
  'bookmarks-book-open-plus': 'book-open-plus',
  'bookmarks-book-open': 'book-open-text',
  'book-check': 'book-check',
  'bookmarks-bookmark': 'bookmark',
  'bookmarks-bookmark-check': 'bookmark-check',
  'bookmarks-bookmark-plus': 'bookmark-plus',
  'add-bookmark-plus': 'bookmark-plus',
  'bookmarks-bookmark-remove': 'bookmark-cross',
  'bookmarks-bookmark-remove-2': 'bookmark-cross',
  'bookmarks-bookmark-music': 'bookmark-music',
  'bookmarks-bookmark-fill': 'bookmark-fill',
  'bookmarks-bookmark-check-fill': 'bookmark-check-fill',
  'bookmarks-bookmark-plus-fill': 'bookmark-plus-fill',
  'bookmarks-bookmark-remove-fill': 'bookmark-cross-fill',
  'bookmarks-bookmark-music-fill': 'bookmark-music-fill',
  fire: 'fire',
  gas: 'gas',
  burn: 'burn',
  snowflake: 'snowflake',
  freeze: 'snowflake-up-to-bracket',
  'poi-nuclear-station': 'radiation',
  nuclear: 'radiation',
  'poi-steam': 'steam',
  'poi-fan': 'fan',
  'poi-plus': 'medical',
  'poi-coal': 'carbon',
  'poi-flask-5': 'vial',
  'vial-plus': 'vial-plus',
  'poi-reject': 'vial-xmark',
  sample: 'vial-rotate-left',
  'poi-cancel': 'vial-rotate-left',
  reject: 'vial-rotate-left',
  'sample-templates-zql': 'sample-templates-zql',
  'poi-flask-4': 'flask',
  'poi-flask-3': 'vial-flask',
  'poi-flask-1': 'flask-round-potion',
  medical: 'snake-cup',
  'poi-separator': 'separator',
  'poi-gas-balloon': 'balloon',
  'poi-respirator': 'respirator-v1',
  'poi-respirator-2.1': 'respirator-v2',
  'poi-respirator-4': 'respirator-v3',
  'poi-respirator-5': 'respirator-v4',
  'poi-respirator-3': 'respirator-v5',
  'poi-respirator-2': 'respirator-v6',
  'material-drop': 'oil-droplet',
  'material-drop-dots': 'oil-droplet',
  'material-drop-ok': 'oil-droplet-chek',
  'poi-ni-fi': 'ni-fi-logo',
  'material-drop-direction': 'oil-droplet-arrows-all',
  'material-drop-cycle': 'oil-droplet-arrows-repeat-h',
  'poi-pipe-sensor': 'pipe-sensor',
  'poi-pipeline': 'pipeline-v1',
  'poi-strit': 'pipeline-v2',
  'poi-pipe-valve': 'pipe-valve',
  'pipe-slash': 'pipe-slash',
  'poi-barley': 'seedling',
  'poi-ecopost-circle': 'seedling-circle',
  'poi-ecopost-rectangle': 'seedling-rectangle',
  'poi-oil-factory': 'oil-barrel',
  'poi-fuel': 'oil-canister',
  'poi-gas-station': 'gas-pump',
  'poi-heat-recovery-boiler': 'boiler',
  'poi-water-pump': 'water-pump',
  'poi-heat-exchanger': 'exchanger',
  'poi-pump': 'pump',
  'poi-pump-drop': 'pump-triangle-down',
  'poi-crane': 'crane',
  'poi-high-speed-reduction-cooling': 'speed-reduction-cooling',
  'poi-steam-boiler': 'steam-boiler',
  'poi-electrics': 'electrics',
  'poi-boiler-hot-water-gas-and-oil': 'boiler-hot-water-gas-and-oil',
  'poi-generator': 'generator',
  'poi-steam-turbine': 'steam-turbine',
  'poi-gear-cooling': 'gear-cooling',
  'poi-gas-monitoring': 'gas-monitoring',
  'poi-water-heater': 'water-heater',
  'poi-press': 'press',
  'equipment-zql': 'equipment-zql',
  'poi-oil-storage': 'storage',
  'poi-factory-pipes': 'factory-pipes',
  'poi-factory': 'factory-pipe',
  pipe: 'factory-pipe',
  'poi-oil-field': 'oil-field',
  'poi-tank': 'tank',
  'poi-oil-tank': 'oil-tank',
  'poi-fuel-oil-tank': 'fuel-oil-tank',
  'poi-lng-storage': 'lng-storage',
  'cargo-container': 'container-v1',
  'cargo-box,': 'container-v2',
  'cargo-box-2': 'container-v2',
  cargo: 'containers',
  loading: 'cart-flatbed-container',
  'shapes-cube': 'cube-fill',
  'shapes-cube-outline': 'cube-empty',
  'shapes-cube-3': 'cubes',
  'poi-early-version': 'early-version',
  'location-compass': 'compass',
  'location-crosshairs-gps': 'crosshairs-dot',
  'location-crosshairs': 'crosshairs-simple',
  'location-language': 'globe',
  'world-triangle': 'globe',
  down: 'arrow-bold-down',
  'social-globe-2': 'earth',
  'location-map-marker': 'location-dot',
  'location-map-marker-plus': 'location-plus',
  'location-map-marker-minus': 'location-minus',
  'location-map-marker-off': 'location-slash',
  'location-person-pin-circle': 'location-user',
  'location-map': 'map',
  'location-pin': 'thumbtack',
  'location-pin-2': 'map-pin',
  'location-navigation': 'location-arrow',
  'location-map-2': 'map-base',
  account: 'user',
  'user-triangle-left': 'user',
  'account-group': 'user-group',
  'account-remove': 'user-cross',
  'account-setting': 'users-gear',
  'account-switch': 'users-arrow-right',
  'account-worker': 'user-worker',
  'account-shield': 'shield',
  'account-key': 'users-key',
  'account-edit': 'user-pen',
  'account-done': 'user-check',
  'account-location': 'user-location',
  'account-minus': 'user-minus',
  'account-plus': 'user-plus',
  'account-role': 'users',
  'account-multiple-plus': 'users-plus',
  'account-multiple-shield': 'users-location',
  'account-multiple-minus': 'users-minus',
  'users-arrow-up-zql': 'users-arrow-up-zql',
  'account-contact-sync': 'user-arrows-swap',
  'account-circle': 'user-circle',
  'account-card-details': 'user-card',
  'premises-zql': 'premises-zql',
  'alerts-help-fill': 'circle-question-fill',
  'alerts-help-box-fill': 'square-question-fill',
  'alerts-help-circle-outline,': 'circle-question-empty',
  'alerts-circle-mini': 'circle-exclamation-empty',
  'alerts-help-box': 'square-question-empty',
  'alerts-question-mark': 'question',
  alarm: 'exclamation',
  'alerts-circle-fill': 'circle-exclamation-fill',
  'alerts-box-fill': 'square-exclamation-fill',
  'alerts-warning': 'triangle-exclamation-fill',
  'alerts-octagon-fill': 'hexagon-exclamation-fill',
  'alerts-decagram-fill': 'decagram-exclamation-fill',
  'alerts-circle,': 'circle-exclamation-empty',
  'alerts-square': 'square-exclamation-empty',
  alerts: 'triangle-exclamation-empty',
  'alerts-octagon': 'hexagon-exclamation-empty',
  'alerts-decagram': 'decagram-exclamation-empty',
  'alerts-mark': 'exclamation',
  'alerts-info-circle-fill,': 'circle-info-fill',
  'alerts-inform-fill-mini': 'circle-info-fill',
  'alerts-info-square-fill': 'square-info-fill',
  'alerts-info-triangle-fill': 'triangle-info-fill',
  'alerts-inform,': 'circle-info-empty',
  'alerts-inform-mini': 'circle-info-empty',
  'date-inform-add': 'circle-info-empty',
  'alerts-info-square': 'square-info-empty',
  'alerts-info-triangle': 'triangle-info-empty',
  'alerts-information-variant': 'info',
  'success-circle-fill': 'circle-check-fill',
  'success-decagram-fill': 'decagram-check-fill',
  'selection-success': 'decagram-check-fill',
  'success-circle-outline': 'circle-check-empty',
  'selection-choice': 'circle-check-empty',
  'selection-checkbox-marked-circle': 'temp-selection-checkbox-marked-circle-chanel',
  'selection-checked': 'circle-xmark',
  'success-decagram-outline': 'decagram-check-empty',
  'selection-check-simple': 'check',
  'selection-check-all': 'check-double',
  'alerts-bell-fill': 'bell-fill',
  'alerts-bell-ring-fill': 'bell-ring-fill',
  'alerts-bell': 'bell',
  'alerts-bell-ring': 'bell-ring',
  'alerts-bell-off': 'bell-slash',
  'add-bell-plus': 'bell-plus',
  'alerts-flag-fill': 'flag-fill',
  'alerts-flag': 'flag',
  'alerts-flag-fill-red': 'flag-fill-red',
  'alerts-info-add-incongruity': 'triangle-plus-empty',
  'alerts-false-alerts1': 'triangle-cross-empty',
  'arrows-chevron-left': 'angle-left',
  'arrows-chevron-right': 'angle-right',
  'badges-code': 'code-simple',
  'badges-code-download': 'code-download',
  'chevrons-left': 'chevron-left',
  'chevrons-right': 'chevron-right',
  'chevrons-down': 'chevron-down',
  'chevrons-up': 'chevron-up',
  'chevrons-left-mini': 'chevron-mini-left',
  'chevrons-right-mini': 'chevron-mini-right',
  'chevrons-close-all': 'chevron-down-to-double-line',
  'chevrons-open-all': 'chevron-right-above-double-line',
  'chevrons-double-left': 'chevrons-double-left',
  'chevrons-double-right': 'chevrons-double-right',
  'chevrons-double-down': 'chevrons-double-down',
  'chevrons-double-left-mini': 'chevrons-double-mini-left',
  'chevrons-double-right-mini': 'chevrons-double-mini-right',
  'chevrons-double-up': 'chevrons-double-up',
  'chevrons-unfold-less-horizontal': 'chevrons-inside-horizontally',
  'chevrons-unfold-less-verica': 'chevrons-inside-vertically',
  'chevrons-unfold-more-horizontal': 'chevrons-outside-horizontally',
  'chevrons-unfold-more-vertical': 'chevrons-outside-vertically',
  chevron: 'triangle-up-down',
  'chevrons-menu-left,chevrons-menu-left-small': 'triangle-left',
  'chevrons-menu-right,chevrons-dropdown-small': 'triangle-right',
  'chevrons-dropdown': 'triangle-down',
  'chevrons-dropup': 'triangle-up',
  'chevrons-input': 'triangle-up-down',
  'arrows-down': 'arrow-down',
  'arrows-arrow-right': 'arrow-right',
  'arrows-arrow-left': 'arrow-left',
  'arrows-up': 'arrow-up',
  'arrows-diagonal-arrow-left-down': 'arrow-left-down',
  'arrows-diagonal-arrow-left-up': 'arrow-left-up',
  'arrows-diagonal-arrow-right-down': 'arrow-right-down',
  'arrows-diagonal-arrow-right-up': 'arrow-right-up',
  'arrows-corner-right-up': 'arrow-turn-right-up',
  'arrows-corner-left-up': 'arrow-turn-left-up',
  'arrows-corner-left-down': 'arrow-turn-left-down',
  'arrows-corner-right-down': 'arrow-turn-right-down',
  'arrows-corner-up-left': 'arrow-turn-up-left',
  'arrows-corner-up-right': 'arrow-turn-up-right',
  'arrows-corner-down-left': 'arrow-turn-down-left',
  'arrows-corner-down-right': 'arrow-turn-down-right',
  'arrows-down-limit-1': 'arrow-down-from-line',
  'arrows-up-limit-1': 'arrow-up-from-line',
  'arrows-down-2,': 'arrow-down-to-line',
  'arrows-download': 'arrow-down-to-line',
  download: 'arrow-down-to-bracket',
  'arrows-up-2': 'arrow-up-to-line',
  'arrows-down-limit-double-1': 'arrow-down-from-double-line',
  'arrows-up-limit-double-1': 'arrow-up-from-double-line',
  'arrows-down-double-2': 'arrow-down-to-double-line',
  'arrows-up-double-2': 'arrow-up-to-double-line',
  'view-menu-arrow-up': 'arrow-up-between-lines',
  'view-menu-arrow-left': 'arrow-left-between-lines',
  'view-menu-arrow-down': 'arrow-down-between-lines',
  'view-menu-arrow-right': 'arrow-right-between-lines',
  'phone-call-missed': 'arrow-daw',
  'arrows-refresh': 'arrow-rotate-right',
  'arrows-replay': 'arrow-rotate-left',
  'finance-qr-code-auto-1': 'arrow-rotate-left',
  'finance-qr-code-auto-2': 'arrow-rotate-left',
  'files-export-arr': 'arrow-up-from-bracket',
  export: 'arrow-up-from-bracket',
  'files-import-arr': 'arrow-down-to-bracket',
  import: 'arrow-down-to-bracket',
  'account-log-in': 'arrow-left-from-bracket',
  'account-logout': 'arrow-right-from-bracket',
  'arrows-arrow-up-level': 'arrow-up-to-dotted-line',
  'arrows-autorenew-square': 'arrows-repeat-v',
  'arrows-cached': 'arrows-rotate-h',
  'finance-qr-code-auto-3': 'arrows-repeat-h',
  'arrows-autorenew-circle': 'arrows-rotate-v',
  'arrows-swap-horiz-2': 'arrow-up-arrow-down-h',
  'arrows-shuffle-variant': 'arrows-shuffle',
  'arrows-shuffle': 'arrows-cross',
  'arrows-arrow-expand-horizontal': 'arrows-left-right-to-lines',
  'arrows-arrow-expand-vertical': 'arrows-up-down-to-lines',
  'files-norm': 'arrows-direction-to-the-lines',
  'editor-ungroup': 'brackets-square',
  'arrows-collapse': 'arrows-inside',
  'arrows-expand': 'arrows-out',
  'arrows-swap-horiz': 'arrows-swap-h',
  'arrows-swap-vert': 'arrows-swap-v',
  'arrows-compare': 'arrows-compare',
  'arrows-expand-all': 'arrows-maximize',
  'arrows-all': 'arrows-all-directions',
  'arrows-collapse-four-arrows': 'arrows-minimize',
  'arrows-arrow-circle-down': 'arrow-down-in-circle-empty',
  'arrows-arrow-circle-left': 'arrow-left-in-circle-empty',
  'arrows-arrow-circle-right': 'arrow-right-in-circle-empty',
  'arrows-arrow-circle-up': 'arrow-up-in-circle-empty',
  'arrows-up-bold': 'arrow-bold-up',
  'arrows-left-bold': 'arrow-bold-left',
  left: 'arrow-bold-left',
  'arrows-down-bold': 'arrow-bold-down',
  'arrows-right-bold': 'arrow-bold-right',
  right: 'arrow-bold-right',
  'sort-asc-arr': 'arrow-down-wide-short',
  'sort-abc-ru': 'arrow-down-wide-short',
  'sort-alphabetical': 'arrow-down-wide-short',
  'sort-numeric': 'arrow-down-wide-short',
  'sort-time-up': 'arrow-down-wide-short',
  'sort-desc-arr': 'arrow-up-wide-short',
  'sort-abc-ru-up': 'arrow-up-wide-short',
  'sort-alphabetical-up': 'arrow-up-wide-short',
  'sort-numeric-re': 'arrow-up-wide-short',
  'sort-time-down': 'arrow-up-wide-short',
  'arrows-swap-vert-2': 'arrow-up-arrow-down-v',
  'editor-discrete-set': 'discrete-set',
  'editor-merge-type': 'merge',
  'files-file': 'file-empty',
  'files-document-text': 'file-filled',
  'files-document-image': 'file-image',
  'files-multi': 'file-dot',
  'files-confirmation': 'file-arrow',
  'cansel-delete-file': 'file-cross',
  'add-file-plus': 'file-plus',
  'files-file-remove': 'file-minus',
  'files-export': 'square-arrows-curve-up-right',
  'files-export-csv': 'file-arrow-up',
  'files-export-xlsx': 'file-arrow-up',
  'files-export-pdf': 'file-arrow-up',
  'files-export-sap': 'file-arrow-up',
  'files-arrow-right': 'file-up-right',
  'files-import': 'square-arrows-curve-right-down',
  'arrow-down': 'file-arrow-down',
  'files-import-csv': 'file-arrow-down',
  'files-import-xlsx': 'file-arrow-down',
  'files-import-pdf': 'file-arrow-down',
  'files-alarm': 'file-exclamation',
  'files-file-ppt': 'file-ppt',
  'files-file-pptx': 'file-pptx',
  'files-file-pdf': 'file-pdf',
  'files-file-txt': 'file-txt',
  'files-file-doc': 'file-doc',
  'files-file-docx': 'file-docx',
  'files-file-xls': 'file-xls',
  'files-file-xlsx': 'file-xlsx',
  'files-file-play': 'file-play',
  'files-file-video': 'file-camera',
  'files-find': 'file-magnifying-glass',
  'files-incorrect': 'file-cross-out',
  'files-file-close': 'file-arrow-down-left',
  'files-file-open': 'file-arrows-up-right',
  'editor-group-panel': 'folder-dot',
  'files-folder': 'folder',
  'files-folder-root-add': 'folder-underline-plus',
  'files-folder-root-remove': 'folder-underline-minus',
  'files-folder-open': 'folder-arrow-up-right',
  'files-folder-close': 'folder-arrow-down-left',
  'files-folder-delete': 'folder-cross',
  'add-folder-plus': 'folder-plus',
  'files-folder-remove': 'folder-minus',
  'files-folder-mode': 'folder-arrow-down',
  'files-in-group': 'list-tree',
  'files-mode': 'files-mode',
  'shapes-ontology-2': 'list-tree-archive',
  'shapes-ontology': 'ontology',
  'badges-clipboard': 'plane',
  'tablet-iqc-zql': 'tablet-iqc-zql',
  'files-method': 'list',
  'files-archive': 'book-stack',
  'badges-clipboard-tex': 'book-stack',
  archive: 'book-stack',
  'files-specification': 'specification',
  'files-description-properties': 'list-square',
  'files-collection-2': 'collection',
  'files-box': 'box',
  'alerts-false-alerts2': 'box-open-arrow-down',
  'sample-logs-zql': 'sample-logs-zql',
  'normdocs-zql': 'normdocs-zql',
  'specs-zql': 'specs-zql',
  'techs-zql': 'techs-zql',
  'tools-content-save': 'floppy',
  'tools-save-back': 'floppy-cross',
  'tools-save-all': 'floppy-star',
  'tools-content-save-as': 'floppy-pen',
  'tools-content-unsave': 'floppy-cross-out',
  'files-cloud': 'cloud',
  'files-cloud-check': 'cloud-check',
  'files-cloup-upload': 'cloud-arrow-up-inside',
  'network-cloud-upload': 'cloud-arrow-up',
  'network-cloud-download': 'cloud-arrow-down',
  'files-word-box': 'square-word',
  'files-excel-box': 'square-excel',
  'files-powerpoint': 'square-powerpoint',
  'importers-zql': 'importers-zql',
  'charts-signal': 'bar-basic',
  'charts-histogram-chart-dashed-2': 'bar-stacked',
  'charts-bar-stacked': 'bar-stacked-axis',
  'charts-histogram': 'histogram-axis',
  'charts-bar': 'bar-axis',
  'charts-histogram-chart-dashed': 'bar-histogram',
  'charts-poll': 'bar-columns',
  'charts-poll-box': 'bar-square',
  'charts-arc': 'pie-simple',
  'charts-pie-2': 'pie-several-parts',
  'charts-pie': 'pie-pentagon',
  'charts-chart-donut-variant': 'pie-line-nested',
  'charts-donut': 'pie-line',
  'charts-smart-trend': 'line-axis',
  'charts-discharge': 'line-axis-arrow-down',
  'charts-areaspline': 'line-multiple-y-axis',
  'charts-elevation-rise': 'line-multiple-y-axis-x',
  'charts-line-variant': 'line-axis-x',
  'charts-iqc-zql': 'charts-iqc-zql',
  'calibration-characteristics-zql': 'calibration-characteristics-zql',
  'charts-chart-donut-variant-half': 'gauge',
  'charts-bar-graph': 'column',
  'charts-gantt': 'gantt',
  'charts-google-assistant': 'scatter',
  'charts-bubble': 'scatter-basic',
  'charts-bubble-line': 'scatter-axis',
  'charts-finance': 'line',
  'charts-trending-down': 'line-down',
  'charts-trending-up': 'line-up',
  'charts-hexagon-multiple': 'hexagons',
  'charts-scatterplot-hexbin': 'hexagons-axis',
  'date-range': 'range',
  'network-database': 'database',
  'network-database-plus': 'database-plus',
  'network-database-minus': 'database-minus',
  'network-database-search': 'database-magnifying-glass',
  'network-database-administration': 'database-check',
  'network-database-delete': 'database-xmark',
  'network-database-json': 'database-json',
  'network-database-sql': 'database-sql',
  'network-lan': 'network-wired',
  'files-mnemo': 'network-v1',
  'network-scheme': 'network-v2',
  'network-scheme-2': 'network-v3',
  'network-lan-disconnect': 'lan-disconnect',
  'network-lan-pending': 'lan-pending',
  'network-lan-connect': 'lan-connect',
  'network-external-link': 'network-external-link',
  'network-server': 'server',
  'network-plus': 'server-network-plus',
  'network-minus': 'server-network-minus',
  'network-security': 'server-network-security',
  'network-close': 'server-network-close',
  'network-upload': 'server-network-upload',
  'network-download': 'server-network-download',
  'network-server-security': 'server-shield',
  'network-server-off': 'server-slash',
  'network-server-network': 'server-up-line',
  'network-server-network-off': 'server-slash-up-line',
  'network-ethernet-open': 'ethernet',
  'date-calendar-text': 'calendar',
  'date-calendar-blank': 'calendar-blank',
  'date-calendar-range': 'calendar-range',
  'date-calendar-check': 'calendar-check',
  'date-calendar-plus': 'calendar-plus',
  'date-calendar': 'calendar-event',
  'date-calendar-today': 'calendar-today',
  'date-calendar-remove': 'calendar-deleted',
  'date-calendar-question': 'calendar-question',
  'date-calendar-clock': 'calendar-clock',
  'date-access-alarm': 'alarm-clock',
  'add-alarm': 'alarm-plus',
  'date-clock': 'clock',
  'date-midnight': 'clock',
  timer: 'clock',
  'date-time-remove': 'clock-deleted',
  'date-clock-alert': 'clock-exclamation',
  'date-time-add': 'clock-add',
  'date-update': 'clock-rotate-right',
  'date-period-dropdown-list': 'clock-rotate-right',
  'date-period-dropdown-list-open': 'clock-rotate-right',
  'date-history': 'clock-rotate-left',
  'date-timer': 'stopwatch',
  'date-timer-sand-empty': 'sandglass',
  'date-clock-out': 'clock-arrow-up',
  'date-clock-in': 'clock-arrow-down',
  'date-clock-start': 'clock-arrow-right',
  'date-clock-end': 'clock-line-end',
  'date-minute': 'letter-minute',
  'date-year': 'letter-year',
  'date-hour': 'letter-hour',
  'date-day': 'letter-day',
  'date-now': 'letter-time',
  'date-second': 'letter-second',
  'date-month': 'letter-month',
  'date-asterisk': 'symbol-asterisk',
  'control-charts-zql': 'control-charts-zql',
  'battery-10': 'battery-empty',
  'battery-charging': 'battery-bolt',
  'battery-30': 'battery-quarter',
  'battery-50': 'battery-half',
  'battery-80': 'battery-three-quarters',
  battery: 'battery-full',
  'battery-plus': 'battery-plus',
  'battery-minus': 'battery-minus',
  'battery-alert': 'battery-exclametion-mark',
  'battery-flash-off': 'energy-slash',
  'battery-flash-ok': 'energy-check',
  'battery-flash': 'energy',
  'alerts-pulse': 'pulse',
  'social-heart': 'heart-empty',
  'social-heart-fill': 'heart-fill',
  'social-star': 'star-empty',
  'social-star-half': 'star-half-fill',
  'social-star-fill': 'star-fill',
  'social-thumb-up': 'thumb-up',
  'social-thumb-down': 'thumb-down',
  'social-telegram': 'messaging-telegram',
  'messaging-send': 'messaging-telegram-send',
  'social-share': 'shareing',
  'messaging-inbox': 'mailbox',
  'messaging-support-man': 'support-man',
  'devices-headphones': 'headphones',
  'messaging-at': 'at-sign',
  'messaging-emai': 'mail',
  'messaging-emai-down-list': 'mail',
  'messaging-email-open': 'mail-open',
  'messaging-message-circle': 'bubble-message-circle',
  'messaging-message-off': 'bubble-message-off',
  'messaging-processing': 'bubble-message',
  'messaging-message': 'bubble-message-empty',
  'messaging-alert': 'bubble-message-attention',
  'messaging-add-comment': 'bubble-message-add',
  'messaging-question-ask': 'bubble-message-question',
  'messaging-text': 'bubble-message-text',
  phone: 'phone',
  'phone-in-talk': 'phone-active-call',
  'phone-plus': 'phone-plus',
  'phone-minus': 'phone-minus',
  'phone-paused': 'phone-paused',
  'phone-missed': 'phone-cancelled',
  'phone-off': 'phone-off',
  'signal-bluetooth': 'bluetooth',
  'signal-access-point': 'signal-access-point',
  'signal-radio-tower': 'signal-radio-tower',
  'signal-wifi': 'wifi',
  'signal-wifi-off': 'wifi-off',
  signal: 'signal',
  'signal-off': 'signal-off',
  'messaging-reply': 'share-left',
  'messaging-forward': 'share-right',
  'finance-credit-card': 'credit-card',
  'finance-credit-card-plus': 'credit-card-plus',
  'finance-credit-card-of': 'credit-card-slash',
  'finance-wallet': 'wallet',
  'finance-calculator': 'calculator',
  'finance-calculator-auto': 'calculator-arrow-rotate-left',
  'calculator-function': 'calculator-function',
  cart: 'cart-plus',
  shopping: 'bag',
  'shopping-cart': 'cart',
  'finance-barcode': 'barcode',
  'finance-scanner': 'finance-scanner',
  'finance-qr-code': 'qr',
  'shopping-gift': 'gift',
  'shopping-box': 'shopping-box',
  'finance-currency-usd-off': 'dollar-slash',
  'finance-currency-usd': 'dollar',
  'finance-currency-btc': 'bitcoin',
  'badges-tag': 'label',
  'badges-tag-badges-tag-archve': 'label',
  'badges-tag-plus': 'label-plus',
  'badges-tag-remove': 'label-cross',
  'badges-tag-with-binding-to-data': 'label-text',
  'badges-tag-fill': 'label-fill',
  'badges-tag-search': 'label-magnifying-glass',
  'badges-tag-with-an-exclamation-mark': 'label-exclamation-mark',
  'badges-tag-without-binding-to-data': 'label-question',
  'shapes-hexagon-fill': 'hexagon-fill',
  'shapes-pentagon-fill': 'pentagon-fill',
  'shapes-rhombus-fill': 'rhombus-fill',
  'shapes-triangle-fill': 'triangle-fill',
  'square-fill': 'square-fill',
  'shapes-hexagon': 'hexagon',
  'shapes-pentagon': 'pentagon',
  'shapes-rhombus': 'rhombus',
  'shapes-triangle': 'triangle',
  'shapes-drawing': 'circle-square-fill',
  'shapes-drawing-outline': 'circle-square',
  'shapes-shape-1-f': 'square-triangle-circle-triangle-fill',
  'shapes-shape-1': 'square-triangle-circle-triangle',
  'shapes-shape-2-f': 'square-hexagon-circle-fill',
  'shapes-shape-2': 'square-hexagon-circle',
  'shapes-shape-3-f': 'square-circle-triangle-cross-fill',
  'shapes-shape-3': 'square-circle-triangle-cross',
  'shapes-shape-fill': 'triangle-circle-square-fill',
  'shapes-shape': 'triangle-circle-square',
  'shapes-shape-plus': 'shape-plus',
  'shapes-drawing-box': 'circle-square-in-square',
  'shapes-nest-protec': 'ring-in-square',
  'shapes-nut': 'circle-in-hexagon',
  'shapes-square-inc': 'square-in-square',
  'shapes-shape-5-f': 'circles-line-left-up-circle-right-down-fill',
  'shapes-shape-5': 'circles-line-left-up-circle-right-down',
  'shapes-shape-4-f': 'shape-link-fill',
  'shapes-shape-4': 'shape-link',
  'shapes-arrange-send-to-back': 'arrange-send-to-back',
  'shapes-checkerboard': 'checkerboard',
  'shapes-shape-6': 'hash',
  'shapes-shape-7': 'hash-dash',
  'shapes-collector': 'collector',
  'tools-eraser': 'eraser',
  'editor-crop': 'crop',
  'tools-brush': 'brush',
  'tools-auto-fix': 'magic-wand',
  'tools-content-cut': 'scissors',
  'tools-earth-work': 'shovel',
  'tools-palette': 'palette',
  'tools-ruler': 'ruler',
  'files-indicator': 'ruler',
  'ruler-index-zql': 'ruler-index-zql',
  'tools-broom': 'broom',
  'tools-pantone': 'pantone',
  'editor-format-color-fill': 'fill',
  'view-component': 'component',
  'tools-npm': 'npm',
  'poi-bdrv-administration': 'bdrv-administration',
  'shapes-Star': 'star-line',
  'editor-percent': 'square-percent',
  'editor-hash': 'hashtag',
  'editor-tag': 'code',
  'editor-group': 'brackets-square',
  'editor-group-dropdown': 'brackets-square',
  'editor-auto': 'brackets-curly',
  'editor-auto1': 'brackets-curly',
  'vectors-point': 'vector-point',
  'vectors-circle-variant': 'vector-circle-one-point',
  'vectors-ellipse': 'vector-circle-two-point',
  'vectors-circle': 'vector-circle',
  'vectors-line': 'vector-line',
  'vectors-curve': 'bezier-curve',
  'network-object': 'object',
  'vectors-square': 'vector-square',
  'vectors-polygon': 'vector-polygon',
  'vectors-rectangle': 'vector-rectangle',
  'vectors-triangle': 'vector-triangle',
  'vectors-polyline': 'polyline',
  'vectors-radius': 'vectors-radius',
  'vectors-arrange-above': 'send-forward',
  'shapes-arrange-bring-forward': 'send-forward',
  'vectors-arrange-below': 'send-backward',
  'shapes-arrange-send-backward': 'send-backward',
  'shapes-layers': 'layer-group',
  'network-layers': 'layer-network',
  'editor-group-choose': 'layer-group',
  'shapes-layers-off': 'layer-group-slash',
  'network-no-layers': 'layer-group-slash',
  'selection-checked-empty': 'selection-checked-empty',
  'editor-vertical-align-bottom': 'align-bottom',
  'editor-vertical-align-top': 'align-top',
  'editor-vertical-align-center': 'align-center-v',
  'editor-horizontal-align-center': 'align-center-v',
  'editor-format-bold': 'bold',
  'editor-format-italic': 'italic',
  'editor-format-underlined': 'underlined',
  'editor-subskrit-text': 'subskrit-up',
  'editor-superskrit-text': 'subskrit-down',
  'editor-format-color-text': 'text-color',
  'editor-title': 'text',
  'editor-line-height': 'line-height',
  'editor-cross-out': 'strikethrough',
  'editor-format-align-left': 'align-left',
  'editor-format-align-center': 'align-center',
  'editor-format-align-right': 'align-right',
  'editor-format-align-justify': 'align-justify',
  'editor-format-indent': 'indent-arrow-right',
  'editor-format-indent1': 'indent-arrow-left',
  'editor-sort-asc': 'bars-sort',
  'editor-format-list-bulleted': 'list-ul',
  'editor-list-dushed-2': 'list-ul',
  'editor-format-list-numbered': 'list-ol',
  'editor-border-all': 'border-all',
  'editor-border-bottom': 'border-bottom',
  'editor-border-horizontal': 'border-center-h',
  'editor-border-inner': 'border-inner',
  'editor-border-left': 'border-left',
  'editor-border-outer': 'border-outer',
  'editor-border-right': 'border-right',
  'editor-border-style': 'border-top-left',
  'editor-border-vertical': 'border-center-v',
  'editor-border-clear': 'border-none',
  'editor-border-top': 'border-top',
  'editor-format-textdirection-l-to-r': 'pi',
  'editor-functions': 'sigma',
  'editor-formula': 'function',
  'tools-keyboard': 'keyboard',
  'tools-keyboard-close': 'keyboard-close',
  'editor-plus': 'square-plus',
  'add-plus-box': 'square-plus',
  'editor-minus': 'square-minus',
  'delete-minus-square': 'square-minus',
  'editor-brace-left': 'square-bracket-left',
  'add-brace-right': 'square-bracket-right',
  'editor-division': 'square-divide',
  'editor-multiplication': 'square-multiply',
  'editor-curly-brace-left': 'square-bracket-curly-left',
  'add-curly-brace-right': 'square-bracket-curly-right',
  'vectors-combine': 'object-intersect',
  'vectors-difference': 'object-exclude',
  'vectors-difference-ab': 'object-up-subtract',
  'vectors-difference-ba': 'object-down-subtract',
  'vectors-intersection': 'object-intersect',
  'vectors-selection': 'object-selection',
  'editor-add-column': 'plus-column-top',
  'editor-add-row': 'plus-column-right',
  'editor-delete-column': 'xmark-column-top',
  'editor-delete-row': 'xmark-column-right',
  'editor-copy-column': 'copy-column',
  'editor-copy-row': 'copy-row',
  'editor-right': 'editor-right',
  'editor-down': 'editor-down',
  'editor-transpose': 'table-transpose',
  'table-transpose-top': 'table-transpose-top',
  'table-transpose-right': 'table-transpose-right',
  'view-agenda': 'agenda-fill',
  'view-sequential': 'sequential-fill',
  'view-headline': 'headline-fill',
  'view-day': 'day-fill',
  'view-column': 'column-fill',
  'view-array': 'array-fill',
  'view-carousel': 'carousel-fill',
  'view-grid': 'grid-fill',
  'view-grid-outline': 'grid',
  'view-dashboard': 'dashboard-fill',
  'view-dashboard-variant': 'dashboard-variant-fill',
  'view-module': 'module-fill',
  'shapes-collage': 'collage-fill',
  'view-quilt': 'quilt-fill',
  'view-list': 'list-fill',
  'social-browser': 'browser',
  'editor-table': 'table',
  'editor-transpose-top': 'table',
  'editor-table-lookup': 'table-magnifying-glass',
  'editor-window': 'window',
  'table-gear': 'table-gear',
  'editor-panel-top': 'panel-top',
  'editor-panel-down': 'panel-down',
  'editor-panel-left': 'panel-left',
  'editor-panel-right': 'panel-right',
  'editor-transpose-right': 'panel-right',
  'transport-airplane': 'airplane-top-view',
  'transport-plane': 'airplane-side-view',
  'transport-helicopter': 'helicopter-side-view',
  'transport-ship-side': 'ship-side-view',
  'transport-ship-front': 'ship-front-view',
  'transport-truck': 'concrete-mixer-side-view',
  'transport-ambulance': 'ambulance-side-view',
  'transport-paver': 'paver-side-view',
  'transport-truck-gasoline': 'truck-gasoline-side-view',
  'transport-truck-dump': 'truck-dump-side-view',
  'transport-truck-empty': 'truck-empty-side-view',
  'transport-car-jeep': 'car-jeep-side-view',
  'transport-car-bus': 'minibus-side-view',
  'transport-railway': 'tankcar-side-view',
  'transport-car-light': 'car-light-side-view',
  'transport-pipe-node': 'pipe-node-side-view',
  'settings-tune-vertical': 'sliders-v',
  'settings-tune': 'sliders-h',
  settings: 'gear-8-edge',
  'settings-2': 'gear-5-edge',
  'poi-article-1': 'pen-hammer',
  'tools-wrench': 'wrench',
  'editor-copy': 'copy',
  'shapes-animation': 'layer-group',
  'editor-copy-drop': 'copy',
  'view-menu': 'bars',
  'view-menu-2': 'bars',
  'settings-more-vert': 'ellipsis-v',
  'settings-more-horiz': 'ellipsis-h',
  'dot-grid': 'ellipsis-grid',
  'settings-apps': 'ellipsis-grid',
  'sort-apps': 'ellipsis-grid',
  'editor-dots': 'grip-dots-vertical',
  'editor-drag-and-drop': 'grip-dots-vertical',
  'drag-and-drop': 'grip-dots-vertical',
  'sort-eye': 'eye',
  'sort-eye-off': 'eye-slash',
  'sort-eye-off-2': 'eye-closed',
  'settings-power': 'power',
  'social-sun': 'sun',
  'shapes-box-shadow': 'sun',
  'social-moon': 'moon',
  'tools-lightbulb': 'lightbulb',
  'shapes-Key': 'key',
  'social-home': 'house',
  'social-home-breadcrumbs': 'house-mini',
  'editor-attach-2': 'paperclip-vertical',
  'editor-attach-file': 'paperclip',
  'editor-droplet': 'droplet',
  'editor-format-color-reset': 'droplet-slash',
  edit: 'pencil-line-bottom',
  'editor-mode': 'pencil',
  'editor-mini': 'pencil',
  'editor-dropdown-list': 'pencil-triangle-down',
  'editor-dropdown-list-open': 'pencil-triangle-left',
  'editor-screen': 'pencil-arrow-right',
  'editor-border-color': 'pencil-line-bottom',
  'tools-eyedropper': 'pipette',
  'editor-insert-link': 'link-simple',
  'editor-link': 'link-simple-h',
  'editor-insert-link-add': 'link-plus',
  'editor-insert-link-delete': 'link-minus',
  'editor-disconnect-link': 'link-break',
  search: 'magnifying-glass-top-from-bracket',
  'sort-zoom-in': 'magnifying-glass',
  'finance-qr-code-search': 'magnifying-glass',
  'editor-search-hierarchy': 'magnifying-glass-ontology',
  'editor-search-tags-words': 'magnifying-glass-tags-words',
  'sort-maximize': 'magnifying-glass-plus',
  'sort-zoom-out': 'magnifying-glass-minus',
  'sort-real-size': 'magnifying-glass-xmark',
  'sort--mistake': 'magnifying-glass-exclamation',
  'sort-zoom-in-dropdown': 'magnifying-glass-triangle-down',
  'sort-look-into-the-buffer': 'magnifying-glass-top-from-bracket',
  delete: 'trash-empty',
  'delete-drop': 'trash',
  'delete-empty': 'trash-empty',
  'delete-restore': 'trash-empty',
  'delete-forever': 'trash-empty',
  'sort-filter': 'filter',
  'sort-filter-remove': 'sort-filter-remove',
  'sort-filter-setting': 'filter',
  'sort-funnel-drop-down-list': 'filter',
  'sort-funnel-drop-down-list-open': 'filter',
  'filter-gear': 'filter-gear',
  'filter-ontology': 'filter-ontology',
  'account-lock': 'lock',
  'account-unlock': 'unlock',
  'cancel-block,': 'ban',
  'cancel,': 'ban',
  'files-loader': 'loader',
  'add-plus': 'plus',
  'add-plus-drop-down-list': 'plus-triangle-down',
  'add-line-add': 'plus-triangle-down',
  'add-circle': 'circle-plus',
  'date-time-add-2': 'circle-plus',
  'add-plus-circle-multiple': 'circle-plus',
  'add-language': 'circle-plus',
  'add-label-add': 'circle-plus',
  'add-circle-fill': 'circle-plus-fill',
  'add-plus-box-fill': 'circle-plus-fill',
  'add-library-plus': 'library-plus',
  'delete-minus': 'minus',
  'delete-minus-circle': 'minus-circle',
  'selection-halfchoice': 'minus-circle',
  'date-time-remove-2': 'minus-circle',
  'cancel-delete-line': 'minus-circle',
  'delete-do-not-disturb': 'minus-circle-fill',
  cross: 'xmark-mini',
  close: 'xmark-mini',
  'cancel-close': 'xmark',
  'cancel-close-circle': 'circle-xmark',
  'cancel-close-box': 'circle-xmark',
  'cancel-close-circle-fill': 'circle-xmark-fill',
  'cancel-close-box-fill': 'circle-xmark-fill',
  'cansel-delete-content': 'delete-content',
  'delete-label-delete': 'delete-content',
  'cancel-close-small': 'xmark-mini',
  'account-shield-off': 'shield-slash',
  'account-shield-alarm': 'shield-exclamation',
  'player-play-back': 'play-back',
  'player-play': 'play',
  'player-stop': 'stop',
  'player-pause': 'pause',
  'player-skip-previous': 'return-step',
  'player-skip-next-circle-outline': 'forvard-step',
  'player-skip-backward': 'return',
  'player-skip-forward': 'forward',
  'player-voicemail': 'voicemail',
  'player-loop': 'infinity',
  replay: 'replay',
  'camera-video': 'video',
  'camera-video-close': 'video-cross',
  'camera-video-off': 'video-slash',
  'camera-camera': 'camera-movie',
  'camera-close': 'camera-movie-cross',
  'camera-film-close': 'film-cross',
  'camera-film': 'film',
  'camera-clapperboard': 'clapperboard-open',
  'camera-clapperboard-close': 'clapperboard-cross',
  'audio-volume-high': 'volume',
  'audio-volume-off': 'volume-slash',
  'audio-volume-low': 'volume-off',
  'audio-volume-medium': 'volume-low',
  'audio-volume-plus': 'volume-plus',
  'audio-volume-minus': 'volume-minus',
  'audio-volume-mute': 'volume-cross',
  'audio-file-music': 'file-music',
  'audio-music-box': 'square-music',
  'audio-add-library-music': 'square-music-double',
  'audio-music-circle': 'circle-music',
  'audio-music': 'music',
  'audio-music-off': 'music-slash',
  'audio-microphone': 'microphone',
  'audio-microphone-off': 'microphone-slash',
  camera: 'camera',
  'camera-image': 'image',
  'devices-laptop': 'laptop',
  'devices-laptop-off': 'laptop-slash',
  'devices-cellphone-link': 'laptop-phone',
  'devices-monitor': 'monitor',
  'devices-input-device': 'input-device',
  'devices-tv': 'tv',
  'devices-cellphone-link-off': 'laptop-phone-slash',
  'devices-cellphone': 'cellphone',
  'devices-tablet': 'tablet',
  'devices-watch-variant': 'watch',
  'devices-cellphone-basic': 'push-button-cellphone',
  'devices-desktop-tower': 'desktop-tower',
  'desktop-tower': 'desktop-tower',
  'devices-touchpad': 'touchpad',
  'devices-transmitter': 'transmitter',
  'devices-speaker': 'audio-speakers',
  'devices-camera-front': 'camera-front',
  'devices-sensor': 'webcam',
  'devices-cellphone-wireless': 'cellphone-wireless',
  'devices-hard-drive-outline': 'hard-drive-outline',
  'devices-printer': 'printer',
  'devices-printer-dropdown-list': 'printer',
  'devices-android': 'android',
  'devices-android-debug-bridge': 'android-pill',
  'editor-template': 'template',
  'vectors-default-16': 'default-ico',
  'editor-insert-emoticon': 'face-smile',
  'editor-insert-emoticon-sad': 'face-frown',
  'tools-thermometer-minus': 'temperature-minus',
  'thermometer-minus': 'temperature-minus',
  'tools-thermometer': 'temperature',
  thermometer: 'temperature',
  'tools-thermometer-plus': 'temperature-plus',
  'thermometer-plus': 'temperature-plus',
  'social-instagram': 'instagram',
  'poi-git-lab': 'git-lab',
  'poi-owl': 'owl',
  'location-map-marker-date-1': 'map-marker-date-v1',
  'location-map-marker-date-2': 'map-marker-date-v2',
  'view-logo-grey-z': 'logo-grey-z',
  'tools-award': 'award',
  'tools-briefcase': 'portfel',
  'tools-umbrella': 'umbrella',
  'selection-process': 'process',
  'editor-alfavit': 'editor-alfavit',
  'block-lines': 'block-lines',
};
