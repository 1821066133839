<prizm-calendar
  [(hoveredItem)]="hoveredItem"
  [min]="min"
  [max]="max"
  [month]="userViewedMonthFirst"
  [markerHandler]="markerHandler"
  [maxViewedMonth]="cappedUserViewedMonthSecond | prizmMapper : monthOffset : -1"
  [value]="value"
  [disabledItemHandler]="disabledItemHandler"
  [showAdjacent]="false"
  [rangeState]="rangeStates[0]"
  (dayClick)="onDayClick($event)"
  (monthChange)="onSectionFirstViewedMonth($event)"
>
  <ng-container footer> <ng-content select="[footerFrom]"></ng-content></ng-container>
  <ng-container header> <ng-content select="[headerFrom]"></ng-content></ng-container>
</prizm-calendar>

<prizm-calendar
  [(hoveredItem)]="hoveredItem"
  [min]="min"
  [max]="max"
  [month]="userViewedMonthSecond"
  [markerHandler]="markerHandler"
  [minViewedMonth]="cappedUserViewedMonthFirst | prizmMapper : monthOffset : 1"
  [value]="value"
  [disabledItemHandler]="disabledItemHandler"
  [showAdjacent]="false"
  [rangeState]="rangeStates[1]"
  (dayClick)="onDayClick($event)"
  (monthChange)="onSectionSecondViewedMonth($event)"
>
  <ng-container footer>
    <ng-content select="[footerTo]"></ng-content>
  </ng-container>
  <ng-container header>
    <ng-content select="[headerTo]"></ng-content>
  </ng-container>
</prizm-calendar>
