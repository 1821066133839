<div class="i18-block">
  <tui-select
    class="select full-width"
    [ngModel]="languageManager.language$ | async"
    [valueContent]="valueContentLanguage"
    [identityMatcher]="languageIdentityMatcher"
    (ngModelChange)="languageSwitcher.setLanguage($event.code)"
    tuiTextfieldSize="s"
  >
    <tui-data-list *tuiDataList>
      <button *ngFor="let language of languages" [value]="language" tuiOption>
        {{ language.label }}
      </button>
    </tui-data-list>
  </tui-select>

  <ng-template #valueContentLanguage let-language>
    {{ language.label }}
  </ng-template>
</div>

<div class="select-block">
  <ng-container *ngIf="initialVersion?.stackblitz">
    <div class="icon-block" *prizmLet="initialVersion as version" [hidden]="!version.stackblitz">
      <a [href]="version.stackblitz" target="_blank">
        <div class="icon" title="Open empty project with Prizm UI on stackblitz">
          <img src="assets/logos/sb.png" />
        </div>
      </a>
    </div>
  </ng-container>

  <tui-select
    class="select"
    [(ngModel)]="initialVersion"
    [valueContent]="'label' | tuiStringify | tuiStringifyContent"
    tuiTextfieldSize="s"
  >
    <tui-data-list *tuiDataList>
      <button *ngFor="let version of versions" [value]="version" tuiOption>
        <ng-container [ngTemplateOutlet]="vt" [ngTemplateOutletContext]="{ $implicit: version }">
        </ng-container>
      </button>
    </tui-data-list>
  </tui-select>
</div>

<ng-template #vt let-version>
  <div class="item">
    <a [hidden]="!version.stackblitz" [href]="version.stackblitz" target="_blank">
      <div class="icon" title="Open empty project with Prizm UI on stackblitz">
        <img src="assets/logos/sb.png" />
      </div>
    </a>

    <a class="version-link" [href]="getVersionHref(version)">
      {{ version.label }}
    </a>
  </div>
</ng-template>
