<ng-content select="[prizmPreHeader]"></ng-content>
<tui-input
  class="t-input"
  #input
  *prizmLet="labels$ | async as labelsList"
  [formControl]="search"
  [tuiAutoFocus]="!!sidebar"
  [tuiTextfieldCleaner]="true"
  [tuiTextfieldLabelOutside]="true"
  tuiTextfieldSize="m"
  tuiTextfieldIconLeft="tuiIconSearch"
>
  {{ searchText }}
  <ng-container *ngIf="canOpen">
    <tui-data-list *tuiDataList [class.is-full]="!(filtered$ | async | prizmCallFunc : isEmpty)">
      <tui-opt-group
        *ngFor="let group of filtered$ | async; let index = index"
        [label]="labelsList[index] || ''"
      >
        <a *ngFor="let item of group" [routerLink]="item.route" (click)="onClick(input)" tuiOption>
          {{ item.title }}
          <ng-template *ngTemplateOutlet="statusLabel; context: { $implicit: item.status }"></ng-template>
        </a>
      </tui-opt-group>
    </tui-data-list>
  </ng-container>
</tui-input>

<nav class="t-navigation" *prizmLet="items$ | async as itemsList">
  <tui-scrollbar class="t-scrollbar" [tuiMode]="mode$ | async">
    <tui-accordion [closeOthers]="false" [rounded]="false">
      <tui-accordion-item
        *ngFor="let label of labels$ | async; index as index"
        [open]="!!openPagesArr[index]"
        [borders]="null"
        size="s"
      >
        <span class="t-label">
          <strong>{{ label }}</strong>
        </span>
        <ng-template tuiAccordionItemContent>
          <div class="t-section">
            <ng-container
              *ngFor="let item of itemsList[index]; index as subIndex"
              [ngTemplateOutlet]="pages"
              [ngTemplateOutletContext]="{ item: item, index: index * 100 + subIndex }"
            ></ng-container>
          </div>
        </ng-template>
      </tui-accordion-item>
    </tui-accordion>
    <div class="t-items-container">
      <ng-container
        *ngFor="let item of itemsWithoutSections(itemsList); let index = index"
        [ngTemplateOutlet]="pages"
        [ngTemplateOutletContext]="{ item: item, index: itemsList.length - 1 + index }"
      ></ng-container>
    </div>

    <ng-template #pages let-item="item" let-index="index">
      <ng-container *ngIf="!item.subPages; else subPages" [ngSwitch]="!!item.link">
        <a
          class="t-sublink"
          *ngSwitchCase="true"
          [class.deprecated]="item.status === 'deprecated'"
          [class.new]="item.status === 'new'"
          [target]="item.target"
          [href]="item.link"
          (click)="closeMenu()"
          tuiLink
          routerLinkActive="t-sublink_active"
        >
          {{ item.title }}
          <ng-template *ngTemplateOutlet="statusLabel; context: { $implicit: item.status }"></ng-template>
        </a>
        <a
          class="t-sublink"
          *ngSwitchDefault
          [class.deprecated]="item.status === 'deprecated'"
          [class.new]="item.status === 'new'"
          [routerLink]="item.route"
          [prizmScrollIntoViewLink]="isActive(item.route)"
          (click)="closeMenu()"
          tuiLink
          routerLinkActive="t-sublink_active"
        >
          {{ item.title }}
          <ng-template *ngTemplateOutlet="statusLabel; context: { $implicit: item.status }"></ng-template>
        </a>
      </ng-container>

      <ng-template #subPages>
        <div class="t-subsection" [routerLinkActiveOptions]="{ exact: false }" routerLinkActive>
          <button
            class="t-sublink t-sublink_subsection"
            *ngIf="item.subPages"
            [title]="item.status === 'deprecated' ? 'Больше не поддерживается' : ''"
            [class.deprecated]="item.status === 'deprecated'"
            (click)="onGroupClick(index)"
            tuiLink
            type="button"
          >
            <tui-svg
              class="t-chevron"
              [class.t-chevron_active]="!!openPagesGroupsArr[index]"
              src="tuiIconChevronRight"
            ></tui-svg>
            {{ item.title }}
            <ng-template *ngTemplateOutlet="statusLabel; context: { $implicit: item.status }"></ng-template>
          </button>
          <tui-expand class="t-expand" [expanded]="!!openPagesGroupsArr[index]">
            <div class="t-section t-section_bordered">
              <a
                class="t-sublink t-sublink_small"
                *ngFor="let subPage of item.subPages"
                [title]="subPage.status === 'deprecated' ? 'Компонент больше не поддерживается' : ''"
                [class.deprecated]="subPage.status === 'deprecated'"
                [routerLink]="subPage.route"
                [prizmScrollIntoViewLink]="isActive(subPage.route)"
                (click)="closeMenu()"
                tuiLink
                routerLinkActive="t-sublink_active"
              >
                {{ subPage.title }}
                <ng-template
                  *ngTemplateOutlet="statusLabel; context: { $implicit: subPage.status }"
                ></ng-template>
              </a>
            </div>
          </tui-expand>
        </div>
      </ng-template>
    </ng-template>
  </tui-scrollbar>
</nav>

<ng-content></ng-content>

<ng-template #statusLabel let-status>
  <ng-container *ngIf="status">
    <prizm-chips-item [className]="status" [deletable]="false">{{ status | titlecase }}</prizm-chips-item>
  </ng-container>
</ng-template>
